<template>
    <v-alert
        border="left"
        colored-border
        color="red accent-4"
        :elevation="elevation"
        class="text-body-2"
        type="error"
    >
        <slot />
    </v-alert>
</template>

<script>
export default {
    props: {
        elevation: {
            default: 0,
            type: Number,
            required: false,
        },
    },
    methods: {},
};
</script>
