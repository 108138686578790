<template>
    <div>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    :text="type == 'icon' && !isMobile"
                    :light="!isMobile"
                    :x-small="!isMobile"
                    :alt="label"
                    @click="$emit('click', $event)"
                    :width="type != 'button' && icon ? 35 : null"
                    :heigth="type != 'button' && icon ? 35 : null"
                    v-bind="attrs"
                    :disabled="disabled"
                    v-on="on"
                    :color="type == 'button' ? color : null"
                >
                    <v-icon small v-if="icon" :color="color">
                        {{ iconName(icon) }}</v-icon
                    >

                    <template v-if="type == 'button' || !icon">
                        {{ label }}
                    </template>
                </v-btn>
            </template>
            <span>{{ placeholder || label }}</span>
        </v-tooltip>
    </div>
</template>

<script>
import GlobalMixins from "@/mixins/global";

export default {
    name: "Action",
    mixins: [GlobalMixins],
    props: {
        label: {
            type: String,
            required: false,
        },
        icon: {
            type: String,
            required: false,
        },
        type: {
            type: String,
            required: false,
            default: "icon",
        },
        color: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        placeholder: {
            type: String,
            required: false,
        },
    },
};
</script>
