import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const DEFAULT_FILTERS = { offset: 0, limit: 20 };

const createState = () => ({
    orders: [],
    orders_url: '/api/customerorders/',
    orders_filters: DEFAULT_FILTERS,
    orders_count: 0,
    orders_loaded: false,
    orders_loading: false,
    orders_loading_errors: null,
});

const mutations = {
    updateOrders(state, orders) {
        state.orders = orders;
        state.orders_loading = false;
        state.orders_loading_errors = null;
    },
    updateOrdersUrl(state, orders_url) {
        state.orders_url = orders_url;
    },
    updateOrdersFilters(state, orders_filters) {
        state.orders_filters = orders_filters;
    },
    updateOrdersLoading(state, orders_loading) {
        state.orders_loading = orders_loading;
    },
    updateOrdersLoaded(state, orders_loaded) {
        state.orders_loaded = orders_loaded;
    },
    updateOrdersLoadingErrors(state, orders_loading_errors) {
        state.orders_loading_errors = orders_loading_errors;
        state.orders_loading = false;
    },
    updateOrdersCount(state, orders_count) {
        state.orders_count = orders_count;
    },
};

const actions = {

    fetchOrders({ commit, dispatch, state }, params) {
        if (store.getters['session/current_user_permissions'].indexOf("order.view_order") == -1) {
            return;
        }

        commit('updateOrdersLoading', true);
        commit('updateOrdersLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get(state.orders_url, { params: state.orders_filters })
                .then((response) => {
                    commit('updateOrders', response.data.results);
                    commit('updateOrdersCount', response.data.count);
                    commit('updateOrdersLoaded', true);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateOrdersLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateOrdersLoading', false);
                });

        });
    },

    init({ commit, dispatch, state }, params) {
        commit('updateOrders', []);
        console.log('xxxxx', params);
        commit('updateOrdersFilters', { ...DEFAULT_FILTERS, ...(params.filters || {}) });
        commit('updateOrdersLoaded', false);
        if (params.url) {
            commit('updateOrdersUrl', params.url);
        }
    },
};


export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});