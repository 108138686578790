import axios from "@/plugins/axios";
import utils from "@/stores/utils";

const state = {
    provider: null,
    provider_saving: false,
    provider_saving_errors: {},
};

const mutations = {
    updateProvider(state, provider) {
        if (state.provider && provider && state.provider.id == provider.id) {
            state.provider = provider;
            return;
        }
        state.provider = provider;
    },
    updateProviderSaving(state, saving) {
        state.provider_saving = saving;
    },
    updateProviderSavingErrors(state, errors) {
        state.provider_saving = false;
        state.provider_saving_errors = errors;
    },
};

const actions = {

    saveProvider({ commit, dispatch, state }, params) {

        commit("updateProviderSaving", true);

        let url = "/api/providers/";
        let method = axios.post;

        if (params.instance.id) {
            url = `/api/providers/${params.instance.id}/`;
            method = axios.put;
        }
        return new Promise((resolve, reject) => {
            method(url, params.instance)
                .then((response) => {
                    commit("updateProviderSaving", false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let errors = utils.handleError(xhr_error);
                    commit("updateProviderSavingErrors", errors.details);
                    reject(errors);
                })
                .finally(() => {
                    dispatch("session/fetchStats", null, { root: true });
                });
        });
    },

    init({ commit, dispatch, state }, params) {
        commit("updateProviderSavingErrors", {});
        commit("updateProvider", JSON.parse(JSON.stringify({ address: {}, ...params.provider })));
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
