var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticStyle: {
      "enable-background": "new 0 0 512 512"
    },
    attrs: {
      "version": "1.1",
      "id": "Capa_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 512 512",
      "xml:space": "preserve"
    }
  }, [_c('g', [_c('g', [_c('g', [_c('circle', {
    attrs: {
      "cx": "256",
      "cy": "256",
      "r": "64"
    }
  }), _c('circle', {
    attrs: {
      "cx": "256",
      "cy": "448",
      "r": "64"
    }
  }), _c('circle', {
    attrs: {
      "cx": "256",
      "cy": "64",
      "r": "64"
    }
  })])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }