var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 50 50"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "d": "m28.80245,25.0103l20.09284,-20.09462a2.67857,2.67857 0 1 0 -3.78571,-3.7875l-20.09284,20.09462l-20.09462,-20.09284a2.67857,2.67857 0 1 0 -3.7875,3.78571l20.09284,20.09284l-20.09105,20.09641a2.67857,2.67857 0 1 0 3.78571,3.78571l20.09284,-20.09284l20.09462,20.09284c0.52321,0.52321 1.20893,0.78571 1.89286,0.78571a2.67857,2.67857 0 0 0 1.89286,-4.57142l-20.09284,-20.09641"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }