import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import documents from './submodules/documents';

const state = {
    is_operator: false,

    receipt: {},
    receipt_saving: false,
    receipt_saving_errors: null,

    options: [],
    options_loading: false,
    options_loading_errors: null,

    providers: [],
    providers_count: 0,
    providers_loading: false,
    providers_loading_errors: null,

    stocks: [],
    stocks_count: 0,
    stocks_loading: false,
    stocks_loading_errors: null,
};

const mutations = {

    updateReceipt(state, receipt) {
        state.receipt = JSON.parse(JSON.stringify(receipt || {}));
        state.receipt_saving = false;
        state.receipt_saving_errors = null;

        state.options = [];
        state.options_loading = false;
        state.options_loading_errors = null;
    },
    updateStock(state, stock) {
        state.receipt.stock = stock;
    },
    updateOptions(state, options) {
        state.options = options;
    },
    updateOptionsLoading(state, deleting) {
        state.options_loading = deleting;
    },
    updateOptionsLoadingErrors(state, errors) {
        state.options_loading_errors = errors;
    },

    updateReceiptSaving(state, saving) {
        state.receipt_saving = saving;
    },
    updateReceiptSavingErrors(state, errors) {
        state.receipt_saving_errors = errors;
    },

    updateProvider(state, provider) {
        state.receipt.provider = provider;
    },
    updateProviders(state, providers) {
        state.providers = providers;
    },
    updateProvidersLoading(state, loading) {
        state.providers_loading = loading;
    },
    updateProvidersLoadingErrors(state, errors) {
        state.providers_loading_errors = errors;
    },

    updateStocks(state, stocks) {
        state.stocks = stocks;
        if (!state.receipt.stock && stocks.length == 1) {
            let main_stock = state.stocks.find((stock) => stock.main) || state.stocks[0];
            state.receipt.stock = main_stock.id;
        }
    },
    updateStocksLoading(state, loading) {
        state.stocks_loading = loading;
    },
    updateStocksLoadingErrors(state, errors) {
        state.stocks_loading_errors = errors;
    },


    toggleOption(state, catalogentry) {
        if (!state.receipt.options) {
            state.receipt.options = [];
        }
        if (!state.receipt.options.find((item) => catalogentry.id == item.catalogentry)) {
            if (!state.receipt.options) {
                state.receipt.options = [];
            }
            state.receipt.options.push({ catalogentry: catalogentry.id });
        } else {
            state.receipt.options = state.receipt.options.filter((item) => catalogentry.id != item.catalogentry);
        }
    }
};

const actions = {

    init({ commit, dispatch, state }, params) {
        commit('updateReceipt', params.receipt);
        dispatch('fetchProviders');
        dispatch('fetchStocks');
        dispatch('fetchOptions');
    },

    fetchOptions({ commit, dispatch, state }, params) {


        if (!state.receipt.stock) {
            commit("updateOptions", []);
            return;
        }

        commit('updateOptionsLoading', true);
        commit('updateOptionsLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get(`/api/stocks/${state.receipt.stock}/operatoroptionscatalogentries/`, {
                params: { trigger: "receipt-option", ordering: "name" }
            })
                .then((response) => {
                    commit('updateOptions', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateOptionsLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateOptionsLoading', false);
                });
        });
    },


    saveReceipt({ commit, dispatch, state }, params) {
        commit('updateReceiptSaving', true);
        commit('updateReceiptSavingErrors', null);

        let url = '/api/receipts/';
        let method = axios.post;
        const receipt = JSON.parse(JSON.stringify(state.receipt || {}));

        if (receipt.id) {
            url = `/api/receipts/${receipt.receipt_id}/`;
            method = axios.put;
        }
        return new Promise((resolve, reject) => {
            method(url, receipt)
                .then((response) => {
                    commit('updateReceiptSaving', false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateReceiptSaving', false);
                    commit('updateReceiptSavingErrors', error.details);
                    reject(error);
                }).finally(() => {
                    dispatch('session/fetchStats', null, { root: true });
                })
        })
    },

    fetchProviders({ commit, dispatch, state }, params) {
        commit('updateProvidersLoading', true);
        commit('updateProvidersLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/providers/', {
                params: { limit: 1000, ordering: 'name' },
            })
                .then((response) => {
                    commit('updateProviders', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateProvidersLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateProvidersLoading', false);
                });
        });
    },


    changeStock({ commit, dispatch, state }, stock) {
        commit('updateStock', stock);
        dispatch('fetchOptions');
    },

    fetchStocks({ commit, dispatch, state }, params) {
        commit('updateStocksLoading', true);
        commit('updateStocksLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/stocks/', {
                params: { limit: 1000 },
            })
                .then((response) => {
                    commit('updateStocks', response.data.results);
                    dispatch('fetchOptions');
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateStocksLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateStocksLoading', false);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        documents: documents(),
    }
};
