<template>
    <div v-if="errors?.length">
        <Error
            v-for="error in errors" :key="error">
            {{ error }}
        </Error>
    </div>
</template>

<script>

export default {
    props: {
        errors: {
            default: null,
            required: true,
        },
    },
};
</script>