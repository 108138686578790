var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "129",
      "height": "28",
      "viewBox": "0 0 129 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0)"
    }
  }, [_c('rect', {
    attrs: {
      "y": "-0.0679932",
      "width": "28",
      "height": "28",
      "rx": "4",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M10.8902 3.61972C10.9308 3.55667 11.0231 3.55652 11.065 3.61868C12.7585 6.12641 15.6541 7.69951 18.8252 7.6751L19.1622 7.6665L19.497 7.64592C19.9177 7.61248 20.3412 7.55605 20.7532 7.46023C20.8182 7.4451 20.8814 7.49341 20.8824 7.56021L20.8885 7.96522C20.9098 10.0751 20.8161 12.1909 20.5969 13.7246C20.428 14.914 20.1889 15.9428 19.8805 16.8032C18.6205 20.3022 16.1269 22.8151 12.2808 24.4439C12.1919 24.4816 12.1056 24.3858 12.1494 24.2997C14.3343 20.0099 14.0337 16.4477 11.2113 13.6859L11.0631 13.537C8.43638 10.8522 8.36421 7.58263 10.8809 3.63413L10.8902 3.61972Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.7",
      "d": "M8.8107 14.1667C8.8394 14.1254 8.89618 14.1151 8.93751 14.1439L9.15018 14.2916C12.0083 16.277 12.7158 20.2034 10.7304 23.0616L10.5827 23.2742C10.554 23.3156 10.4972 23.3258 10.4559 23.2971C7.4803 21.2301 6.74372 17.1423 8.8107 14.1667Z",
      "fill": "white"
    }
  }), _c('g', {
    attrs: {
      "clip-path": "url(#clip1)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M40.9967 19.6013C40.6412 19.1726 40.4027 18.5252 40.2813 17.6592H36.457C36.457 19.6313 37.1725 21.1833 38.6033 22.3151C40.0342 23.4384 41.9983 24 44.4958 24C46.9413 24 48.8318 23.4298 50.1672 22.2894C51.5114 21.1404 52.1834 19.6999 52.1834 17.9678C52.1834 16.2787 51.6111 14.9882 50.4664 14.0965C49.7293 13.522 48.6367 13.0632 47.1885 12.7203L43.8845 11.9357C42.6097 11.6356 41.7772 11.3741 41.387 11.1511C40.78 10.8167 40.4764 10.3108 40.4764 9.63344C40.4764 8.89603 40.7843 8.32154 41.4 7.90997C41.9739 7.52636 42.7572 7.27938 43.6679 7.28968C43.7115 7.29018 43.7994 7.29294 43.7994 7.29294C43.7994 7.29294 43.9102 7.298 43.9643 7.30172C44.6116 7.34622 45.1353 7.50391 45.5959 7.75906C46.4682 8.24652 46.9334 9.06251 46.9915 10.207H50.5919C50.5348 8.19523 49.8432 6.6577 48.5173 5.59442C47.2598 4.57171 45.8978 4.05808 44.1446 4C41.7252 4 39.8911 4.56163 38.6423 5.68489C37.3936 6.80815 36.7692 8.21865 36.7692 9.9164C36.7692 11.7771 37.4153 13.1447 38.7074 14.0193C39.4705 14.5423 40.8493 15.0268 42.8439 15.4727L44.8731 15.9228C46.0611 16.1801 46.9326 16.4759 47.4876 16.8103C48.0426 17.1533 48.3201 17.6377 48.3201 18.2637C48.3201 19.3355 47.7608 20.0686 46.6421 20.463C46.0524 20.6688 45.285 20.7717 44.3397 20.7717C42.7615 20.7717 41.6471 20.3816 40.9967 19.6013Z",
      "fill": "#0F057A"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M60.274 14.984C60.9591 14.8982 61.449 14.791 61.7439 14.6624C62.2728 14.4395 62.5373 14.0922 62.5373 13.6206C62.5373 13.0461 62.3336 12.6517 61.926 12.4373C61.5271 12.2144 60.9374 12.1029 60.1569 12.1029C59.2811 12.1029 58.661 12.3173 58.2968 12.746C58.0366 13.0633 57.8632 13.492 57.7765 14.0322H54.1994C54.2774 12.806 54.6243 11.7985 55.24 11.0097C56.2199 9.77496 57.9022 9.15759 60.287 9.15759C61.8393 9.15759 63.2181 9.46199 64.4235 10.0708C65.6289 10.6796 66.2316 11.8285 66.2316 13.5177V19.9486C66.2316 20.3945 66.2402 20.9347 66.2576 21.5692C66.2836 22.0493 66.3573 22.3752 66.4787 22.5467C66.6001 22.7182 66.7822 22.8596 67.025 22.9711V23.5113H62.9926C62.8799 23.2283 62.8018 22.9625 62.7585 22.7139C62.7151 22.4652 62.6804 22.1822 62.6544 21.865C62.1428 22.4138 61.5531 22.8811 60.8854 23.2669C60.0875 23.7214 59.1857 23.9486 58.1797 23.9486C56.8963 23.9486 55.834 23.5885 54.9928 22.8682C54.1603 22.1394 53.7441 21.1104 53.7441 19.7814C53.7441 18.0579 54.4161 16.8103 55.7603 16.0386C56.4974 15.6185 57.5814 15.3184 59.0122 15.1383L60.274 14.984ZM62.4494 15.9579C62.4962 15.9562 62.5356 15.9923 62.5373 16.0386C62.6624 19.3732 60.0299 22.1767 56.6574 22.3004C56.6106 22.3021 56.5712 22.266 56.5694 22.2197L56.5605 21.9813C56.4404 18.7783 58.969 16.0855 62.2083 15.9667L62.4494 15.9579Z",
      "fill": "#0F057A"
    }
  }), _c('path', {
    attrs: {
      "d": "M74.5188 4.36011C74.753 4.36011 74.9654 4.36868 75.1562 4.38583C75.347 4.39441 75.6071 4.41155 75.9367 4.43728V7.4212C75.7285 7.39548 75.3773 7.37833 74.883 7.36975C74.3974 7.3526 74.0592 7.45979 73.8684 7.6913C73.6863 7.91423 73.5953 8.16289 73.5953 8.43728V9.62056H76.0277V12.2058H73.5953V23.5112H69.9011V12.2058H67.8328V9.62056H69.862V8.72024C69.862 7.2197 70.1179 6.18647 70.6295 5.62056C71.1671 4.78026 72.4636 4.36011 74.5188 4.36011Z",
      "fill": "#0F057A"
    }
  }), _c('path', {
    attrs: {
      "d": "M83.5085 9.1189C84.6879 9.1189 85.7502 9.33755 86.6954 9.77485C87.6407 10.2121 88.4211 10.9024 89.0368 11.8456C89.5918 12.6773 89.9517 13.6419 90.1165 14.7395C90.2119 15.3826 90.2509 16.3086 90.2335 17.5176H80.5297C80.5818 18.9238 81.0284 19.9099 81.8695 20.4758C82.3812 20.8274 82.9969 21.0031 83.7167 21.0031C84.4798 21.0031 85.0998 20.7888 85.5768 20.3601C85.8369 20.1285 86.0667 19.807 86.2662 19.3954H90.0514C89.956 20.2271 89.5181 21.0717 88.7377 21.9292C87.5236 23.2925 85.8239 23.9742 83.6386 23.9742C81.8349 23.9742 80.2436 23.3997 78.8647 22.2507C77.4859 21.1017 76.7965 19.2325 76.7965 16.643C76.7965 14.2164 77.4165 12.3558 78.6566 11.061C79.9054 9.76627 81.5227 9.1189 83.5085 9.1189ZM86.1576 11.0532C86.158 11.0118 86.1244 10.9779 86.0825 10.9774L85.867 10.9753C82.9708 10.9465 80.5993 13.2446 80.5702 16.1082L80.568 16.3213C80.5676 16.3627 80.6012 16.3966 80.6431 16.3971C83.6583 16.4271 86.1272 14.0345 86.1576 11.0532Z",
      "fill": "#0F057A"
    }
  }), _c('path', {
    attrs: {
      "d": "M91.0066 8.93147V11.5424H92.9838V20.8875C92.9838 21.7621 93.1919 22.4095 93.6081 22.8296C94.2499 23.4898 95.4466 23.7899 97.1983 23.7299L98.9543 23.6656V20.9261C98.8329 20.9346 98.7072 20.9432 98.5771 20.9518H98.2259C97.4715 20.9518 97.0205 20.8832 96.8731 20.746C96.7257 20.6002 96.652 20.2401 96.652 19.6656V11.5424H98.9543V8.93147H96.652V5.02151H92.9838V8.93147H91.0066Z",
      "fill": "#0F057A"
    }
  }), _c('path', {
    attrs: {
      "d": "M106.435 9.14465C107.615 9.14465 108.677 9.3633 109.622 9.8006C110.567 10.2379 111.348 10.9281 111.964 11.8713C112.519 12.7031 112.879 13.6677 113.043 14.7652C113.139 15.4083 113.178 16.3344 113.16 17.5434H103.457C103.509 18.9496 103.955 19.9356 104.796 20.5016C105.308 20.8531 105.924 21.0289 106.643 21.0289C107.407 21.0289 108.027 20.8145 108.504 20.3858C108.764 20.1543 108.994 19.8328 109.193 19.4212H112.978C112.883 20.2529 112.445 21.0975 111.664 21.9549C110.45 23.3183 108.751 24 106.565 24C104.762 24 103.17 23.4255 101.792 22.2765C100.413 21.1275 99.7233 19.2583 99.7233 16.6688C99.7233 14.2422 100.343 12.3815 101.583 11.0868C102.832 9.79203 104.449 9.14465 106.435 9.14465ZM109.084 11.0789C109.085 11.0375 109.051 11.0036 109.009 11.0032L108.794 11.0011C105.898 10.9722 103.526 13.2703 103.497 16.134L103.495 16.3471C103.494 16.3885 103.528 16.4224 103.57 16.4228C106.585 16.4528 109.054 14.0603 109.084 11.0789Z",
      "fill": "#0F057A"
    }
  }), _c('path', {
    attrs: {
      "d": "M121.437 9.14465C122.617 9.14465 123.679 9.3633 124.624 9.8006C125.569 10.2379 126.35 10.9281 126.966 11.8713C127.521 12.7031 127.881 13.6677 128.045 14.7652C128.141 15.4083 128.18 16.3344 128.162 17.5434H118.459C118.511 18.9496 118.957 19.9356 119.798 20.5016C120.31 20.8531 120.926 21.0289 121.645 21.0289C122.409 21.0289 123.029 20.8145 123.506 20.3858C123.766 20.1543 123.996 19.8328 124.195 19.4212H127.98C127.885 20.2529 127.447 21.0975 126.666 21.9549C125.452 23.3183 123.753 24 121.567 24C119.764 24 118.172 23.4255 116.794 22.2765C115.415 21.1275 114.725 19.2583 114.725 16.6688C114.725 14.2422 115.345 12.3815 116.585 11.0868C117.834 9.79203 119.452 9.14465 121.437 9.14465ZM124.086 11.0789C124.087 11.0375 124.053 11.0036 124.011 11.0032L123.796 11.0011C120.9 10.9722 118.528 13.2703 118.499 16.134L118.497 16.3471C118.496 16.3885 118.53 16.4224 118.572 16.4228C121.587 16.4528 124.056 14.0603 124.086 11.0789Z",
      "fill": "#0F057A"
    }
  })])]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0"
    }
  }, [_c('rect', {
    attrs: {
      "width": "128.166",
      "height": "28",
      "fill": "white"
    }
  })]), _c('clipPath', {
    attrs: {
      "id": "clip1"
    }
  }, [_c('rect', {
    attrs: {
      "width": "91.7094",
      "height": "20",
      "fill": "white",
      "transform": "translate(36.457 4)"
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }