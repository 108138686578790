<template>
    <div class="prettyinput" :class="'pretty' + type">
        <div class="inputwrapper d-flex align-items-center">
            <input
                :id="id"
                :name="name"
                :type="type"
                :disabled="disabled"
                :checked="checked"
            />
            <label :for="id">{{ label }}</label>
        </div>
    </div>
</template>

<script>
export default {
    name: "Choice",
    props: {
        label: {
            type: String,
            required: true,
            default: null,
        },
        type: {
            type: String,
            required: true,
            default: null,
            validator(value) {
                return ["checkbox", "radio", "selector"].indexOf(value) !== -1;
            },
        },
        name: {
            type: String,
            required: false,
            default: null,
        },
        id: {
            type: String,
            required: false,
            default: null,
        },
        value: {
            type: [String, Number],
            required: false,
            default: null,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        checked: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";
.prettyinput {
    input[type="checkbox"] {
        height: 15px;
        width: 15px;

        &:checked {
        }
    }
}

.prettycheckbox,
.prettyradio {
    .inputwrapper {
        display: flex;
        position: relative;
        margin-top: 0; //Override inputwrapper class from .prettyinput
    }
    label {
        flex-shrink: 1;
        margin-left: 10px;
        margin-bottom: 0;
    }
    label:before,
    label:after {
        position: absolute;
        opacity: 0;
    }
    label:before {
        content: "";
        top: 1px;
        left: 0;
        height: 15px;
        width: 15px;

        border-radius: 3px;
        background-color: $color-primary;
    }
    input {
        height: 15px;
        width: 15px;
        appearance: none;
        flex-grow: 0;
        flex-shrink: 0;
        margin-top: 1px;

        border: 2px solid $color-gs-medium;
        border-radius: 3px;

        &:disabled {
            &,
            & + label:before {
                background-color: $color-gs-medium;
            }

            &:checked + label:after {
                color: $color-gs-extrabold;
            }
        }
    }
}

.prettycheckbox {
    label:after {
        content: "L";
        top: -1px;
        left: 1px;
        height: 15px;
        width: 15px;
        font-weight: bold;
        transform: rotateZ(45deg) rotateY(155deg);
        text-align: center;

        color: white;
    }

    input {
        &:checked + label {
            &:before,
            &:after {
                opacity: 1;
            }
        }

        &:checked {
            visibility: hidden;
        }
    }
}

.prettyradio {
    input {
        color: $color-gs-black;
        border-color: currentColor;
        border-radius: 50%;
        &:checked + label {
            font-weight: bold;
        }
        &:checked + label:before {
            opacity: 1;
        }

        &:disabled {
            color: $color-gs-medium;
            background-color: white;
            & + label:before {
                background-color: $color-gs-medium;
            }
        }
    }

    label {
        &:hover {
            cursor: pointer;
        }
        &:before {
            top: 6px;
            left: 2px;
            height: 11px;
            width: 11px;

            border-radius: 50%;
            color: $color-gs-black;
            border: 2px solid $color-gs-white;
            background-color: currentColor;
        }
    }
}
</style>
