var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "loading_view d-flex justify-center justify-center align-center",
    style: {
      position: _vm.absolute ? 'absolute' : 'relative'
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "primary",
      "width": "1",
      "size": _vm.size
    }
  }, [_c('div', {
    staticClass: "px-6 text-center"
  }, [_vm._v(" " + _vm._s(_vm.displayLabel ? _vm.label || _vm.$gettext("Loading...") : "") + " ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }