import axios from "axios";
import utils from "@/stores/utils";
import store from "@/stores/store";

const state = {
    catalogpublishing: {},
    catalogpublishing_saving: false,
    catalogpublishing_saving_errors: {},

    catalogpublishing_deleting: false,
};

const mutations = {
    updateCatalogPublishingSaving(state, saving) {
        state.catalogpublishing_saving = saving;
    },
    updateCatalogPublishingSavingErrors(state, errors) {
        state.catalogpublishing_saving_errors = errors;
    },

    updateCatalogPublishing(state, catalogpublishing) {
        state.catalogpublishing = catalogpublishing;
        state.catalogpublishing_saving_errors = {};
    },

    updateCatalogPublishingDeleting(state, deleting) {
        state.catalogpublishing_deleting = deleting;
    },
};

const actions = {

    async saveCatalogPublishing({ commit, dispatch, state }, params) {

        commit("updateCatalogPublishingSaving", true);
        commit("updateCatalogPublishingSavingErrors", {});

        let url = "/api/catalogpublishings/";
        let method = axios.post;

        if (state.catalogpublishing.id) {
            url = `/api/catalogpublishings/${state.catalogpublishing.id}/`;
            method = axios.put;
        }
        try {
            const response = await method(url, state.catalogpublishing);
            commit("updateCatalogPublishingSaving", false);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateCatalogPublishingSaving", false);
            commit("updateCatalogPublishingSavingErrors", error.details);
            throw error;
        }
    },

    async deleteCatalogPublishing({ commit, dispatch, state }, params) {

        commit("updateCatalogPublishingDeleting", true);

        const url = `/api/catalogpublishings/${params.instance.id}/`;
        return new Promise((resolve, reject) => {
            axios
                .delete(url)
                .then((response) => {
                    commit("updateCatalogPublishingDeleting", false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    commit("updateCatalogPublishingDeleting", false);
                    reject(utils.handleError(xhr_error));
                })
                .finally(() => {
                    dispatch("session/fetchStats", null, { root: true });
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
