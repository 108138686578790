<script>
export default {
    props: {
        chartData: {
            type: Object,
            required: true,
        },
        chartId: {
            type: String,
            required: true,
        },
        height: {
            type: Number,
            required: false,
        },
        options: {
            type: Object,
            default: null,
        },
        displayYAxes: {
            type: Boolean,
            default: true,
        },
        displayXAxes: {
            type: Boolean,
            default: true,
        },
        unit: {
            type: String,
            required: false,
        },
        displayLegend: {
            type: Boolean,
            default: false,
        },
        xAxisType: {
            type: String,
            required: false,
        },
        yAxisType: {
            type: String,
            required: false,
        },
        aspectRatio: {
            type: Number,
            required: false,
            default: 1,
        },
    },
    computed: {
        preparedDisplayLegend() {
            return !!this.displayLegend;
        },
        chartOptions() {
            let options = {
                type: this.charttype,
                responsive: true,
                maintainAspectRatio: true,
                aspectRatio: this.aspectRatio,
                height: this.height,
                plugins: {
                    title: {
                        display: true,
                    },
                    legend: {
                        display: this.preparedDisplayLegend,
                    },
                    tooltip: {
                        callbacks: {
                            label: this.getLabel,
                        },
                    },
                },
                show: {
                    animations: {
                        x: {
                            from: 0,
                        },
                        y: {
                            from: 0,
                        },
                    },
                },
                scales: {
                    x: {
                        display: this.displayXAxes,
                        grid: {
                            display: false,
                        },
                        ticks: {
                            maxTicksLimit: 20,
                        },
                    },

                    y: {
                        type: this.yAxisType,
                        display: this.displayYAxes,
                        grid: {
                            display: false,
                        },
                    },
                },
            };
            if (this.xAxisType == "time") {
                options.scales.x.type = this.xAxisType;
            }

            return options;
        },
    },
    methods: {
        getLabel(tooltipItems, data) {
            let label = data.datasets[tooltipItems.datasetIndex].label;

            if (!label) {
                label = data.labels[tooltipItems.index];
                if (this.xAxisType == "time") {
                    label = this.$moment(
                        data.labels[tooltipItems.index]
                    ).format("YYYY-MM-DD");
                }
            }
            return ` ${label} : ${
                data.datasets[tooltipItems.datasetIndex].data[
                    tooltipItems.index
                ]
            } ${this.unit}`;
        },
    },
};
</script>
