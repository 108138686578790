<template>
    <div
        class="loading_view d-flex justify-center justify-center align-center"
        :style="{ position: absolute ? 'absolute' : 'relative' }"
    >
        <v-progress-circular indeterminate color="primary" width="1" :size="size"
            ><div class="px-6 text-center">
                {{ displayLabel ? label || $gettext("Loading...") : "" }}
            </div></v-progress-circular
        >
    </div>
</template>

<script>
export default {
    name: "Loading",
    props: {
        label: {
            type: String,
            required: false,
        },
        displayLabel: {
            type: Boolean,
            default: true,
        },
        absolute: {
            type: Boolean,
            default: true,
        },
        size: {
            type: Number,
            default: 150,
        },
    },
};
</script>

<style lang="scss">
.loading_view {
    font-size: 10pt;
    min-height: 200px;
    padding: 100px;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}
</style>
