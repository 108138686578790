var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "prettyinput",
    "class": 'pretty' + _vm.type
  }, [_c('div', {
    staticClass: "inputwrapper d-flex align-items-center"
  }, [_c('input', {
    attrs: {
      "id": _vm.id,
      "name": _vm.name,
      "type": _vm.type,
      "disabled": _vm.disabled
    },
    domProps: {
      "checked": _vm.checked
    }
  }), _c('label', {
    attrs: {
      "for": _vm.id
    }
  }, [_vm._v(_vm._s(_vm.label))])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }