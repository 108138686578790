import axios from "axios";
import utils from "@/stores/utils";
import store from '@/stores/store';

const default_catalogentries_filters = { trigger: "manual", ordering: "name", type: "service", recurring: false, limit: 50 };

const state = {
    search: "",
    customer_id: null,

    catalogentries: null,
    catalogentries_count: 0,
    catalogentries_filters: default_catalogentries_filters,
    catalogentries_loading: false,
    catalogentries_loading_errors: null,

    invoiceitems_saving: false,
    invoiceitems_saving_errors: null,

    invoice: null,
    invoices: null,
    invoices_loading: false,
    invoices_loading_errors: null,

};

const mutations = {
    updateCustomerId(state, customer_id) {
        state.customer_id = customer_id;
        state.catalogentries_filters.for_customer = customer_id;
    },

    updateSearch(state, search) {
        state.search = search;
        state.catalogentries_filters.search = search;
    },

    updateCatalogEntries(state, catalogentries) {
        state.catalogentries = catalogentries;
    },
    updateCatalogEntriesCount(state, count) {
        state.catalogentries_count = count;
    },
    updateCatalogEntriesLoading(state, loading) {
        state.catalogentries_loading = loading;
    },
    updateCatalogEntriesLoadingErrors(state, errors) {
        state.catalogentries_loading_errors = errors;
    },

    updateInvoiceItemsSaving(state, saving) {
        state.invoiceitems_saving = saving;
    },

    updateInvoiceItemsSavingErrors(state, errors) {
        state.invoiceitems_saving_errors = errors;
    },

    updateInvoicesLoading(state, loading) {
        state.invoices_loading = loading;
    },

    updateInvoicesLoadingErrors(state, errors) {
        state.invoices_loading_errors = errors;
    },

    updateInvoices(state, invoices) {
        state.invoices = invoices;
    },

    updateInvoice(state, invoice) {
        state.invoice = invoice;
    },

    updateCatalogEntriesFilters(state, filters) {
        state.catalogentries_filters = { ...default_catalogentries_filters, ...filters };
    },
};

const actions = {

    async fetchCatalogEntries({ commit, dispatch, state }, params) {
        commit("updateCatalogEntriesLoading", true);
        commit("updateCatalogEntriesLoadingErrors", null);

        try {
            const response = await axios.get("/api/catalogentries/", { params: state.catalogentries_filters });
            commit("updateCatalogEntries", response.data.results);
            commit("updateCatalogEntriesCount", response.data.count);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateCatalogEntriesLoadingErrors", error.details);
            throw error;
        } finally {
            commit("updateCatalogEntriesLoading", false);
        }
    },

    async saveInvoiceItems({ commit, dispatch, state }, params) {
        try {
            commit("updateInvoiceItemsSaving", true);
            commit("updateInvoiceItemsSavingErrors", null);

            const response = await axios.post("/api/customerinvoiceitems/bulkcreate/", params.invoiceitems);

            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateInvoiceItemsSavingErrors", error.details);
            throw error;
        }
    },

    async fetchInvoices({ commit, dispatch, state }, params) {

        commit("updateInvoicesLoading", true);
        commit("updateInvoicesLoadingErrors", null);

        try {
            const response = await axios.get("/api/customerinvoices/", { params: { status: "draft", billed: state.customer_id, ordering: '-invoice_date' } });
            commit("updateInvoices", response.data.results);
            if (response.data.results.length > 0 && state.invoice === null) {
                commit("updateInvoice", response.data.results[0].id);
            }
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateInvoicesLoadingErrors", error.details);
            throw error;
        } finally {
            commit("updateInvoicesLoading", false);
        }
    },


    init({ commit, dispatch, state }, params) {

        commit('updateCatalogEntriesFilters', { 'type': store.getters['session/current_entity_profile'] == 'operator' ? 'service' : 'product' });
        commit("updateCustomerId", params.customer_id);
        commit("updateInvoice", params.invoice);
        commit("updateInvoices", []);
        dispatch('fetchInvoices');
    },

    setSearch({ commit, dispatch, state }, search) {

        commit("updateSearch", search);
        dispatch("fetchCatalogEntries");
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
