import store from "@/stores/store";
import axios from "@/plugins/axios";
import utils from "@/stores/utils";
import documents from "./submodules/documents";
import invoiceitems from "./submodules/invoiceitems";
import qs from "qs";

const state = {
    is_receipt_operator: false,

    receipt: null,
    receipt_loading: false,
    receipt_loading_errors: null,
    receipt_saving: false,
    receipt_saving_errors: null,
    receipt_deleting: false,
    receipt_deleting_errors: null,

    receiptitem: null,
    receiptitem_loading: false,
    receiptitem_loading_errors: null,
    receiptitem_saving: false,
    receiptitem_saving_errors: null,
    receiptitem_deleting: false,
    receiptitem_deleting_errors: null,

    receiptitems: [],
    receiptitems_filters: { limit: 20 },
    receiptitems_count: 0,
    receiptitems_loaded: false,
    receiptitems_loading: false,
    receiptitems_loading_errors: null,
    receiptitems_cancel_source: null,

    waitingreceiptitems: [],
    waitingreceiptitems_filters: { limit: 20 },
    waitingreceiptitems_count: 0,
    waitingreceiptitems_loading: false,
    waitingreceiptitems_loading_errors: null,

    providers: [],
    providers_count: 0,
    providers_loading: false,
    providers_loading_errors: null,

    stocks: [],
    stocks_count: 0,
    stocks_loading: false,
    stocks_loading_errors: null,

    products: [],
    products_filters: { offset: 0, limit: 20 },
    products_count: 0,
    products_loading: false,
    products_loading_errors: null,
    products_cancel_source: null,

    setting_readyness: false,
    completing_receipt: false,
    placing_receipt: false,
    assigning_receipt: false,
    providerstats: null,

    events: [],
    events_filters: { offset: 0, limit: 20 },
    events_loading: false,
    events_loading_errors: null,
    events_count: 0,

    receipt_stats: {},
    receipt_stats_loading: false,
    receipt_stats_loading_errors: null,
    receipt_stats_loaded: false,

    productstrategy: [],
    productstrategy_loading: false,
};

let getProductsFilters = function (state, receipt) {
    if (!receipt) {
        return { limit: state.products_filters.limit || 20 };
    }
    return {
        provider: receipt ? receipt.provider : null,
        offset: 0,
        limit: state.products_filters.limit || 20,
        stock: receipt.stock,
        include_archived: false,
        exclude_receipt_products: receipt ? receipt.receipt_id : null,
    };
};

const mutations = {
    updateIsReceiptOperator(state, is_receipt_operator) {
        state.is_receipt_operator = is_receipt_operator;
    },

    updateReceipt(state, receipt) {
        if (receipt && state.receipt && state.receipt.receipt_id == receipt.receipt_id) {
            state.receipt = receipt;
            return;
        }

        state.receipt = receipt;
        state.providerstats = null;

        state.products = [];
        state.products_count = 0;

        state.receiptitems = [];
        state.receiptitems_count = 0;
        state.receiptitems_loaded = false;

        state.waitingreceiptitems = [];
        state.waitingreceiptitems_count = 0;

        state.productstrategy = [];
        state.productstrategy_loading = false;

        state.events = [];
        state.events_count = 0;
        state.events_loading = false;
        state.events_loading_errors = null;

        if (!state.receipt) {
            state.products_filters = { limit: state.products_filters.limit };
            state.receiptitems_filters = { limit: state.receiptitems_filters.limit };
            state.waitingreceiptitems_filters = {
                limit: state.waitingreceiptitems_filters.limit,
            };
            state.events_filters = {};
            return;
        }

        state.products_filters = getProductsFilters(state, receipt);

        state.receiptitems_filters = {
            limit: state.receiptitems_filters.limit || 20,
            receipt: receipt.receipt_id,
            stock: receipt.stock,
        };

        state.waitingreceiptitems_filters = {
            limit: state.waitingreceiptitems_filters.limit || 20,
            receipt: receipt.receipt_id,
            stock: receipt.stock,
        };

        state.events_filters = {
            ordering: "event_date",
            object_id: receipt.receipt_id,
            object_type: "receipt",
        };
    },
    updateReceiptLoading(state, loading) {
        state.receipt_loading = loading;
    },
    updateReceiptLoadingErrors(state, errors) {
        state.receipt_loading_errors = errors;
    },
    updateReceiptSaving(state, saving) {
        state.receipt_saving = saving;
    },
    updateReceiptSavingErrors(state, errors) {
        state.receipt_saving_errors = errors;
    },
    updateReceiptDeleting(state, deleting) {
        state.receipt_deleting = deleting;
    },
    updateReceiptDeletingErrors(state, errors) {
        state.receipt_deleting_errors = errors;
    },

    updateProviders(state, providers) {
        state.providers = providers;
    },
    updateProvidersLoading(state, loading) {
        state.providers_loading = loading;
    },
    updateProvidersLoadingErrors(state, errors) {
        state.providers_loading_errors = errors;
    },
    updateProvidersCount(state, count) {
        state.providers_count = count;
    },

    updateStocks(state, stocks) {
        state.stocks = stocks;
    },
    updateStocksLoading(state, loading) {
        state.stocks_loading = loading;
    },
    updateStocksLoadingErrors(state, errors) {
        state.stocks_loading_errors = errors;
    },
    updateStocksCount(state, count) {
        state.stocks_count = count;
    },

    updateReceiptItem(state, receiptitem) {
        state.receiptitem = receiptitem;
    },
    updateReceiptItemLoading(state, loading) {
        state.receiptitem_loading = loading;
    },
    updateReceiptItemLoadingErrors(state, errors) {
        state.receiptitem_loading_errors = errors;
    },
    updateReceiptItemSaving(state, saving) {
        state.receiptitem_saving = saving;
    },
    updateReceiptItemSavingErrors(state, errors) {
        state.receiptitem_saving_errors = errors;
    },
    updateReceiptItemDeleting(state, deleting) {
        state.receiptitem_deleting = deleting;
    },
    updateReceiptItemDeletingErrors(state, errors) {
        state.receiptitem_deleting_errors = errors;
    },

    updateReceiptItems(state, receiptitems) {
        state.receiptitems = receiptitems;
        state.receiptitems_loaded = true;
    },
    updateReceiptItemsFilters(state, filters) {
        state.receiptitems_filters = Object.assign(
            {
                receipt: state.receipt ? state.receipt.receipt_id : null,
                limit: state.receiptitems_filters.limit || 20,
                stock: state.receipt ? state.receipt.stock : null,
                status__neq: state.is_receipt_operator ? "waiting" : null,
            },
            filters
        );
    },
    updateReceiptItemsCount(state, count) {
        state.receiptitems_count = count;
    },
    updateReceiptItemsLoading(state, loading) {
        state.receiptitems_loading = loading;
    },
    updateReceiptItemsLoadingErrors(state, errors) {
        state.receiptitems_loading_errors = errors;
    },

    updateWaitingReceiptItems(state, receiptitems) {
        state.waitingreceiptitems = receiptitems;
    },
    updateWaitingReceiptItemsFilters(state, filters) {
        state.waitingreceiptitems_filters = Object.assign(
            {
                receipt: state.receipt ? state.receipt.receipt_id : null,
                stock: state.receipt ? state.receipt.stock : null,
                status: "waiting",
            },
            filters
        );
    },
    updateWaitingReceiptItemsCount(state, count) {
        state.waitingreceiptitems_count = count;
    },
    updateWaitingReceiptItemsLoading(state, loading) {
        state.waitingreceiptitems_loading = loading;
    },
    updateWaitingReceiptItemsLoadingErrors(state, errors) {
        state.waitingreceiptitems_loading_errors = errors;
    },
    updateReceiptItemsCancelSource(state, source) {
        state.receiptitems_cancel_source = source;
    },

    updateProducts(state, products) {
        state.products = products;
    },
    updateProductsCount(state, products_count) {
        state.products_count = products_count;
    },
    updateProductsFilters(state, filters) {
        state.products_filters = Object.assign(
            getProductsFilters(state, state.receipt),
            filters
        );
    },
    updateProductsLoading(state, loading) {
        state.products_loading = loading;
    },
    updateProductsLoadingErrors(state, errors) {
        state.products_loading_errors = errors;
    },
    updateProductsCancelSource(state, source) {
        state.products_cancel_source = source;
    },

    updateSynchronizingProducts(state, synchronizing) {
        state.synchronizing_products = synchronizing;
    },
    updateSynchronizingProductsErrors(state, errors) {
        state.synchronizing_products_errors = errors;
    },

    updateProviderStats(state, providerstats) {
        state.providerstats = providerstats;
    },
    updateCompletingReceipt(state, completing_receipt) {
        state.completing_receipt = completing_receipt;
    },
    updateSettingReadyness(state, setting_readyness) {
        state.setting_readyness = setting_readyness;
    },
    updatePlacingReceipt(state, placing_receipt) {
        state.placing_receipt = placing_receipt;
    },
    updateAssigningReceipt(state, assigning_receipt) {
        state.assigning_receipt = assigning_receipt;
    },

    updateEvents(state, data) {
        state.events = data.results;
        state.events_count = data.count;
    },
    updateEventsLoading(state, events_loading) {
        state.events_loading = events_loading;
    },
    updateEventsLoadingErrors(state, events_loading_errors) {
        state.events_loading_errors = events_loading_errors;
    },

    updateReceiptStats(state, receipt_stats) {
        state.receipt_stats = receipt_stats;
    },
    updateReceiptStatsLoading(state, loading) {
        state.receipt_stats_loading = loading;
    },
    updateReceiptStatsLoadingErrors(state, errors) {
        state.receipt_stats_loading_errors = errors;
    },
    updateReceiptStatsLoaded(state, loaded) {
        state.receipt_stats_loaded = loaded;
    },

    updateProductStrategy(state, productstrategy) {
        state.productstrategy = productstrategy;
    },
    updateProductStrategyLoading(state, productstrategy_loading) {
        state.productstrategy_loading = productstrategy_loading;
    },
};

const getters = {
    productStrategyNeedsUserInput(state) {
        return state.productstrategy.some((product) => !product.target_product);
    },
};

const actions = {
    async fetchProductStrategy({ commit, state }) {
        if (store.getters["session/current_entity_profile"] == "operator") {
            return;
        }
        commit("updateProductStrategy", []);
        commit("updateProductStrategyLoading", true);

        try {
            let response = await axios.get(
                `/api/receipts/${state.receipt.receipt_id}/productstrategy/`
            );
            commit("updateProductStrategy", response.data.products);
            return response.data.products;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        } finally {
            commit("updateProductStrategyLoading", false);
        }
    },

    fetchEvents({ commit, dispatch, state }, params) {
        if (
            store.getters["session/current_user_permissions"].indexOf(
                "core.view_event"
            ) == -1
        ) {
            return;
        }

        if (!state.events_filters) return;
        commit("updateEventsLoading", true);

        return new Promise((resolve, reject) => {
            axios
                .get("/api/events/", { params: state.events_filters })
                .then((response) => {
                    commit("updateEvents", response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit("updateEventsLoadingErrors", error.details);
                    reject(error);
                })
                .finally(() => {
                    commit("updateEventsLoading", false);
                });
        });
    },

    init({ commit, dispatch, state }, params) {
        dispatch("invoiceitems/init", {
            shipment: params.receipt,
            url: `/api/receipts/${params.receipt.receipt_id}/invoiceitems/`,
        });
        dispatch("documents/init", {
            filterTag: `receipt:${params.receipt.receipt_id}`,
            shareWith: params.receipt.owner ? { customer: params.receipt.owner } : null,
            createTags: [
                `receipt:${params.receipt.receipt_id}`,
                `provider:${params.receipt.provider}`,
            ],
        });
        commit("updateReceipt", params.receipt);

        dispatch("fetchReceiptStats");
        dispatch("session/fetchStats", null, { root: true });
        let is_receipt_operator =
            params.receipt.operator == store.getters["session/current_entity_id"];
        commit("updateIsReceiptOperator", is_receipt_operator);
        if (is_receipt_operator && params.receipt.ready) {
            dispatch("fetchWaitingReceiptItems");
        } else {
            dispatch("fetchProducts");
            dispatch("fetchReceiptItems");
        }
        dispatch("fetchEvents");
        dispatch("invoiceitems/fetchInvoiceItems");
        dispatch("documents/fetchDocuments");
        dispatch("fetchProviderStats");
        dispatch("fetchProductStrategy");
    },

    fetchReceipt({ commit, dispatch, state }, params) {
        commit("updateReceiptLoading", true);
        commit("updateReceiptLoadingErrors", null);

        let url;
        if (params && params.receipt_id) {
            url = `/api/receipts/${params.receipt_id}/`;
        } else if (state.receipt) {
            url = `/api/receipts/${state.receipt.receipt_id}/`;
        } else {
            return;
        }
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((response) => {
                    commit("updateReceipt", response.data);
                    commit("updateReceiptLoading", false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit("updateReceiptLoading", false);
                    commit("updateReceiptLoadingErrors", error.details);
                    reject(error);
                })
                .finally(() => {
                    dispatch("fetchReceiptStats");
                    dispatch("fetchWaitingReceiptItems");
                    dispatch("fetchProducts");
                    dispatch("fetchReceiptItems");
                    dispatch("fetchEvents");
                    dispatch("invoiceitems/fetchInvoiceItems");
                    dispatch("documents/fetchDocuments");
                    dispatch("fetchProviderStats");
                });
        });
    },

    deleteReceipt({ commit, dispatch, state }, params) {
        commit("updateReceiptDeleting", true);
        commit("updateReceiptDeletingErrors", null);

        const url = `/api/receipts/${params.instance.receipt_id}/`;
        return new Promise((resolve, reject) => {
            axios
                .delete(url)
                .then((response) => {
                    commit("updateReceiptDeleting", false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit("updateReceiptDeleting", false);
                    commit("updateReceiptDeletingErrors", error.details);
                    reject(error);
                })
                .finally(() => {
                    dispatch("session/fetchStats", null, { root: true });
                });
        });
    },

    deleteReceiptItem({ commit, dispatch, state }, params) {
        commit("updateReceiptItemDeleting", true);
        commit("updateReceiptItemDeletingErrors", null);

        const url = `/api/receiptitems/${params.instance.id}/`;
        return new Promise((resolve, reject) => {
            axios
                .delete(url)
                .then((response) => {
                    dispatch("fetchReceipt");
                    commit("updateReceiptItemDeleting", false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit("updateReceiptItemDeleting", false);
                    commit("updateReceiptItemDeletingErrors", error.details);
                    reject(error);
                });
        });
    },

    saveReceipt({ commit, dispatch, state }, params) {
        commit("updateReceiptSaving", true);
        commit("updateReceiptSavingErrors", null);

        let url = "/api/receipts/";
        let method = axios.post;

        if (params.instance.id) {
            url = `/api/receipts/${params.instance.receipt_id}/`;
            method = axios.put;
        }
        return new Promise((resolve, reject) => {
            method(url, params.instance)
                .then((response) => {
                    dispatch("fetchReceipt");
                    commit("updateReceiptSaving", false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit("updateReceiptSaving", false);
                    commit("updateReceiptSavingErrors", error.details);
                    reject(error);
                });
        });
    },

    downloadProviderOrderForm({ commit, dispatchm, state }, params) {
        var url = `/forms/receiptproviderorders/${params.instance.receipt_id}/`;
        window.open(url);
    },

    setReceiptReadyness({ commit, dispatch, state }, params) {
        const url = `/api/receipts/${params.receipt.receipt_id}/ready/`;
        commit("updateSettingReadyness", true);

        return new Promise((resolve, reject) => {
            axios
                .put(url, {
                    ready: params.ready,
                    productstrategy: params.productstrategy,
                })
                .then((response) => {
                    dispatch("fetchReceipt");
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                })
                .finally(() => {
                    commit("updateSettingReadyness", false);
                    dispatch("session/fetchStats", null, { root: true });
                });
        });
    },

    placeReceipt({ commit, dispatch, state }, params) {
        const url = `/api/receipts/${state.receipt.receipt_id}/place/`;
        commit("updatePlacingReceipt", true);

        return new Promise((resolve, reject) => {
            axios
                .put(url)
                .then((response) => {
                    dispatch("fetchReceipt");
                    resolve(response);
                })
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                })
                .finally(() => {
                    commit("updatePlacingReceipt", false);
                    dispatch("session/fetchStats", null, { root: true });
                });
        });
    },

    assignReceipt({ commit, dispatch, state }, params) {
        const url = `/api/receipts/${state.receipt.receipt_id}/assign/`;
        commit("updateAssigningReceipt", true);

        return new Promise((resolve, reject) => {
            axios
                .put(url, { user: params.user })
                .then((response) => {
                    dispatch("fetchReceipt");
                    resolve(response);
                })
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                })
                .finally(() => {
                    commit("updateAssigningReceipt", false);
                    dispatch("session/fetchStats", null, { root: true });
                });
        });
    },

    completeReceipt({ commit, dispatch, state }, params) {
        const url = `/api/receipts/${state.receipt.receipt_id}/complete/`;
        commit("updateCompletingReceipt", true);

        return new Promise((resolve, reject) => {
            axios
                .put(url)
                .then((response) => {
                    dispatch("fetchReceipt");
                    resolve(response);
                })
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                })
                .finally(() => {
                    commit("updateCompletingReceipt", false);
                    dispatch("session/fetchStats", null, { root: true });
                });
        });
    },

    setReceiptItemComplete({ commit, dispatch, state }, params) {
        const data = {
            status: "complete",
        };
        return new Promise((resolve, reject) => {
            axios
                .put(`/api/receiptitems/${params.receiptitem.id}/status/`, data)
                .then((response) => {
                    dispatch("fetchReceipt");
                    resolve(response);
                })
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                })
                .finally(() => {
                    dispatch("session/fetchStats", null, { root: true });
                });
        });
    },

    setReceiptItemWaiting({ commit, dispatch, state }, params) {
        const data = {
            status: "waiting",
        };
        return new Promise((resolve, reject) => {
            axios
                .put(`/api/receiptitems/${params.receiptitem.id}/status/`, data)
                .then((response) => {
                    dispatch("fetchReceipt");
                    resolve(response);
                })
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                })
                .finally(() => {
                    dispatch("fetchEvents");
                    dispatch("session/fetchStats", null, { root: true });
                });
        });
    },

    async fetchReceiptItems({ commit, state }) {
        commit("updateReceiptItemsLoading", true);
        commit("updateReceiptItemsLoadingErrors", null);

        if (state.receiptitems_cancel_source) {
            state.receiptitems_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit("updateReceiptItemsCancelSource", cancelSource);

        try {
            const response = await axios.get("/api/receiptitems/", {
                params: state.receiptitems_filters,
                cancelToken: state.receiptitems_cancel_source?.token,
            });
            commit("updateReceiptItems", response.data.results);
            commit("updateReceiptItemsCount", response.data.count);
            commit("updateReceiptItemsCancelSource", null);
            return response;
        } catch (xhr_error) {
            if (axios.isCancel(xhr_error)) {
                return;
            }
            const error = utils.handleError(xhr_error);
            commit("updateReceiptItemsLoadingErrors", error.details);
            throw error;
        } finally {
            commit("updateReceiptItemsLoading", false);
        }
    },

    fetchProviderStats({ commit, dispatch, state }, params) {
        if (!state.receipt.provider) {
            return;
        }

        if (
            state.receipt.provider_data.owner !=
            store.getters["session/current_entity_id"]
        ) {
            return;
        }
        return new Promise((resolve, reject) => {
            axios
                .get(`/api/providers/${state.receipt.provider}/stats/`)
                .then((response) => {
                    commit("updateProviderStats", response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                });
        });
    },

    fetchWaitingReceiptItems({ commit, dispatch, state }, params) {
        commit("updateWaitingReceiptItemsLoading", true);
        commit("updateWaitingReceiptItemsLoadingErrors", null);

        return new Promise((resolve, reject) => {
            axios
                .get("/api/receiptitems/", { params: state.waitingreceiptitems_filters })
                .then((response) => {
                    commit("updateWaitingReceiptItems", response.data.results);
                    commit("updateWaitingReceiptItemsCount", response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit("updateWaitingReceiptItemsLoadingErrors", error.details);
                    reject(error);
                })
                .finally(() => {
                    commit("updateWaitingReceiptItemsLoading", false);
                });
        });
    },

    fetchProducts({ commit, dispatch, state }, params) {
        commit("updateProductsLoading", true);
        commit("updateProductsLoadingErrors", null);

        if (state.products_cancel_source) {
            state.products_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit("updateProductsCancelSource", cancelSource);

        return new Promise((resolve, reject) => {
            axios
                .get(`/api/providerproducts/`, {
                    params: state.products_filters,
                    cancelToken: state.products_cancel_source.toke,
                })
                .then((response) => {
                    commit("updateProducts", response.data.results);
                    commit("updateProductsCount", response.data.count);
                    commit("updateProductsLoading", false);
                    commit("updateProductsCancelSource", null);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    if (axios.isCancel(xhr_error)) {
                        return;
                    }
                    let error = utils.handleError(xhr_error);
                    commit("updateProductsCancelSource", null);
                    commit("updateProductsLoadingErrors", error.details);
                    commit("updateProductsLoading", false);
                    commit("updateProductsCancelSource", null);
                    reject(error);
                });
        });
    },

    async fetchReceiptStats({ commit, dispatch, state }, params) {
        commit("updateReceiptStatsLoading", true);
        commit("updateReceiptStatsLoadingErrors", null);
        try {
            const response = await axios.get(
                `/api/receipts/${state.receipt.receipt_id}/stats/`
            );
            commit("updateReceiptStats", response.data);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateReceiptStatsLoadingErrors", error.details);
            throw error;
        } finally {
            commit("updateReceiptStatsLoading", false);
        }
    },

    exportReceiptItemsAsCSV({ commit, dispatch, state }, params) {
        let filters = {
            format: "csv",
            limit: 10000,
            receipt: state.receipt.receipt_id,
        };
        const queryparams = qs.stringify(filters, { arrayFormat: "repeat" });
        window.open("/api/receiptitems/?" + queryparams);
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
    modules: {
        documents: documents(),
        invoiceitems: invoiceitems(),
    },
};
