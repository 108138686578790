var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    "class": {
      link: !_vm.error
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.openObject.apply(null, arguments);
      }
    }
  }, [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }