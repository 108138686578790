"use strict";

import Vue from 'vue';
import axios from "axios";

// Base configuration for Axios
const axiosConfig = {
  // Add your configuration here if needed
};

const apiClient = axios.create(axiosConfig);

let refreshTimeout;

// Function to set or reset the refresh timeout
const setRefreshTimeout = () => {
  clearTimeout(refreshTimeout);
  refreshTimeout = setTimeout(() => {
    window.location.reload(); // Refresh the page after 4 hours of inactivity
  }, 4 * 60 * 60 * 1000); // 4 hours in milliseconds
};

// Response interceptor to reset the timeout on each successful response
apiClient.interceptors.response.use(
  response => {
    setRefreshTimeout(); // Reset the timeout on each successful response
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      window.location.reload(); // Refresh the page if unauthorized
    }
    return Promise.reject(error);
  }
);
apiClient.CancelToken = axios.CancelToken;
apiClient.isCancel = axios.isCancel;
apiClient.Cancel = axios.Cancel;

export default apiClient;
