import qs from "qs";
import catalogs from "./submodules/catalogs";

const state = {
    section: null,
};

const mutations = {
    updateSection(state, section) {
        state.section = section;
    },
};

const actions = {
    exportCatalogsAsCSV({ commit, dispatch, state }, params) {
        let filters = {};
        if (params.filtered) {
            filters = Object.assign({}, state.catalogs_filters);
            filters.offset = null;
        }
        filters.format = "csv";
        filters.limit = 10000;
        const queryparams = qs.stringify(filters, { arrayFormat: "repeat" });
        window.open("/api/catalogs/?" + queryparams);
    },

    init({ commit, dispatch, state }, params) {
        dispatch("catalogs/init", { filters: { owned: true } });
        dispatch("assignedcatalogs/init", {
            url: "/api/assignedcatalogs/",
            filters: { owned: false },
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        catalogs: catalogs(),
        assignedcatalogs: catalogs(),
    },
};
