import posthog from "posthog-js";

export default {
  install(Vue, options) {
    // If prod environment, use prod key, else don't track
    if (process.env.NODE_ENV == "production") {
      Vue.prototype.$posthog = posthog.init(
        "phc_ineZR06S2aVScRfk695XhG9K95n1JzMC9HpuPpRL2sk",
        {
          api_host: "https://eu.i.posthog.com",
          person_profiles: 'identified_only',
        }
      );
    }
  }
};