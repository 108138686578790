var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "address mb-3"
  }, [_vm.title ? _c('h6', {
    staticClass: "mt-3 mb-5 text-overline font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]) : _vm._e(), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "error-messages": _vm.errors.contact_name,
      "label": _vm.$translate('contactname'),
      "disabled": _vm.readonly
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.address || {});
      }
    },
    model: {
      value: _vm.address.contact_name,
      callback: function callback($$v) {
        _vm.$set(_vm.address, "contact_name", $$v);
      },
      expression: "address.contact_name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "error-messages": _vm.errors.email || (_vm.highlightMandatory && !_vm.address.email ? [_vm.$translate('required')] : null),
      "label": _vm.$translate('email'),
      "disabled": _vm.readonly
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.address || {});
      }
    },
    model: {
      value: _vm.address.email,
      callback: function callback($$v) {
        _vm.$set(_vm.address, "email", $$v);
      },
      expression: "address.email"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "error-messages": _vm.errors.line1 || (_vm.highlightMandatory && !_vm.address.line1 ? [_vm.$translate('required')] : null),
      "label": _vm.$translate('address'),
      "disabled": _vm.readonly
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.address || {});
      }
    },
    model: {
      value: _vm.address.line1,
      callback: function callback($$v) {
        _vm.$set(_vm.address, "line1", $$v);
      },
      expression: "address.line1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "error-messages": _vm.errors.postal_code || (_vm.highlightMandatory && !_vm.address.postal_code ? [_vm.$translate('required')] : null),
      "label": _vm.$translate('postalCode'),
      "disabled": _vm.readonly
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.address || {});
      }
    },
    model: {
      value: _vm.address.postal_code,
      callback: function callback($$v) {
        _vm.$set(_vm.address, "postal_code", $$v);
      },
      expression: "address.postal_code"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "error-messages": _vm.errors.city || (_vm.highlightMandatory && !_vm.address.city ? [_vm.$translate('required')] : null),
      "label": _vm.$translate('city'),
      "disabled": _vm.readonly
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.address || {});
      }
    },
    model: {
      value: _vm.address.city,
      callback: function callback($$v) {
        _vm.$set(_vm.address, "city", $$v);
      },
      expression: "address.city"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "error-messages": _vm.errors.country,
      "label": _vm.$translate('country'),
      "disabled": _vm.readonly
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.address || {});
      }
    },
    model: {
      value: _vm.address.country,
      callback: function callback($$v) {
        _vm.$set(_vm.address, "country", $$v);
      },
      expression: "address.country"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "error-messages": _vm.errors.mobile_phone_number,
      "label": _vm.$translate('mobileNumber'),
      "disabled": _vm.readonly
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.address || {});
      }
    },
    model: {
      value: _vm.address.mobile_phone_number,
      callback: function callback($$v) {
        _vm.$set(_vm.address, "mobile_phone_number", $$v);
      },
      expression: "address.mobile_phone_number"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "error-messages": _vm.errors.phone_number,
      "label": _vm.$translate('phoneNumber'),
      "disabled": _vm.readonly
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.address || {});
      }
    },
    model: {
      value: _vm.address.phone_number,
      callback: function callback($$v) {
        _vm.$set(_vm.address, "phone_number", $$v);
      },
      expression: "address.phone_number"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }