<template>
    <div>
        <v-btn
            :loading="loading"
            v-if="!items"
            :key="action"
            class="w-sm-auto w-100"
            :light="!main"
            :disabled="disabled"
            :text="$vuetify.breakpoint.name == 'xs' ? false : !main"
            :color="
                main
                    ? 'primary'
                    : $vuetify.breakpoint.name == 'xs'
                    ? 'primary-light'
                    : 'primary'
            "
            elevation="0"
            @click="actionClicked(action)"
        >
            <v-icon
                small
                :color="main ? 'white' : color || 'primary-light'"
                v-if="icon"
                left
                >{{ iconName(icon) || icon }}</v-icon
            >

            <div :class="main ? 'white--text' : 'primary-text'">
                {{ label }}
            </div>
        </v-btn>

        <v-menu offset-y v-else>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    :key="action"
                    class="w-sm-auto w-100"
                    light
                    elevation="0"
                    :text="$vuetify.breakpoint.name == 'xs' ? false : !main"
                    :color="
                        main
                            ? 'primary'
                            : $vuetify.breakpoint.name == 'xs'
                            ? 'primary-light'
                            : 'primary'
                    "
                    v-bind="attrs"
                    v-on="on"
                >
                    <div :class="main ? 'white--text' : 'primary-text'">
                        {{ label }}
                    </div>
                    <v-icon
                        small
                        :color="main ? 'white' : 'primary-light'"
                        v-if="icon"
                        right
                        >{{ iconName("down") }}</v-icon
                    >
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                    :disabled="item.disabled"
                    @click="actionClicked(item)"
                >
                    <v-icon small v-if="item.icon" left :color="item.color">{{
                        iconName(item.icon) || item.icon
                    }}</v-icon
                    >{{ item.label }}
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import { mapState } from "vuex";
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    props: {
        action: {
            type: Object,
            required: false,
        },
        main: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        actions: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: "primary",
        },
        items: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: false,
        },
    },
    methods: {
        actionClicked(action) {
            this.$emit("on-action", action.action);
            this.$emit(action.action);
        },
    },
};
</script>

<style lang="scss"></style>
