import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const createState = () => ({
    receipts: [],
    receipts_filters: { offset: 0, limit: 20, },
    receipts_count: 0,
    receipts_loaded: false,
    receipts_loading: false,
    receipts_loading_errors: null,
});

const mutations = {
    updateReceipts(state, receipts) {
        state.receipts = receipts;
        state.receipts_loaded = true;
        state.receipts_loading = false;
        state.receipts_loading_errors = null;
    },
    updateReceiptsFilters(state, receipts_filters) {
        state.receipts_filters = receipts_filters;
    },
    updateReceiptsLoading(state, receipts_loading) {
        state.receipts_loading = receipts_loading;
    },
    updateReceiptsLoadingErrors(state, receipts_loading_errors) {
        state.receipts_loading_errors = receipts_loading_errors;
        state.receipts_loading = false;
    },
    updateReceiptsCount(state, receipts_count) {
        state.receipts_count = receipts_count;
    },
};

const actions = {

    fetchReceipts({ commit, dispatch, state }, params) {
        if (store.getters['session/current_user_permissions'].indexOf("core.view_receipt") == -1) {
            return;
        }

        commit('updateReceiptsLoading', true);
        commit('updateReceiptsLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get("/api/receipts/", { params: state.receipts_filters })
                .then((response) => {
                    commit('updateReceipts', response.data.results);
                    commit('updateReceiptsCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateReceiptsLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateReceiptsLoading', false);
                });

        });
    },

    init({ commit, dispatch, state }, params) {
        commit('updateReceipts', []);
        const default_filters = { offset: 0, limit: 20 };
        commit('updateReceiptsFilters', { ...default_filters, ...(params || {}) });
    },
};


export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});