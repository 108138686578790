var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "listwrapper"
  }, [_c('Filters', {
    attrs: {
      "filters": {},
      "filters-conf": _vm.filtersConf,
      "search": _vm.search,
      "search-label": _vm.searchLabel
    },
    on: {
      "on-filters-change": _vm.onFiltersChange
    }
  }), _vm.mainFilters ? _c('div', {
    key: _vm.activemainfilter,
    staticClass: "mainfilter"
  }, [_c('div', {
    staticClass: "d-flex"
  }, _vm._l(_vm.mainFilters, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "mainfilter__item",
      "class": {
        active: _vm.activemainfilter && item.code == _vm.activemainfilter
      },
      on: {
        "click": function click($event) {
          return _vm.selectMainFilter(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0)]) : _vm._e(), _c('div', {
    staticClass: "row mt-2"
  }, [_c('div', {
    staticClass: "col-12 col-md-auto"
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading && _vm.items.length && _vm.title,
      expression: "!loading && items.length && title"
    }]
  }, [_c('em', [_vm._v(_vm._s(_vm.title))])]), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading && !_vm.items.length,
      expression: "!loading && !items.length"
    }]
  }, [_c('em', {
    directives: [{
      name: "translate",
      rawName: "v-translate"
    }]
  }, [_vm._v("Nothing found")])]), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c('em', {
    directives: [{
      name: "translate",
      rawName: "v-translate"
    }]
  }, [_vm._v("Loading")])])])]), _vm.items.length ? _c('div', {
    staticClass: "list mt-2"
  }, [_c('ul', {
    staticClass: "pa-0"
  }, _vm._l(_vm.filtered_items, function (item, index) {
    return _c('li', {
      key: item.id
    }, [_c('ListItem', {
      attrs: {
        "item": item,
        "edit": _vm.edit,
        "remove": _vm.remove
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('clicked-item', item);
        },
        "edit-item": function editItem($event) {
          return _vm.$emit('edit-item', item);
        },
        "remove-item": function removeItem($event) {
          return _vm.$emit('remove-item', item);
        }
      },
      scopedSlots: _vm._u([{
        key: "listitem",
        fn: function fn(scope) {
          return [_vm._t("item", null, {
            "item": scope.scope,
            "index": index
          })];
        }
      }], null, true)
    })], 1);
  }), 0)]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }