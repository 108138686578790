<template>
    <div class="tablewrapper" :class="className">
        <v-card-text class="controls" v-if="filtersConf.length">
            <Filters
                :filters-conf="filtersConf"
                :filters="filters"
                :enable-ordering="enableOrdering"
                :exclude-from-ordering="excludeFromOrdering"
                @on-filters-change="onFiltersChange($event)"
                :display-filters="displayFilters"
            />
            <div
                class="link mt-4 text-end"
                v-if="!displayFilters && hasHiddenFilters"
                @click="display_filters = true"
            >
                {{ $translate("displayFilters") }}
            </div>
        </v-card-text>
        <div class="pa-4" v-if="value?.length && actions?.length">
            <Block class="d-block d-sm-flex">
                <div class="flex-grow-1 align-center d-flex black--text mb-2">
                    <span
                        >{{ $translate("youSelected") }}
                        <span class="font-weight-bold">
                            <template v-if="value?.length == 1">
                                1 {{ $translate("item") }}
                            </template>
                            <template v-else>
                                {{ value.length }}
                                {{ $translate("items") }}</template
                            >.
                        </span>
                        <template v-if="value.length == 1">
                            {{ $translate("selectionItemInstructions") }}
                        </template>
                        <template v-else>
                            {{ $translate("selectionItemsInstructions") }}
                        </template>
                    </span>
                </div>
                <v-btn
                    @click="clearSelection"
                    class="mr-2 font-weigth-regular mb-2 w-100 w-sm-auto"
                    text
                >
                    <v-icon class="mr-1">{{ iconName("delete") }}</v-icon>
                    {{ $translate("clearSelection") }}
                </v-btn>
                <Action
                    class="mt-2 mt-sm-0 mb-2"
                    :label="$translate('selectAction')"
                    :main="true"
                    :items="actions"
                    @on-action="runBulkAction"
                />
            </Block>
        </div>
        <Placeholder
            v-if="errors?.length"
            :title="$gettext('Error loading data')"
            icon="nodata"
            :description="
                $gettext(
                    'An error occured while loading data. Please try again or contact us for support.'
                )
            "
            :actions="[
                {
                    text: $gettext('Retry'),
                    action: 'retry',
                },
            ]"
            @on-action="onErrorAction"
        />
        <template v-else-if="!rowsCount && !loading">
            <Placeholder
                :icon="placeholderIcon"
                :title="placeholderTitle || $gettext('No result found')"
                :description="
                    placeholderDescription ||
                    $gettext(
                        'Nothing was found, try to clear filters or include archived items in your search.'
                    )
                "
                :actions="placeholderActions || []"
                @on-action="$emit('on-placeholder-action', $event)"
            />
        </template>
        <Loading :absolute="false" v-else-if="!rowsCount && loading" />
        <v-data-table
            v-else-if="rowsCount"
            :value="value"
            @input="$emit('input', $event)"
            :headers="headers"
            :items="rows || []"
            :loading="loading"
            :page.sync="page"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :must-sort="true"
            :single-select="false"
            :disable-sort="isMobile"
            :selectable-key="selectableKey"
            :item-key="itemKey"
            :item-value="itemValue"
            :show-select="showSelect"
            :server-items-length="remote ? rowsCount : rowsCount"
            :items-per-page.sync="itemsPerPage"
            :no-results-text="placeholderTitle || $gettext('No result found')"
            :no-data-text="placeholderTitle || $gettext('No result found')"
            :footer-props="
                remote
                    ? {
                          'items-per-page-options': [10, 20, 30, 40, 50, 100, -1],
                      }
                    : null
            "
            :item-class="itemClass"
            :hide-default-footer="hideDefaultFooter"
        >
            <template v-slot:item="{ item, index, headers }">
                <tr
                    :key="index"
                    :class="[item[itemClass], { pointer: clickableRow }]"
                    @click="$emit('on-row-click', item)"
                >
                    <td
                        v-for="header in headers"
                        :key="header.field + '-' + header.type"
                        class="mb-0 mb-sm-0"
                        :style="{
                            minWidth: header.minWidth,
                        }"
                    >
                        <slot
                            :name="header.field"
                            :row="item"
                            :header="header"
                            :value="resolve(item, header.field)"
                        >
                            <div v-if="header.type == 'actions'" class="actions">
                                <template v-if="header.actions">
                                    <CellAction
                                        v-for="action in header.actions"
                                        :key="action.action"
                                        :icon="action.icon"
                                        :type="action.type"
                                        :label="action.label"
                                        :color="action.color"
                                        :disabled="action.disabled"
                                        :placeholder="action.placeholder"
                                        @click.stop.prevent="handleAction(action, item)"
                                    />
                                </template>
                            </div>
                            <template v-else-if="header.value == 'data-table-select'">
                                <v-checkbox
                                    class="mt-0 pt-0"
                                    hide-details
                                    single-line
                                    outlined
                                    dense
                                    :key="`${item.id}-${isSelected(item)}`"
                                    :input-value="
                                        isSelected(item) || alreadySelectedFunc(item)
                                    "
                                    :disabled="
                                        selectableKey ? !item[selectableKey] : false
                                    "
                                    @click.stop
                                    @change="toggleItem(item, $event)"
                                ></v-checkbox>
                            </template>
                            <div v-else-if="header.type == 'getactions'" class="actions">
                                <CellAction
                                    v-for="action in header.get_actions(item)"
                                    :key="action.action"
                                    :icon="action.icon"
                                    :type="action.type"
                                    :label="action.label"
                                    :color="action.color"
                                    :disabled="action.disabled"
                                    @click.stop.prevent="handleAction(action, item)"
                                />
                            </div>

                            <template v-else-if="header.type == 'month'">{{
                                resolve(item, header) | moment("MMM YYYY")
                            }}</template>

                            <template v-else-if="header.component">
                                <component v-bind:is="header.component" :item="item" />
                            </template>

                            <template v-else-if="header.type == 'boolean'">
                                <span :key="resolve(item, header)">
                                    <translate v-if="resolve(item, header)"
                                        >Yes</translate
                                    >
                                    <translate v-else>No</translate>
                                </span>
                            </template>

                            <template v-else-if="header.type == 'product'">
                                <router-link
                                    v-if="routeExists('product')"
                                    :to="{
                                        name: 'product',
                                        params: {
                                            product_id: resolve(item, header, 'id'),
                                        },
                                    }"
                                    :href="`/products/${resolve(item, header, 'id')}/`"
                                    >{{ resolve(item, header, "fullname") }}</router-link
                                >
                                <template v-else>{{
                                    resolve(item, header, "fullname")
                                }}</template>
                            </template>

                            <template v-else-if="header.type == 'customer'">
                                <router-link
                                    v-if="routeExists('customer')"
                                    :to="{
                                        name: 'customer',
                                        params: {
                                            customer_id: resolve(item, header, 'id'),
                                        },
                                    }"
                                    >{{ resolve(item, header, "name") }}</router-link
                                >
                                <template v-else>{{
                                    resolve(item, header, "name")
                                }}</template>
                            </template>

                            <template v-else-if="header.type == 'stock'">
                                {{ resolve(item, header, "name") }}
                            </template>

                            <template v-else-if="header.type == 'provider'">
                                <router-link
                                    v-if="routeExists('provider')"
                                    :to="{
                                        name: 'provider',
                                        params: {
                                            provider_id: resolve(item, header, 'id'),
                                        },
                                    }"
                                    :href="`/providers/${resolve(item, header, 'id')}/`"
                                    >{{ resolve(item, header, "name") }}</router-link
                                >
                                <template v-else>{{
                                    resolve(item, header, "name")
                                }}</template>
                            </template>

                            <template v-else-if="header.type == 'receipt'">
                                <router-link
                                    v-if="routeExists('receipt')"
                                    :to="{
                                        name: 'receipt',
                                        params: {
                                            receipt_id: resolve(
                                                item,
                                                header,
                                                'receipt_id'
                                            ),
                                        },
                                    }"
                                    :href="`/receipts/${resolve(
                                        item,
                                        header,
                                        'receipt_id'
                                    )}/`"
                                    >{{
                                        resolve(item, header, "receipt_id")
                                    }}</router-link
                                >
                                <template v-else>{{
                                    resolve(item, header, "receipt_id")
                                }}</template>
                            </template>

                            <template v-else-if="header.type == 'email'">
                                <a
                                    @click.prevent
                                    :href="`mailto:${resolve(item, header)}`"
                                    >{{ resolve(item, header) }}</a
                                >
                            </template>

                            <template v-else-if="header.subtype == 'euros'"
                                ><template v-if="resolve(item, header) !== null">
                                    {{ resolve(item, header) | price }}</template
                                >
                            </template>

                            <template
                                v-else-if="
                                    header.type == 'months' && resolve(item, header)
                                "
                            >
                                <span
                                    v-translate="{
                                        months: resolve(item, header),
                                    }"
                                    :translate-n="resolve(item, header)"
                                    translate-plural="%{months} months"
                                >
                                    %{ months } month
                                </span>
                            </template>

                            <template
                                v-else-if="
                                    header.type == 'percentage' ||
                                    header.type == 'percent'
                                "
                            >
                                <template v-if="resolve(item, header) !== null">
                                    {{ resolve(item, header) * 100 }}
                                    %
                                </template>
                                <template v-else>
                                    <em class="tableplaceholder">-</em>
                                </template>
                            </template>

                            <template v-else-if="header.type == 'link'">
                                <router-link
                                    :to="header.link_to(item)"
                                    :href="header.link_href(item)"
                                    >{{ resolve(item, header) }}</router-link
                                >
                            </template>

                            <template v-else-if="resolve(item, header) !== null">{{
                                resolve(item, header)
                            }}</template>
                            <template v-else-if="header.placeHolder">
                                <em class="tableplaceholder">{{ header.placeHolder }}</em>
                            </template>
                        </slot>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import Vue from "vue";
import CellAction from "./cell-action";
import GlobalMixins from "@/mixins/global";

export default {
    components: {
        CellAction,
    },
    mixins: [GlobalMixins],
    props: {
        value: {
            required: false,
        },
        bulkAction: {
            required: false,
            type: Function,
            default: () => {
                return () => Promise.resolve();
            },
        },

        placeholderIcon: {
            default: "noresult",
            type: String,
            required: false,
        },
        placeholderTitle: {
            type: String,
            required: false,
        },
        placeholderDescription: {
            type: String,
            required: false,
        },
        placeholderActions: {
            type: Array,
            required: false,
        },
        className: {
            required: false,
        },
        rows: {
            required: true,
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        showTitle: {
            type: Boolean,
            required: false,
            default: false,
        },
        enableOrdering: {
            type: Boolean,
            default: false,
        },
        filtersConf: {
            required: false,
            default: () => [],
        },
        filters: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },
        columns: {
            required: true,
        },
        title: {
            type: [String, Boolean],
            required: false,
        },
        itemClass: {
            required: false,
        },
        rowsCount: {
            required: true,
            default: null,
        },
        clickableRow: {
            required: false,
            type: Boolean,
            default: true,
        },
        errors: {
            required: false,
            type: Array,
            default: () => [],
        },
        showSelect: {
            required: false,
            type: Boolean,
            default: false,
        },
        itemKey: {
            required: false,
            type: String,
            default: "id",
        },
        selectableKey: {
            required: false,
            type: String,
            default: null,
        },
        itemValue: {
            required: false,
            type: String,
            default: null,
        },
        remote: {
            required: false,
            type: Boolean,
            default: true,
        },
        hideDefaultFooter: {
            required: false,
            type: Boolean,
            default: false,
        },
        alreadySelectedFunc: {
            required: false,
            type: Function,
            default: () => {
                return false;
            },
        },
        actions: {
            required: false,
            type: Array,
            default: () => [],
        },
        bulkError: {
            required: false,
            type: Function,
            default: (error) => {
                return error;
            },
        },
    },
    computed: {
        hasHiddenFilters() {
            return !!this.filtersConf.filter((filter) => filter != "search").length;
        },
        excludeFromOrdering() {
            return this.columns.map((column) => column.field);
        },
        filtersItemsPerPage() {
            return (this.filters ? this.filters.limit : 20) || 20;
        },

        headers() {
            let displayed_columns = this.columns.filter((column) => {
                return (
                    !column.hide ||
                    column.hide.indexOf(this.$vuetify.breakpoint.name) == -1
                );
            });

            return displayed_columns
                .map((item) => {
                    if (item.type == "_orderingfield") {
                        if (!this.filters.ordering) {
                            return null;
                        }
                        // Get ordering field without leading '-'
                        let rawfield = this.filters.ordering;
                        rawfield = rawfield.slice(rawfield.lastIndexOf("-") + 1);
                        // Do not take ordering field column into account if already displayed on columns
                        if (
                            displayed_columns.find(
                                (finditem) => finditem.field == rawfield
                            )
                        ) {
                            return null;
                        }
                        item.field = this.filters.ordering;
                        item.label = this.$gettext("Order filter");
                        item.sortable = true;
                    }

                    let column = Object.assign(item, {
                        text: item.label,
                        value: item.field,
                    });
                    return column;
                })
                .filter((item) => item);
        },
        displayFilters() {
            if (this.$vuetify.breakpoint.name == "xs") {
                return this.display_filters;
            }
            return true;
        },
    },
    data() {
        let filtersSortBy = this.filters.ordering
            ? this.filters.ordering.slice(this.filters.ordering.lastIndexOf("-") + 1)
            : null;

        let filtersSortDesc = this.filters.ordering && this.filters.ordering[0] == "-";
        let data = {
            sortBy: [filtersSortBy],
            sortDesc: [filtersSortDesc],
            itemsPerPage: this.filters.limit,
            page: parseInt((this.filters.offset || 0) / this.filters.limit) + 1,
            display_filters: false,
        };
        return data;
    },
    watch: {
        page: {
            handler(page) {
                let filters = Object.assign({ limit: this.itemsPerPage }, this.filters);
                filters.offset = (page - 1) * this.filters.limit;
                this.$emit("on-filters-change", filters);
            },
        },
        itemsPerPage: {
            handler(itemsPerPage) {
                let filters = Object.assign({}, this.filters);
                filters.limit = itemsPerPage;
                filters.offset = 0;
                this.page = 1;
                this.$emit("on-filters-change", filters);
            },
        },
        sortBy: {
            handler(sortBy) {
                let filters = Object.assign({}, this.filters);
                filters.ordering = this.getOrdering();
                filters.offset = 0;
                this.page = 1;
                this.$emit("on-filters-change", filters);
            },
        },
        sortDesc: {
            handler(sortDesc) {
                let filters = Object.assign({}, this.filters);
                filters.ordering = this.getOrdering();
                this.$emit("on-filters-change", filters);
            },
        },
    },
    methods: {
        runBulkAction(action) {
            this.bulkAction({ action })
                .then((result) => {
                    if (!result.errors.length) {
                        this.addAlert(this.$gettext("Bulk action completed"));
                    } else {
                        let errorcount = result.errors.length;
                        console.log(result.errors, this.bulkError({}));
                        let errors = result.errors.map((instance) => {
                            return this.bulkError(instance);
                        });

                        this.$swal({
                            title: this.$gettext("Bulk action failed"),
                            html:
                                this.$gettext(
                                    "There were {errorcount} error(s) in the bulk action."
                                ).replace("{errorcount}", errorcount) +
                                "<br><br>" +
                                "<div style='font-size: 0.9em;'>" +
                                errors.join("<br><br>") +
                                "</div>",
                            type: "error",
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.$swal({
                        title: this.$gettext("Bulk action failed"),
                        html: this.$utils.resultToSwalContent(this, error),
                        type: "error",
                    });
                });
        },
        clearSelection() {
            this.$emit("input", []);
        },
        toggleItem(item, selected) {
            if (selected) {
                this.$emit("on-row-selected", item);
                let new_list = (this.value || []).concat(item);
                this.$emit("input", new_list);
            } else {
                this.$emit("on-row-deselected", item);
                let new_list = this.value.filter(
                    (selected_item) => selected_item[this.itemKey] != item[this.itemKey]
                );
                this.$emit("input", new_list);
            }
        },
        displayColName(header) {
            if (["xs"].indexOf(this.$vuetify.breakpoint.name) == -1) {
                return false;
            }
            return header.type != "actions" && header.type != "getactions";
        },
        onErrorAction($event) {
            if ($event == "retry") {
                this.$emit("retry");
            }
        },
        onFiltersChange(filters) {
            filters.offset = 0;
            this.page = 1;

            this.$emit("on-filters-change", filters);
        },
        getOrdering() {
            if (!this.sortBy) {
                return null;
            }

            let sortDesc = this.sortDesc.length ? this.sortDesc[0] : false;
            let sortBy = this.sortBy.length ? this.sortBy[0] : null;

            if (!sortBy) {
                return null;
            }
            sortBy = sortBy.slice(sortBy.lastIndexOf("-") + 1);
            return (sortDesc ? "-" : "") + sortBy;
        },
        getStatusText(item, key) {
            return this.$utils.getStatusText(item[key]);
        },

        handleAction(action, item) {
            this.$emit("on-row-action", {
                action: action.action,
                item,
                params: action.params,
            });
        },

        getValue(obj, header) {
            let value = obj;
            if (header.field && header.field != "*") {
                const parts = Array.isArray(header.field) ? header.field : [header.field];

                let properties = [];
                parts.forEach((part) => {
                    part.split(".").forEach((dotsplit) => {
                        dotsplit.split("__").forEach((underscoresplit) => {
                            properties.push(underscoresplit);
                        });
                    });
                });
                value = properties.reduce((prev, curr) => prev && prev[curr], obj);
            }
            return value;
        },
        resolve(obj, header, final_field) {
            if (!header) {
                return;
            }
            let value = this.getValue(obj, header);
            if (header.render) {
                return header.render(value);
            }

            if (header.type == "datetime" && value && value.length) {
                if (["xs", "sm"].indexOf(this.$vuetify.breakpoint.name) == -1) {
                    value = Vue.moment(value).format(header.dateFormat || "LLL");
                } else {
                    value = Vue.moment(value).format("L");
                }
            }
            if (header.type == "humandate" && value && value.length) {
                if (["xs", "sm"].indexOf(this.$vuetify.breakpoint.name) == -1) {
                    value = Vue.moment(value).format(header.dateFormat || "LL");
                } else {
                    value = Vue.moment(value).format("L");
                }
            }
            if (value === null || value === undefined) {
                return null;
            }
            if (final_field) {
                return value[final_field];
            }

            return value;
        },
        isSelected(item) {
            return !!this.value?.find(
                (selected_item) => selected_item[this.itemKey] == item[this.itemKey]
            );
        },
    },
    i18n: {
        messages: {
            en: {
                displayFilters: "Display filters",
                selectionItemInstructions: "Choose an action to perform on this item.",
                selectionItemsInstructions: "Choose an action to perform on these items.",
                youSelected: "You selected",
                item: "item",
                items: "items",
                selectAction: "Select an action",
                clearSelection: "Clear selection",
            },
            fr: {
                displayFilters: "Afficher les filtres",
                selectionItemInstructions:
                    "Choisissez une action à effectuer sur cet élément.",
                selectionItemsInstructions:
                    "Choisissez une action à effectuer sur ces éléments.",
                youSelected: "Vous avez sélectionné",
                item: "élément",
                items: "éléments",
                selectAction: "Sélectionner une action",
                clearSelection: "Effacer la sélection",
            },
        },
    },
};
</script>

<style lang="scss">
.tablewrapper {
    td,
    th {
        white-space: nowrap;
    }
    .actions {
        display: flex;
        align-items: center;
        & > * + * {
            margin-left: 10px;
        }
    }
    .noresult--title {
        font-family: Raleway;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 16px;
    }
}
</style>
