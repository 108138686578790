<template>
    <v-card class="flex-grow-1" :elevation="elevation" @click="$emit('click', $event)">
        <v-card-title class="d-flex" :class="size == 'small' ? 'pb-0' : ''">
            <span class="flex-grow-1 ellipsis">{{ title }}</span>
            <v-icon
                :size="size == 'small' ? 20 : 40"
                color="primary-dark ml-auto"
                v-if="icon"
                >{{ iconName(icon) }}</v-icon
            >
        </v-card-title>
        <v-card-text class="d-flex flex-column justify-center my-0">
            <div v-if="loading" class="">
                <div class="d-flex align-center">
                    <i v-translate>Loading...</i>
                    <div class="text-h2">&nbsp;</div>
                </div>
                <div class="text-subtitle-2 ellipsis" :class="`${subtitleColor}--text`">
                    &nbsp;
                </div>
            </div>
            <template v-else>
                <div class="text-h2">{{ formatString(stat) }}&nbsp;</div>

                <div class="text-subtitle-2 ellipsis" :class="`${subtitleColor}--text`">
                    {{ subtitle }}&nbsp;
                </div>
            </template>
        </v-card-text>
    </v-card>
</template>

<script>
import GlobalMixins from "@/mixins/global";
import utils from "@/utils";

export default {
    mixins: [GlobalMixins],
    props: {
        size: {
            type: String,
            default: "normal",
        },
        title: {
            type: String,
            required: true,
        },
        elevation: {
            type: [String, Number],
            required: false,
        },
        stat: {
            type: [String, Number],
            required: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
        icon: {
            type: String,
            required: false,
        },
        subtitleColor: {
            type: String,
            required: false,
        },
        loading: {
            type: Boolean,
            required: false,
        },
        format: {
            type: String,
            required: false,
        },
    },
    methods: {
        formatString(value) {
            if (value === null || value === undefined) {
                return "-";
            }
            if (this.format === "euros") {
                return utils.price(value);
            }
            return utils.formatNumberToString(value);
        },
    },
    computed: {},
};
</script>
