var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "back-home bold pointer",
    on: {
      "click": _vm.clicked
    }
  }, [_c('MasterSvg', {
    attrs: {
      "use": "back-arrow"
    }
  }), _vm._v(" " + _vm._s(_vm.text) + " ")], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }