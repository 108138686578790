var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "filters"
  }, _vm._l(_vm.fullFiltersConf, function (filterconf) {
    return _c('v-col', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.displayFilter(filterconf),
        expression: "displayFilter(filterconf)"
      }],
      key: JSON.stringify(filterconf),
      attrs: {
        "cols": _vm.filterWidth(filterconf),
        "md": _vm.filterMdWidth(filterconf),
        "sm": _vm.filterSmWidth(filterconf)
      }
    }, [_c('FiltersItem', {
      attrs: {
        "filter": filterconf,
        "value": _vm.filters ? _vm.filters[filterconf.field] : null
      },
      on: {
        "change": function change($event) {
          return _vm.filterChanged(filterconf, $event);
        }
      }
    })], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }