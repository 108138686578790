import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                success: "#009B5A",
                primary: "#3A00E0",
                "primary-dark": "#0F057A",
                "primary-grey": "#7B77B2",
                "primary-extralight": "#F1F4FF",
                "primary-ultralight-grey": "#F4F8FB",
                "gs-black": "#000000",
                "gs-extrabold": "#424242",
                "gs-semilight": "#E0E0E0",
                "primary-lighter": "#5A20FF",
                "primary-lighter2": "#ede5fe",
                "primary-lighter3": "#f3f5ff",
                "primary-extralightold": "#f5f2fd",
                black: "#212121",
                secondary: "#F6F6FC",
                background: "#EAF2F9",
                "font-weight": 900,
            },
        },
        options: {
            customProperties: true,
        },
    },
});
