<template>
    <div>
        <template v-if="content">
            <div
                ref="contentContainer"
                :class="{ expanded: isExpanded || !compact }"
                class="content-container"
            >
                <div v-if="showToggle && compact" class="fade-overlay"></div>
                <div class="text-h6 font-weight-bold mb-4">
                    {{ content?.fields?.title }}
                </div>
                <ContentfulRichTextRenderer :document="content?.fields?.helpText" />
            </div>
            <v-btn
                v-if="showToggle && compact"
                @click="toggleExpand"
                class="mt-2"
                color="primary"
            >
                {{ $translate("seeMore") }}
            </v-btn>
        </template>
        <div key="error" v-else-if="error">{{ error }}</div>
        <div key="loading" v-else-if="loading">
            <Loading :absolute="false" :size="50" />
        </div>
        <div key="notfound" v-else v-translate><i>Content not found.</i></div>
    </div>
</template>

<script>
import ContentfulRichTextRenderer from "contentful-rich-text-vue-renderer";
import GlobalMixins from "@/mixins/global";
const contentful = require("contentful");

export default {
    mixins: [GlobalMixins],
    components: {
        ContentfulRichTextRenderer,
    },
    props: {
        contentId: {
            type: String,
            required: true,
        },
        contentType: {
            type: String,
            default: "help",
        },
        compact: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            content: null,
            loading: false,
            error: null,
            maxHeight: 250,
            showToggle: !this.compact,
            isExpanded: false,
        };
    },
    computed: {
        envContentId() {
            return this.$isProduction ? this.contentId : `dev-${this.contentId}`;
        },
    },
    methods: {
        toggleExpand() {
            this.openHelp(this.envContentId);
        },
        checkContentHeight() {
            const contentHeight = this.$refs.contentContainer?.scrollHeight;
            this.showToggle = contentHeight > this.maxHeight;
        },
        fetchContent(contentId) {
            let active_locale = this.$store.state.session["active_locale"];

            const client = contentful.createClient({
                space: "pi2rko0ko0vm",
                accessToken: "--8R5Fb_StNt59asxJ849F7yZ6U1rKUN--tJYigUqg8",
            });
            return new Promise((resolve, reject) => {
                client
                    .getEntries({
                        "fields.uniqueId": this.envContentId,
                        content_type: this.contentType,
                        locale: active_locale,
                    })
                    .then((entries) => {
                        if (!entries?.items?.length) {
                            reject(this.$gettext("Did not find content."));
                            return;
                        }
                        resolve(entries.items[0]);
                        this.$forceUpdate();
                        this.$nextTick(() => {
                            this.checkContentHeight();
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                        reject();
                    });
            });
        },
    },
    created() {
        this.loading = true;
        this.fetchContent(this.contentId)
            .then((content) => {
                this.content = content;
                this.$emit("fetched");
            })
            .catch((error) => {
                this.error = error;
            })
            .finally(() => {
                this.loading = false;
            });
    },
    mounted() {
        this.checkContentHeight();
        window.addEventListener("resize", this.checkContentHeight);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.checkContentHeight);
    },
    i18n: {
        messages: {
            en: {
                seeMore: "See more",
            },
            fr: {
                seeMore: "Voir plus",
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.content-container {
    max-height: 250px;
    overflow: hidden;
    transition: max-height 0.3s ease;
}
.content-container.expanded {
    max-height: none;
}
.fade-overlay {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    height: 120px;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
    );
    pointer-events: none;
}
</style>
