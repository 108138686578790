var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-0 py-sm-1"
  }, [_c('stroke', {
    staticClass: "mr-1 mb-1",
    attrs: {
      "strike": _vm.product.archived
    }
  }, [_c('span', {
    staticClass: "mr-1 mb-0 mt-sm-0"
  }, [_c('div', {
    staticClass: "align-center"
  }, [_vm.upc ? _c('div', {
    staticClass: "mb-1",
    attrs: {
      "template": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: _vm.type,
        params: {
          product_id: _vm.product.id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.product.upc) + " ")])], 1) : _vm._e(), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm.product.name ? [_vm._v(_vm._s(_vm.product.name))] : _c('i', {
    directives: [{
      name: "translate",
      rawName: "v-translate"
    }],
    staticClass: "warning--text"
  }, [_vm._v("No name")]), _vm.product.manufacturer && _vm.manufacturer ? [_c('i', {
    staticClass: "grey--text text--darken-2 text-justify"
  }, [_vm._v(" " + _vm._s(_vm.isMobile ? "" : "-") + " " + _vm._s(_vm.product.manufacturer))])] : _vm._e()], 2)]), _vm.product.description && _vm.description ? [_c('div', {
    staticClass: "mt-0 wrap text-justify"
  }, [_vm._v(" " + _vm._s(_vm.product.description) + " ")])] : _vm._e()], 2)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }