import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const default_filters = { offset: 0, limit: 100, ordering: 'label' };

const createState = () => ({
    producttypes: [],
    producttypes_filters: default_filters,
    producttypes_count: 0,
    producttypes_loaded: false,
    producttypes_loading: false,
    producttypes_loading_errors: null,
});

const mutations = {
    updateProductTypes(state, producttypes) {
        state.producttypes = producttypes;
        state.producttypes_loaded = true;
        state.producttypes_loading = false;
        state.producttypes_loading_errors = null;
    },
    updateProductTypesFilters(state, producttypes_filters) {
        state.producttypes_filters = producttypes_filters;
    },
    updateProductTypesLoading(state, producttypes_loading) {
        state.producttypes_loading = producttypes_loading;
    },
    updateProductTypesLoadingErrors(state, producttypes_loading_errors) {
        state.producttypes_loading_errors = producttypes_loading_errors;
        state.producttypes_loading = false;
    },
};

const actions = {

    fetchProductTypes({ commit, dispatch, state }, params) {
        commit('updateProductTypesLoading', true);
        commit('updateProductTypesLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get("/api/producttypes/", { params: state.producttypes_filters })
                .then((response) => {
                    commit('updateProductTypes', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProductTypesLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateProductTypesLoading', false);
                });

        });
    },

    init({ commit, dispatch, state }, params) {
        commit('updateProductTypes', []);
        commit('updateProductTypesFilters', params?.filters || { ...default_filters });
    },
};


export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});