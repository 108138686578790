import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    receiptitem: null,
    received_count: null,
    setting_status: false,
    setting_status_errors: {},
};

const mutations = {
    updateReceiptItem(state, receiptitem) {
        state.receiptitem = receiptitem;
        state.received_count = null;
    },
    updateSettingStatus(state, setting) {
        state.setting_status = setting;
    },
    updateSettingStatusErrors(state, errors) {
        state.setting_status_errors = errors;
    },
    updateReceivedCount(state, received_count) {
        state.received_count = received_count;
    }
};

const actions = {

    async setReceiptItemStatus({ commit, state }, params) {
        commit('updateSettingStatus', true);
        const data = {
            received_count: state.received_count,
        };

        try {
            const response = await axios.put(`/api/receiptitems/${state.receiptitem.id}/status/`, data);
            commit('updateSettingStatus', false);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateSettingStatusErrors', error.details);
            throw error;
        } finally {
            commit('updateSettingStatus', false);
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};