var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "flex-grow-1",
    attrs: {
      "elevation": _vm.elevation
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click', $event);
      }
    }
  }, [_c('v-card-title', {
    staticClass: "d-flex",
    "class": _vm.size == 'small' ? 'pb-0' : ''
  }, [_c('span', {
    staticClass: "flex-grow-1 ellipsis"
  }, [_vm._v(_vm._s(_vm.title))]), _vm.icon ? _c('v-icon', {
    attrs: {
      "size": _vm.size == 'small' ? 20 : 40,
      "color": "primary-dark ml-auto"
    }
  }, [_vm._v(_vm._s(_vm.iconName(_vm.icon)))]) : _vm._e()], 1), _c('v-card-text', {
    staticClass: "d-flex flex-column justify-center my-0"
  }, [_vm.loading ? _c('div', {}, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('i', {
    directives: [{
      name: "translate",
      rawName: "v-translate"
    }]
  }, [_vm._v("Loading...")]), _c('div', {
    staticClass: "text-h2"
  }, [_vm._v(" ")])]), _c('div', {
    staticClass: "text-subtitle-2 ellipsis",
    "class": "".concat(_vm.subtitleColor, "--text")
  }, [_vm._v("   ")])]) : [_c('div', {
    staticClass: "text-h2"
  }, [_vm._v(_vm._s(_vm.formatString(_vm.stat)) + " ")]), _c('div', {
    staticClass: "text-subtitle-2 ellipsis",
    "class": "".concat(_vm.subtitleColor, "--text")
  }, [_vm._v(" " + _vm._s(_vm.subtitle) + "  ")])]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }