var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "10",
      "height": "5",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:svg": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    staticClass: "layer"
  }, [_c('title', [_vm._v("Layer 1")]), _c('g', {
    attrs: {
      "id": "svg_1",
      "transform": "rotate(-90 4.911054611206056,2.575051546096802) "
    }
  }, [_c('path', {
    attrs: {
      "d": "m6.70172,6.79538c0.05944,0.05944 0.13374,0.08916 0.21547,0.08916s0.15603,-0.02972 0.21547,-0.08916c0.11888,-0.11888 0.11888,-0.31207 0,-0.43095l-3.78938,-3.78938l3.78938,-3.78938c0.11888,-0.11888 0.11888,-0.31207 0,-0.43095s-0.31207,-0.11888 -0.43095,0l-4.01229,4.00486c-0.11888,0.11888 -0.11888,0.31207 0,0.43095l4.01229,4.00486l0,0z",
      "id": "svg_2"
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }