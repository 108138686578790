import movements from './submodules/movements';

const state = {

};

const mutations = {

};

const actions = {

    async init({ commit, dispatch, state }, params) {
        dispatch('movements/init', {
            filters: {},
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        movements: movements(),
    }
};
