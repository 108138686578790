import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const createState = () => ({
    shipments: [],
    shipments_filters: { offset: 0, limit: 20, },
    shipments_count: 0,
    shipments_loaded: false,
    shipments_loading: false,
    shipments_loading_errors: null,
});

const mutations = {
    updateShipments(state, shipments) {
        state.shipments = shipments;
        state.shipments_loaded = true;
        state.shipments_loading = false;
        state.shipments_loading_errors = null;
    },
    updateShipmentsFilters(state, shipments_filters) {
        state.shipments_filters = shipments_filters;
    },
    updateShipmentsLoading(state, shipments_loading) {
        state.shipments_loading = shipments_loading;
    },
    updateShipmentsLoadingErrors(state, shipments_loading_errors) {
        state.shipments_loading_errors = shipments_loading_errors;
        state.shipments_loading = false;
    },
    updateShipmentsCount(state, shipments_count) {
        state.shipments_count = shipments_count;
    },
};

const actions = {

    fetchShipments({ commit, dispatch, state }, params) {
        if (store.getters['session/current_user_permissions'].indexOf("core.view_shipment") == -1) {
            return;
        }

        commit('updateShipmentsLoading', true);
        commit('updateShipmentsLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get("/api/shipments/", { params: state.shipments_filters })
                .then((response) => {
                    commit('updateShipments', response.data.results);
                    commit('updateShipmentsCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateShipmentsLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateShipmentsLoading', false);
                });

        });
    },

    init({ commit, dispatch, state }, params) {
        commit('updateShipments', []);
        const default_filters = { offset: 0, limit: 20 };
        commit('updateShipmentsFilters', { ...default_filters, ...(params || {}) });
    },
};


export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});