var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0)"
    }
  }, [_c('rect', {
    attrs: {
      "y": "-0.0679932",
      "width": "28",
      "height": "28",
      "rx": "4",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M10.8902 3.61972C10.9308 3.55667 11.0231 3.55652 11.065 3.61868C12.7585 6.12641 15.6541 7.69951 18.8252 7.6751L19.1622 7.6665L19.497 7.64592C19.9177 7.61248 20.3412 7.55605 20.7532 7.46023C20.8182 7.4451 20.8814 7.49341 20.8824 7.56021L20.8885 7.96522C20.9098 10.0751 20.8161 12.1909 20.5969 13.7246C20.428 14.914 20.1889 15.9428 19.8805 16.8032C18.6205 20.3022 16.1269 22.8151 12.2808 24.4439C12.1919 24.4816 12.1056 24.3858 12.1494 24.2997C14.3343 20.0099 14.0337 16.4477 11.2113 13.6859L11.0631 13.537C8.43638 10.8522 8.36421 7.58263 10.8809 3.63413L10.8902 3.61972Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.7",
      "d": "M8.8107 14.1667C8.8394 14.1254 8.89618 14.1151 8.93751 14.1439L9.15018 14.2916C12.0083 16.277 12.7158 20.2034 10.7304 23.0616L10.5827 23.2742C10.554 23.3156 10.4972 23.3258 10.4559 23.2971C7.4803 21.2301 6.74372 17.1423 8.8107 14.1667Z",
      "fill": "white"
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0"
    }
  }, [_c('rect', {
    attrs: {
      "width": "128.166",
      "height": "28",
      "fill": "white"
    }
  })]), _c('clipPath', {
    attrs: {
      "id": "clip1"
    }
  }, [_c('rect', {
    attrs: {
      "width": "91.7094",
      "height": "20",
      "fill": "white",
      "transform": "translate(36.457 4)"
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }