import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    user: null,
    recovertoken: null,
};

const mutations = {

    updateUser(state, user) {
        state.user = user;
    },

    updateRecoverToken(state, recovertoken) {
        state.recovertoken = recovertoken;
    }
};

const actions = {

    async fetchRecoverToken({ commit }, params) {
        commit('updateRecoverToken', null);
        try {
            const response = await axios.get(`/api/account/recovertokens/${params.token}/`);
            commit('updateRecoverToken', response.data);
            return response.data;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },

    async fetchUser({ commit }) {
        try {
            const response = await axios.get('/api/account/user/');
            commit('updateUser', response.data);
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },

    async confirmEmail({ commit }, params) {
        try {
            const response = await axios.post('/api/account/confirmemail/', { token: params.token });
            commit('updateUser', response.data);
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },

    async acceptInvitation({ commit }, params) {
        try {
            const response = await axios.post('/api/account/acceptinvitation/', { token: params.token });
            return response.data;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },



    async sendConfirmationEmail({ commit }, params) {
        try {
            const response = await axios.post('/api/account/sendconfirmationemail/', { token: params.token });
            commit('updateUser', response.data);
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },

    async deleteAccount({ commit }, params) {
        try {
            const response = await axios.post('/api/account/delete/', { password: params.password });
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },

    async register({ commit, dispatch, state }, params) {
        try {
            const response = await axios.post('/api/account/register/?connect=true', params);
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },

    async verifyPhoneNumber({ commit, dispatch, state }, params) {
        try {
            const response = await axios.post('/api/account/confirmidendity/', params);
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },

    async changePassword({ commit, dispatch, state }, params) {
        try {
            const response = await axios.put('/api/account/password/', params);
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};

