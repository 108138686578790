<template>
    <div>
        <v-dialog
            :value="idx == modals.length - 1"
            persistent
            :max-width="maxWidth"
            class="d-flex"
            v-for="(modal, idx) in modals"
            :key="modal.id"
        >
            <v-card class="pa-0 d-flex flex-column">
                <component
                    class="w-100 overflow-hidden"
                    :is="modal.component"
                    :params="modal.params"
                >
                </component>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    props: {
        maxWidth: {
            type: Number,
            default: 890,
        },
    },
    computed: Object.assign(
        mapState("mcl", {
            currentModal: "modal",
            modals: "modals",
        }),
        {}
    ),
    methods: Object.assign(mapActions("mcl", ["hideModal"]), {
        clickedOutside() {
            this.hideModal();
        },
    }),
};
</script>
