var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', {
    "class": _vm.buttonClass,
    style: {
      justifyContent: _vm.align
    },
    attrs: {
      "color": _vm.ghost ? '' : _vm.color,
      "small": _vm.small,
      "dense": _vm.dense,
      "loading": _vm.loading,
      "elevation": _vm.elevation,
      "disabled": _vm.disabled || _vm.loading,
      "size": _vm.size
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click', $event);
      }
    }
  }, [_vm.icon ? _c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": _vm.iconColor
    }
  }, [_vm._v(_vm._s(_vm.iconName(_vm.icon)))]) : _vm._e(), _vm.text ? _c('span', {
    staticClass: "text-wrap"
  }, [_vm._v(_vm._s(_vm.text))]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }