<template>
    <div>
        <v-autocomplete
            ref="autocomplete"
            :value="option"
            @input="selectOption"
            flat
            hide-details
            :outlined="outlined"
            :dense="dense"
            :items="options"
            :item-title="itemTitle"
            :item-value="itemValue"
            :clearable="clearable && !required"
            :loading="loading"
            :label="required ? label + ' (*)' : label"
            :required="required"
            :hint="hint"
            :disabled="disabled"
            :menu-props="{ closeOnClick: true }"
            :error-messages="errorMessages"
            :autofocus="autofocus"
        >
            <template v-slot:label="scope">
                <slot name="label" :props="scope" />
            </template>

            <template v-slot:item="scope">
                <slot name="item" :props="scope">
                    {{ scope.item[itemTitle || "text"] }}
                </slot>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
export default {
    name: "Select",
    props: {
        itemTitle: {
            type: String,
            required: false,
        },
        itemValue: {
            type: String,
            required: false,
        },
        label: {
            type: String,
            required: false,
        },
        defaultLabel: {
            type: String,
            required: false,
            default: "",
        },
        clearable: {
            type: Boolean,
            required: false,
            default: true,
        },
        hint: {
            type: String,
            required: false,
        },
        required: {
            type: Boolean,
            required: false,
        },
        options: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [String, Number, Boolean],
            required: false,
            default: null,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        errorMessages: {
            required: false,
        },
    },
    data() {
        return {
            option: this.value,
        };
    },
    methods: {
        selectOption(option) {
            this.$emit("input", option);
        },
    },
    watch: {
        options(options) {
            if (!this.option) {
                return;
            }
            const option = options.find((item) => item.value == this.option);
            if (!option && options.length) {
                this.option = null;
            }
        },
        value: {
            handler(value) {
                if (value !== this.option) {
                    this.option = value;
                }
            },
        },
    },
    mounted() {
        this.option = this.value;
    },
};
</script>
