import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const createState = () => ({
    customer: null,
    stock: null,

    stockEvolution: null,
    stockEvolutionPeriod: null,
    stockEvolutionLoading: false,
    stockEvolutionLoadingErrors: {},

    billingEvolution: null,
    billingEvolutionPeriod: null,
    billingEvolutionLoading: false,
    billingEvolutionLoadingErrors: {},

    activity: null,
    activityPeriod: null,
    activityLoading: false,
    activityLoadingErrors: {},

    statsLoading: false,
    statsLoadingErrors: {},

    lastMonth: null,
    previousMonth: null,
    lastMonthTrend: null,
    currentYear: null,
    lastYear: null,
    waitingValidation: null,
    waitingValidationInvoices: null,
});

const mutations = {

    updateCustomer(state, customer) {
        state.customer = customer;
    },
    updateStatsLoading(state, statsLoading) {
        state.statsLoading = statsLoading;
        state.statsLoadingErrors = {};
    },
    updateStatsLoadingErrors(state, statsLoadingErrors) {
        state.statsLoadingErrors = statsLoadingErrors;
        state.statsLoading = false;
    },
    updateStock(state, stock) {
        state.stock = stock;
    },

    updateStats(state, stats) {
        state.statsLoading = false;
        state.lastMonth = stats.last_month_total_excl_vat;
        state.previousMonth = stats.previous_month_total_excl_vat;
        state.lastMonthTrend = stats.last_month_trend;
        state.currentYear = stats.current_year_total_excl_vat;
        state.lastYear = stats.last_year_total_excl_vat;
        state.waitingValidation = stats.draft_invoices_total_excl_vat;
        state.waitingValidationInvoices = stats.draft_invoices_count;
    },

    updateStockEvolution(state, stockEvolution) {
        state.stockEvolution = stockEvolution;
        state.stockEvolutionLoading = false;
        state.stockEvolutionLoadingErrors = {};
    },
    updateStockEvolutionPeriod(state, stockEvolutionPeriod) {
        state.stockEvolution = null;
        state.stockEvolutionPeriod = stockEvolutionPeriod;
    },
    updateStockEvolutionLoading(state, stockEvolutionLoading) {
        state.stockEvolutionLoading = stockEvolutionLoading;
    },
    updateStockEvolutionLoadingErrors(state, stockEvolutionLoadingErrors) {
        state.stockEvolutionLoading = false;
        state.stockEvolutionLoadingErrors = stockEvolutionLoadingErrors;
    },

    updateBillingEvolution(state, billingEvolution) {
        state.billingEvolution = billingEvolution;
        state.billingEvolutionLoading = false;
        state.billingEvolutionLoadingErrors = {};
    },
    updateBillingEvolutionPeriod(state, billingEvolutionPeriod) {
        state.billingEvolution = null;
        state.billingEvolutionPeriod = billingEvolutionPeriod;
    },
    updateBillingEvolutionLoading(state, billingEvolutionLoading) {
        state.billingEvolutionLoading = billingEvolutionLoading;
    },
    updateBillingEvolutionLoadingErrors(state, billingEvolutionLoadingErrors) {
        state.billingEvolutionLoading = false;
        state.billingEvolutionLoadingErrors = billingEvolutionLoadingErrors;
    },


    updateActivity(state, activity) {
        state.activity = activity;
        state.activityLoading = false;
        state.activityLoadingErrors = {};
    },
    updateActivityPeriod(state, activityPeriod) {
        state.activity = null;
        state.activityPeriod = activityPeriod;
    },
    updateActivityLoading(state, activityLoading) {
        state.activityLoading = activityLoading;
    },
    updateActivityLoadingErrors(state, activityLoadingErrors) {
        state.activityLoading = false;
        state.activityLoadingErrors = activityLoadingErrors;
    },
};

const actions = {

    fetchStats({ commit, dispatch, state }, params) {
        commit('updateStatsLoading', true);
        return new Promise((resolve, reject) => {
            axios.get('/api/stats/billing/stats/', { params: { entity: state.customer.id } })
                .then((response) => {
                    commit('updateStats', response.data);
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateStatsLoadingErrors', error.details);
                    reject(error);
                })
        });
    },

    fetchActivity({ commit, dispatch, state }, params) {
        commit('updateActivityLoading', true);
        return new Promise((resolve, reject) => {
            axios.get('/api/stats/customer/activity/', { params: { customer: state.customer.id, start: state.activityPeriod.start, end: state.activityPeriod.end } })
                .then((response) => {
                    commit('updateActivity', response.data);
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateActivityLoadingErrors', error.details);
                    reject(error);
                })
        });
    },

    setActivityPeriod({ commit, dispatch, state }, params) {
        commit('updateActivityPeriod', params);
        dispatch('fetchActivity');
    },

    fetchStockEvolution({ commit, dispatch, state }, params) {
        commit('updateStockEvolutionLoading', true);
        return new Promise((resolve, reject) => {
            axios.get('/api/stats/stock/evolution/', { params: { customer: state.customer.id, start: state.stockEvolutionPeriod.start, end: state.stockEvolutionPeriod.end, stock: state.stock } })
                .then((response) => {
                    commit('updateStockEvolution', response.data);
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateStockEvolutionLoadingErrors', error.details);
                    reject(error);
                })
        });
    },
    setStockEvolutionPeriod({ commit, dispatch, state }, params) {
        commit('updateStockEvolutionPeriod', params);
        dispatch('fetchStockEvolution');
    },

    fetchBillingEvolution({ commit, dispatch, state }, params) {
        commit('updateBillingEvolutionLoading', true);
        return new Promise((resolve, reject) => {
            axios.get('/api/stats/billing/evolution/', { params: { entity: state.customer.id, start: state.billingEvolutionPeriod.start, end: state.billingEvolutionPeriod.end } })
                .then((response) => {
                    commit('updateBillingEvolution', response.data);
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateBillingEvolutionLoadingErrors', error.details);
                    reject(error);
                })
        });
    },
    setBillingEvolutionPeriod({ commit, dispatch, state }, params) {
        commit('updateBillingEvolutionPeriod', params);
        dispatch('fetchBillingEvolution');
    },
    changeStock({ commit, dispatch, state }, stock) {
        commit('updateStock', stock);
        dispatch('fetchStockEvolution');
    },


};

export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});