import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import stocks from './submodules/stocks';


const state = {
    stock: null,
    stockEvolution: null,
    stockEvolutionPeriod: null,
    stockEvolutionLoading: false,
    stockEvolutionLoadingErrors: {},
};

const mutations = {

    updateStockEvolution(state, stockEvolution) {
        state.stockEvolution = stockEvolution;
        state.stockEvolutionLoading = false;
        state.stockEvolutionLoadingErrors = {};
    },
    updateStockEvolutionPeriod(state, stockEvolutionPeriod) {
        state.stockEvolution = null;
        state.stockEvolutionPeriod = stockEvolutionPeriod;
    },
    updateStockEvolutionLoading(state, stockEvolutionLoading) {
        state.stockEvolutionLoading = stockEvolutionLoading;
    },
    updateStockEvolutionLoadingErrors(state, stockEvolutionLoadingErrors) {
        state.stockEvolutionLoading = false;
        state.stockEvolutionLoadingErrors = stockEvolutionLoadingErrors;
    },
    updateStock(state, stock) {
        state.stock = stock;
    },

};

const actions = {

    fetchStockEvolution({ commit, dispatch, state }, params) {
        commit('updateStockEvolutionLoading', true);
        return new Promise((resolve, reject) => {
            axios.get('/api/stats/stock/evolution/', { params: { start: state.stockEvolutionPeriod.start, end: state.stockEvolutionPeriod.end, scale: 'month', stock: state.stock } })
                .then((response) => {
                    commit('updateStockEvolution', response.data);
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateStockEvolutionLoadingErrors', error.details);
                    reject(error);
                })
        });
    },

    setStockEvolutionPeriod({ commit, dispatch, state }, params) {
        commit('updateStockEvolutionPeriod', params);
        dispatch('fetchStockEvolution');
    },

    changeStock({ commit, dispatch, state }, stock) {
        commit('updateStock', stock);
        dispatch('fetchStockEvolution');
    },

    init({ commit, dispatch, state }, params) {
        dispatch('stocks/init', { filters: {} });
        dispatch('stocks/fetchStocks');
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        stocks: stocks(),
    }
};
