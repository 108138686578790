<template>
    <span :class="{ link: !error }" @click.stop="openObject">
        <slot />
    </span>
</template>

<script>
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    props: {
        object: {
            type: String,
            required: true,
        },
        params: {
            type: Object,
            required: true,
        },
    },
    methods: {
        async openObject() {
            this.$utils.lookup(this.object, this.params).then((id) => {
                if (!id) {
                    this.addAlert({ text: this.$gettext("Invalid link"), type: "error" });
                    this.error = true;
                    return;
                }
                let params = {};
                params[`${this.object}_id`] = id;

                this.routerPush({
                    name: this.object,
                    params,
                });
            });
        },
    },
    data() {
        return {
            error: false,
        };
    },
};
</script>
