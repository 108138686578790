import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    target: null,
    sarch: null,
    catalog_id: null,

    targets: [],
    targets_loaded: false,
    targets_count: 0,
    targets_loading: false,
    targets_loading_errors: null,
    targets_cancel_token_source: null,


    selected_targets: [],
    initial_selected_targets: [],
    catalogassignments: [],

    catalogentries: null,
    catalogentries_count: 0,
    catalogentries_filters: { limit: 1000, trigger: 'manual' },
    catalogentries_loading: false,
    catalogentries_loading_errors: null,

    assigning_catalog: false,
    assigning_catalog_errors: null,

    catalogassignments_loaded: false,
    catalogassignments_loading: false,
    catalogassignments_loading_errors: null,
};

const mutations = {
    updateSearch(state, search) {
        state.search = search;
    },
    updateAssigningCatalog(state, assigning_catalog) {
        state.assigning_catalog = assigning_catalog;
    },

    updateCatalogAssignmentErrors(state, assigning_catalog_errors) {
        state.assigning_catalog_errors = assigning_catalog_errors;
    },

    updateTargets(state, targets) {
        state.targets = targets;
        state.targets_loaded = true;
        state.targets_loading = false;
    },
    updateTargetsCount(state, targets_count) {
        state.targets_count = targets_count;
    },
    updateTargetsLoading(state, loading) {
        state.targets_loading = loading;
    },
    updateTargetsLoadingErrors(state, errors) {
        state.targets_loading_errors = errors;
    },
    updateTargetsCancelTokenSource(state, cancelTokenSource) {
        state.targets_cancel_token_source = cancelTokenSource;
    },

    updateCatalogAssignments(state, catalogassignments) {
        state.catalogassignments = catalogassignments;
        if (!catalogassignments) {
            state.catalogassignments_loading = false;
            state.catalogassignments_loading_errors = null;
            state.selected_targets = null;
            state.initial_selected_targets = null;
            return;
        }
        state.catalogassignments_loaded = true;
        state.catalogassignments_loading = false;
        state.catalogassignments_loading_errors = null;
        state.selected_targets = catalogassignments.map((assignment) => assignment.customer_data || assignment.agent_data);
        state.initial_selected_targets = JSON.parse(JSON.stringify(state.selected_targets));
    },

    updateCatalogAssignmentsLoading(state, loading) {
        state.catalogassignments_loading = loading;
    },

    updateCatalogAssignmentsLoadingErrors(state, errors) {
        state.catalogassignments_loading = false;
        state.catalogassignments_loading_errors = errors;
    },


    updateCatalogEntries(state, catalogentries) {
        state.catalogentries = catalogentries;
    },
    updateCatalogEntriesCount(state, count) {
        state.catalogentries_count = count;
    },
    updateCatalogEntriesLoading(state, loading) {
        state.catalogentries_loading = loading;
    },
    updateCatalogEntriesLoadingErrors(state, errors) {
        state.catalogentries_loading_errors = errors;
    },
    updateCatalog(state, catalog) {
        state.catalog = catalog;
    },

    updateParams(state, params) {
        state.target = params.target;
        state.catalog_id = params.catalog_id;
        state.targets_loaded = false;
        state.targets = [];
    },

    selectAllTargets(state) {
        state.selected_targets = state.targets;
    },
    deselectAllTargets(state) {
        state.selected_targets = [];
    },
    toggleTarget(state, target) {
        if (state.selected_targets.find((item) => item.id == target.id)) {
            state.selected_targets = state.selected_targets.filter((item) => item.id != target.id);
        } else {
            state.selected_targets.push(target);
        }
    },

};

const actions = {

    async fetchTargets({ commit, state }) {
        commit('updateTargetsLoading', true);

        if (state.targets_cancel_token_source) {
            state.targets_cancel_token_source.cancel("Operation canceled due to new request.");
        }

        const cancelSource = axios.CancelToken.source();
        commit('updateTargetsCancelTokenSource', cancelSource);

        let getparams = { limit: 1000, search: state.search, archived: false, ordering: 'name' };
        let url = '/api/customers/';
        if (state.target === 'agent') {
            getparams.is_agent = true;
            url = '/api/agents/';
        }

        try {
            const response = await axios.get(url, {
                params: getparams,
                cancelToken: state.targets_cancel_token_source?.token,
            });
            commit('updateTargets', response.data.results);
            commit('updateTargetsCount', response.data.count);
            commit('updateTargetsCancelTokenSource', null);
            return response;
        } catch (xhr_error) {
            if (axios.isCancel(xhr_error)) {
                return;
            }
            const error = utils.handleError(xhr_error);
            commit('updateTargetsLoadingErrors', error.details);
            commit('updateTargetsCancelTokenSource', null);
            throw error;
        } finally {
            commit('updateTargetsLoading', false);
        }
    },

    fetchCatalogAssignments({ commit, dispatch, state }, params) {
        commit('updateCatalogAssignmentsLoading', true);
        commit('updateCatalogAssignmentsLoadingErrors', null);
        let url = "/api/catalogassignments/";
        if (state.target == 'agent') {

            url = "/api/catalogagentassignments/";
        }
        return new Promise((resolve, reject) => {

            axios.get(url, { params: { limit: 1000, catalog: state.catalog_id } })
                .then((response) => {
                    commit('updateCatalogAssignments', response.data.results);
                    resolve(response.data.payload);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateCatalogAssignmentsLoadingErrors', error.details);
                    reject(error);
                })
        });

    },

    assignCatalog({ commit, dispatch, state }, params) {
        commit('updateAssigningCatalog', true);

        return new Promise((resolve, reject) => {
            let url = `/api/catalogs/${state.catalog_id}/assign/`;
            if (state.target == 'agent') {
                url = `/api/catalogs/${state.catalog_id}/assignments/`;
            }
            let targets = state.selected_targets.map((item) => item.id);
            let data = {};

            if (state.target === 'agent') {
                data.entities = targets;
            } else {
                data.customers = targets;
            }

            axios.post(url, data)
                .then((response) => {
                    commit('updateAssigningCatalog', false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateAssigningCatalog', false);
                    commit('updateCatalogAssignmentErrors', error.details);
                    reject(error);
                });
        });
    },

    fetchCatalog({ commit, dispatch, state }, params) {
        return new Promise((resolve, reject) => {
            axios.get(`/api/catalogs/${state.catalog_id}/`)
                .then((response) => {
                    commit('updateCatalog', response.data);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                })
        })
    },

    setSearch({ commit, dispatch, state }, search) {
        commit('updateSearch', search);
        dispatch('fetchTargets');
    },

    init({ commit, dispatch, state }, params) {
        commit('updateParams', params);
        commit('updateSearch', null);
        commit('deselectAllTargets');
        commit('updateCatalogAssignments', null);
        dispatch('fetchCatalog');
        dispatch('fetchCatalogAssignments');
        dispatch('fetchTargets');
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
