<template>
    <v-row class="filters">
        <v-col
            :cols="filterWidth(filterconf)"
            :md="filterMdWidth(filterconf)"
            :sm="filterSmWidth(filterconf)"
            :key="JSON.stringify(filterconf)"
            v-show="displayFilter(filterconf)"
            v-for="filterconf in fullFiltersConf"
        >
            <FiltersItem
                :filter="filterconf"
                :value="filters ? filters[filterconf.field] : null"
                @change="filterChanged(filterconf, $event)"
            />
        </v-col>
    </v-row>
</template>

<script>
import axios from "@/plugins/axios";
import GlobalMixins from "@/mixins/global";
import FiltersItem from "./filters-item";

export default {
    components: {
        FiltersItem,
    },
    mixins: [GlobalMixins],
    props: {
        filtersConf: {
            type: Array,
            required: false,
        },
        filters: {
            type: Object,
            required: false,
        },
        enableOrdering: {
            type: Boolean,
            default: false,
        },
        excludeFromOrdering: {
            type: Array,
            required: false,
        },
        displayFilters: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed: {
        presets() {
            return {
                search: {
                    type: "search",
                    label: this.$gettext("Search"),
                    field: "search",
                    icon: "search",
                    force_display: true,
                },
                mindate: {
                    type: "datetime",
                    label: this.$gettext("Min date"),
                    field: "mindate",
                    icon: "date",
                },
                maxdate: {
                    type: "datetime",
                    label: this.$gettext("Max date"),
                    field: "maxdate",
                    icon: "date",
                },
                provider: {
                    type: "searchselect",
                    source: "provider",
                    field: "provider",
                    label: this.$gettext("Provider"),
                    endpoint: "/api/providers/",
                    icon: "provider",
                    params: { ordering: "name" },
                },

                vendor: {
                    type: "searchselect",
                    source: "vendor",
                    field: "customer",
                    label: this.$gettext("Provider"),
                    endpoint: "/api/catalogs/",
                    icon: "provider",
                    params: { ordering: "name", owned: false },
                },
                billed: {
                    type: "searchselect",
                    source: "entity",
                    field: "billed",
                    label: this.$gettext("Customer"),
                    endpoint: "/api/entities/",
                    icon: "customer",
                    params: { ordering: "name" },
                },
                catalog: {
                    type: "searchselect",
                    source: "catalog",
                    field: "catalog",
                    label: this.$gettext("Catalog"),
                    endpoint: "/api/catalogs/",
                    icon: "catalog",
                    params: {}, // ordering: "owner_name,name" },
                },
                catalogentry_category: {
                    type: "combo",
                    source: "catalogentry_category",
                    field: "category",
                    label: this.$gettext("Category"),
                    endpoint: "/api/catalogcategories/",
                    icon: "category",
                    params: { ordering: "owner_name,name" },
                },
                stock: {
                    type: "select",
                    source: "stock",
                    field: "stock",
                    label: this.$gettext("Warehouse"),
                    endpoint: "/api/stocks/",
                    icon: "stock",
                    params: { ordering: "owner_name,name" },
                },
                customer: {
                    type: "searchselect",
                    source: "customer",
                    field: "customer",
                    label: this.$gettext("Customer"),
                    endpoint: "/api/customers/",
                    icon: "customer",
                    params: { ordering: "name" },
                },
                recipient: {
                    type: "searchselect",
                    source: "customer",
                    field: "recipient",
                    label: this.$gettext("Customer"),
                    endpoint: "/api/customers/",
                    icon: "customer",
                    params: { ordering: "name" },
                },
                receipt_owner: {
                    type: "searchselect",
                    source: "customer",
                    field: "customer",
                    label: this.$gettext("Customer"),
                    endpoint: "/api/customers/",
                    icon: "customer",
                    params: { ordering: "name" },
                },
                initiator: {
                    type: "searchselect",
                    source: "customer",
                    field: "initiator",
                    label: this.$gettext("Customer"),
                    endpoint: "/api/customers/",
                    icon: "customer",
                    params: { ordering: "name" },
                },
                deliverysite: {
                    type: "searchselect",
                    source: "deliverysite",
                    field: "deliverysite",
                    label: this.$gettext("Delivery site"),
                    endpoint: "/api/deliverysites/",
                    icon: "site",
                    params: { field: "name" },
                    order: true,
                },
                manufacturer: {
                    type: "select",
                    source: "productmetadata",
                    field: "manufacturer",
                    label: this.$gettext("Manufacturer"),
                    endpoint: "/api/productsmetadata/",
                    icon: "manufacturer",
                    params: { field: "manufacturer" },
                    order: true,
                },
                movement_type: {
                    type: "select",
                    field: "movement_type",
                    icon: "movement",
                    label: this.$gettext("Movement type"),
                    sort: true,
                    choices: [
                        {
                            text: this.$gettext("Shipment"),
                            value: "shipment",
                        },
                        {
                            text: this.$gettext("Shipment canceled"),
                            value: "shipmentcanceled",
                        },
                        {
                            text: this.$gettext("Receipt"),
                            value: "receipt",
                        },
                        {
                            text: this.$gettext("Adjustment"),
                            value: "adjustment",
                        },
                    ],
                    order: true,
                },
                connected: {
                    type: "select",
                    field: "connected",
                    icon: "link",
                    label: this.$gettext("Connected"),
                    sort: false,
                    force_value: true,
                    choices: [
                        {
                            text: this.$gettext("Yes"),
                            value: true,
                        },
                        {
                            text: this.$gettext("No"),
                            value: false,
                        },
                    ],
                },
                producttype: {
                    type: "select",
                    source: "productmetadata",
                    field: "producttype",
                    label: this.$gettext("Product type"),
                    endpoint: "/api/productsmetadata/",
                    params: { field: "producttype" },
                    icon: "producttype",
                    order: false,
                },
                productmetadatafilters: {
                    type: "dynamicfilters",
                    field: "producttype",
                    filters: this.getProductMetadataFilters,
                },
                catalogentry_recurring: {
                    type: "select",
                    icon: "status",
                    label: this.$gettext("Recurrence"),
                    field: "recurring",
                    sort: true,
                    choices: [
                        { text: this.$gettext("Recurring"), value: "true" },
                        { text: this.$gettext("Oneshot"), value: "false" },
                    ],
                    order: true,
                },
                catalogentry_type: {
                    type: "combo",
                    icon: "status",
                    label: this.$gettext("Type"),
                    field: "type",
                    sort: true,
                    choices: [
                        { text: this.$gettext("Product"), value: "product" },
                        { text: this.$gettext("Service"), value: "service" },
                    ],
                    order: true,
                },
                catalogentry_trigger: {
                    type: "select",
                    icon: "status",
                    label: this.$gettext("Trigger"),
                    field: "trigger",
                    sort: true,
                    choices: [
                        { text: this.$gettext("Manual"), value: "manual" },
                        {
                            text: this.$gettext("Shipment prepared"),
                            value: "shipment-prepared",
                        },
                        {
                            text: this.$gettext("Shipment delivered"),
                            value: "shipment-delivered",
                        },
                        {
                            text: this.$gettext("Shipment picked up"),
                            value: "shipment-pickedup",
                        },
                        {
                            text: this.$gettext("Shipment option"),
                            value: "shipment-option",
                        },
                        {
                            text: this.$gettext("Receipt handled"),
                            value: "receipt-handled",
                        },
                        {
                            text: this.$gettext("Receipt option"),
                            value: "receipt-option",
                        },
                        { text: this.$gettext("Stock"), value: "stock" },
                    ],
                    order: true,
                },
                city: {
                    field: "city",
                    label: this.$gettext("City"),
                    order: true,
                },
                postal_code: {
                    field: "postal_code",
                    label: this.$gettext("Postal code"),
                    order: true,
                },
                country: {
                    field: "country",
                    label: this.$gettext("Country"),
                    order: true,
                },
                order_status: {
                    field: "status",
                    type: "select",
                    icon: "status",
                    label: this.$gettext("Status"),
                    choices: [
                        { text: this.$gettext("Waiting"), value: "waiting" },
                        {
                            text: this.$gettext("Processing"),
                            value: "processing",
                        },
                        {
                            text: this.$gettext("Completed"),
                            value: "completed",
                        },
                        { text: this.$gettext("Canceled"), value: "canceled" },
                    ],
                    order: true,
                },
                allocation_status: {
                    field: "status",
                    type: "select",
                    icon: "status",
                    label: this.$gettext("Status"),
                    choices: [
                        { text: this.$gettext("Pending"), value: "pending" },
                        {
                            text: this.$gettext("Opened"),
                            value: "opened",
                        },
                        {
                            text: this.$gettext("Exhausted"),
                            value: "exhausted",
                        },
                        {
                            text: this.$gettext("Closed"),
                            value: "closed",
                        },
                    ],
                    order: true,
                },
                shipment_status: {
                    field: "status",
                    type: "select",
                    icon: "status",
                    label: this.$gettext("Status"),
                    choices: [
                        { text: this.$gettext("Waiting"), value: "waiting" },
                        {
                            text: this.$gettext("Preparing"),
                            value: "preparing",
                        },
                        { text: this.$gettext("Prepared"), value: "prepared" },
                        { text: this.$gettext("Shipping"), value: "shipping" },
                        {
                            text: this.$gettext("Picked up"),
                            value: "picked_up",
                        },
                        {
                            text: this.$gettext("Delivered"),
                            value: "delivered",
                        },
                        { text: this.$gettext("Canceled"), value: "canceled" },
                    ],
                    order: true,
                },
                receipt_placed: {
                    field: "placed",
                    type: "select",
                    icon: "placed",
                    label: this.$gettext("Placed"),
                    choices: [
                        {
                            text: this.$gettext("Any"),
                            value: null,
                        },
                        {
                            text: this.$gettext("Yes"),
                            value: "true",
                        },
                        {
                            text: this.$gettext("No"),
                            value: "false",
                        },
                    ],
                    order: false,
                },
                receipt_status: {
                    field: "status",
                    type: "select",
                    icon: "status",
                    label: this.$gettext("Status"),
                    choices: [
                        {
                            text: this.$gettext("Waiting"),
                            value: "waiting",
                        },
                        {
                            text: this.$gettext("Handling"),
                            value: "handling",
                        },
                        {
                            text: this.$gettext("Handled"),
                            value: "handled",
                        },
                        { text: this.$gettext("Complete"), value: "complete" },
                        {
                            text: this.$gettext("Partially received"),
                            value: "partial",
                        },
                        {
                            text: this.$gettext("Missing"),
                            value: "missing",
                        },
                    ],
                    order: true,
                },
                invoice_type: {
                    type: "select",
                    field: "invoice_type",
                    icon: "invoice",
                    label: this.$gettext("Invoice type"),
                    choices: [
                        {
                            text: this.$gettext("Oneshot"),
                            value: "oneshot",
                        },
                        { text: this.$gettext("Period"), value: "period" },
                    ],
                    order: true,
                },

                invoice_status: {
                    type: "select",
                    field: "status",
                    icon: "status",
                    label: this.$gettext("Status"),
                    choices: [
                        {
                            text: this.$gettext("Validated"),
                            value: "validated",
                        },
                        { text: this.$gettext("Draft"), value: "draft" },
                    ],
                    order: true,
                },
                invoice_paid: {
                    type: "select",
                    field: "paid",
                    icon: "euro",
                    label: this.$gettext("Payment status"),
                    choices: [
                        {
                            text: this.$translate("paid"),
                            value: "true",
                        },
                        {
                            text: this.$translate("waiting_payment"),
                            value: "false",
                        },
                    ],
                    order: true,
                },
                invoice_credit: {
                    type: "select",
                    field: "credit",
                    icon: "euro",
                    label: this.$gettext("Credit / Debit"),
                    choices: [
                        {
                            text: this.$gettext("Debit"),
                            value: "false",
                        },
                        {
                            text: this.$gettext("Credit"),
                            value: "true",
                        },
                    ],
                    order: true,
                },
                billingaccount: {
                    type: "select",
                    field: "billingaccount",
                    icon: "invoice",
                    label: this.$gettext("Billing account"),
                    order: true,
                    source: "billingaccount",
                    endpoint: "/api/billingaccounts/",
                    params: { ordering: "name" },
                },
                exclude_readonly_shipments: {
                    field: "exclude_readonly",
                    type: "boolean",
                    label: this.$gettext("Exclude completed"),
                },
                delegated: {
                    field: "delegated",
                    type: "boolean",
                    label: this.$gettext("Delegated"),
                },
                paid: {
                    field: "paid",
                    type: "boolean",
                    label: this.$gettext("Invoice paid"),
                },
                payment_status: {
                    field: "paid",
                    type: "select",
                    icon: "euro",
                    label: this.$gettext("Payment status"),
                    choices: [
                        {
                            text: this.$translate("paid"),
                            value: "true",
                        },
                        {
                            text: this.$translate("waiting_payment"),
                            value: "false",
                        },
                    ],
                    order: true,
                },
                completed_date_min: {
                    field: "completed_date_min",
                    type: "datetime",
                    label: this.$gettext("Completion date min"),
                    icon: "date",
                },
                completed_date_max: {
                    field: "completed_date_max",
                    type: "datetime",
                    label: this.$gettext("Completion date max"),
                    icon: "date",
                },
                paid_date_min: {
                    field: "paid_date_min",
                    type: "datetime",
                    label: this.$gettext("Paid date min"),
                    icon: "date",
                },
                paid_date_max: {
                    field: "paid_date_max",
                    type: "datetime",
                    label: this.$gettext("Paid date max"),
                    icon: "date",
                },
                exclude_readonly_orders: {
                    field: "exclude_readonly",
                    type: "boolean",
                    label: this.$gettext("Exclude completed"),
                },
                include_archived: {
                    field: "include_archived",
                    type: "boolean",
                    label: this.$gettext("Include archived"),
                },
                include_archived_products: {
                    field: "include_archived_products",
                    type: "boolean",
                    label: this.$gettext("Include archived products"),
                },
                exclude_entries_with_no_stock: {
                    field: "with_stock",
                    type: "boolean",
                    label: this.$gettext("Exclude empty stock"),
                },
                exclude_entries_with_no_provider_stock: {
                    field: "with_provider_stock",
                    type: "boolean",
                    label: this.$gettext("Exclude empty provider stock"),
                },
                exclude_products_with_no_stock: {
                    field: "with_stock",
                    type: "boolean",
                    label: this.$gettext("Exclude empty stock"),
                },
                exclude_readonly_receipts: {
                    field: "exclude_readonly",
                    type: "boolean",
                    label: this.$gettext("Exclude completed"),
                },
                delivery_date_around: {
                    field: "delivery_date_around",
                    type: "datetime",
                    label: this.$gettext("Delivery date (+/- 1 day)"),
                    icon: "date",
                },
                assigned_to: {
                    field: "assigned_to",
                    type: "select",
                    source: "user",
                    label: this.$gettext("Assigned to"),
                    icon: "take",
                    endpoint: "/api/users/",
                    params: { ordering: "full_name", myentity: true },
                },
                receipt_date_around: {
                    field: "receipt_date_around",
                    type: "datetime",
                    label: this.$gettext("Receipt date (+/- 1 day)"),
                    icon: "date",
                },
            };
        },
    },
    data() {
        return {
            timeout_counter: 0,
            fullFiltersConf: [],
        };
    },
    async mounted() {
        this.fullFiltersConf = await this.getFullFiltersConf(this.filters);
    },
    methods: {
        displayFilter(filterconf) {
            return filterconf.force_display || this.displayFilters;
        },
        getOrderingFilter(fullfiltersconf) {
            fullfiltersconf = fullfiltersconf.filter(
                (filter) =>
                    filter.order &&
                    (this.excludeFromOrdering || []).indexOf(filter.field) == -1
            );
            let orderingchoices = fullfiltersconf
                .map((filter) => {
                    return {
                        text: filter.label,
                        value: filter.field,
                    };
                })
                .sort((item1, item2) =>
                    item1.text < item2.text ? -1 : item2.text < item2.text ? 1 : 0
                );
            if (!orderingchoices.length) {
                return null;
            }
            return {
                field: "ordering",
                type: "select",
                icon: "placed",
                label: this.$gettext("Order by"),
                choices: orderingchoices,
            };
        },
        async getFullFiltersConf(filters) {
            if (!this.filtersConf) {
                return [];
            }
            let fullfiltersconf = [];

            for (let filterConf of this.filtersConf) {
                let filterconf = this.filterConf(filterConf);
                if (filterconf.type == "dynamicfilters") {
                    let dynamicfilters = await filterconf.filters(filterConf, filters);
                    dynamicfilters.forEach((dynamicfilterConf) => {
                        fullfiltersconf.push(dynamicfilterConf);
                    });
                } else {
                    fullfiltersconf.push(filterconf);
                }
            }

            // if (this.enableOrdering) {
            //     let ordering = this.getOrderingFilter(fullfiltersconf);
            //     if (ordering) {
            //         fullfiltersconf.push(ordering);
            //     }
            // }
            return fullfiltersconf;
        },
        async getProductMetadataFilters(filterConf, filters) {
            if (!filters) {
                filters = this.filters;
            }

            let field = filterConf.field || "producttype";

            if (!filters[field]) {
                return [];
            }
            let url = `/api/producttypes/${filters[field]}/`;
            const response = await axios.get(url);
            let producttype = response.data;
            if (!producttype) {
                return [];
            }
            return (producttype.fields || [])
                .filter((field) => field.filter)
                .map((field) => {
                    if (field.type == "choices") {
                        return {
                            type: "select",
                            label: field.label,
                            unit: field.unit,
                            field: "metadata__" + field.code,
                            choices: field.choices.map((item) => {
                                return {
                                    text: item,
                                    value: item,
                                };
                            }),
                            order: true,
                        };
                    }
                    return {
                        type: field.type,
                        label: field.label,
                        unit: field.unit,
                        source: "productmetadata",
                        endpoint: filterConf.endpoint || "/api/productsmetadata/",
                        params: { field: field.code, producttype: producttype.id },
                        field: "metadata__" + field.code,
                        order: true,
                    };
                });
        },
        filterWidth(filterconf) {
            if (filterconf.type == "search") {
                return 12;
            }
            return 12;
        },
        filterSmWidth(filterconf) {
            if (filterconf.type == "search") {
                return 12;
            }
            return filterconf.cols || 6;
        },
        filterMdWidth(filterconf) {
            if (filterconf.type == "search") {
                return 6;
            }
            return filterconf.cols || 3;
        },
        filterXsWidth(filterconf) {
            if (filterconf.type == "search") {
                return 12;
            }
            return filterconf.cols || 12;
        },
        filterConf(filter) {
            let filterconf = {};
            if (typeof filter === "object") {
                let preset = {};
                if (filter.preset) {
                    preset = this.presets[filter.preset];
                }
                filterconf = Object.assign(preset, filter);
            }

            if (typeof filter == "string") {
                if (this.presets[filter]) {
                    filterconf = Object.assign({}, this.presets[filter]);
                } else {
                    filterconf = {
                        field: filter,
                        type: "string",
                    };
                }
            } else if (typeof filter === "object") {
                if (filter.preset) {
                    filterconf = Object.assign({}, this.presets[filter.preset], filter);
                }
            }
            filterconf.field = filterconf.field || filterconf.source || filterconf.preset;
            return filterconf;
        },
        async filterChanged(filter, value) {
            let timeout_counter = ++this.timeout_counter;
            return new Promise((resolve) => {
                setTimeout(resolve, 300);
            }).then(async () => {
                if (this.timeout_counter != timeout_counter) {
                    return;
                }

                let filters = Object.assign({}, this.filters);

                if (value === null || (!value && !filter.force_value)) {
                    if (filter.field in filters) {
                        delete filters[filter.field];
                    }
                } else {
                    if (filter.type == "boolean" && filter.value) {
                        value = filter.value || value;
                    }
                    filters[filter.field] = value;
                }
                if (filters.offset) {
                    delete filters.offset;
                }

                let fullFiltersConf = await this.getFullFiltersConf(filters);
                this.fullFiltersConf = fullFiltersConf;

                for (filter in filters) {
                    if (filter == "offset" || filter == "limit") {
                        continue;
                    }

                    if (!this.fullFiltersConf.find((item) => item.field == filter)) {
                        //delete filters[filter];
                    }
                }
                this.$emit("on-filters-change", filters);
            });
        },
    },
    i18n: {
        messages: {
            en: {
                paid: "Paid",
                waiting_payment: "Waiting payment",
            },
            fr: {
                paid: "Payé",
                waiting_payment: "En attente de paiement",
            },
        },
    },
};
</script>

<style lang="scss">
.filters .filter label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
</style>
