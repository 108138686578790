import axios from '@/plugins/axios';
import store from '@/stores/store';
import qs from 'qs';
import utils from '@/stores/utils';

const createState = () => ({
    allocations: [],
    allocations_filters: { offset: 0, limit: 20, },
    allocations_count: 0,
    allocations_loaded: false,
    allocations_loading: false,
    allocations_loading_errors: null,

    allocations_stats: {},
    allocations_stats_loaded: false,
    allocations_stats_loading: false,
    allocations_stats_loading_errors: null,

    allocation_deleting: false,
    allocation_deleting_errors: null,
});


const mutations = {
    updateAllocations(state, allocations) {
        state.allocations = allocations;
        state.allocations_loaded = true;
    },
    updateAllocationsCount(state, count) {
        state.allocations_count = count;
    },
    updateAllocationsFilters(state, filters) {
        state.allocations_filters = filters;
    },
    updateAllocationsLoading(state, loading) {
        state.allocations_loading = loading;
    },
    updateAllocationsLoadingErrors(state, errors) {
        state.allocations_loading_errors = errors;
    },

    updateAllocationsStats(state, allocations_stats) {
        state.allocations_stats = allocations_stats;
        state.allocations_stats_loaded = true;
    },
    updateAllocationsStatsLoading(state, loading) {
        state.allocations_stats_loading = loading;
    },
    updateAllocationsStatsLoadingErrors(state, errors) {
        state.allocations_stats_loading_errors = errors;
    },

    updateAllocationDeleting(state, deleting) {
        state.allocation_deleting = deleting;
    },
    updateAllocationDeletingErrors(state, errors) {
        state.allocation_deleting_errors = errors;
    },
    updateAllocationsLoaded(state, loaded) {
        state.allocations_loaded = loaded;
    },
};

const actions = {

    exportAllocationsAsCSV({ commit, dispatch, state }, params) {
        let filters = {};
        if (params.filtered) {
            filters = Object.assign({}, state.allocations_filters);
            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 10000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/allocations/?" + queryparams);
    },

    async deleteAllocation({ commit, dispatch, state }, params) {
        if (store.getters['session/current_user_permissions'].indexOf("order.delete_allocation") == -1) {
            return;
        }

        const url = `/api/allocations/${params.instance.id}/`;

        commit('updateAllocationDeleting', true);
        commit('updateAllocationDeletingErrors', null);

        try {
            const response = await axios.delete(url);
            commit('updateAllocationDeleting', false);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateAllocationDeleting', false);
            commit('updateAllocationDeletingErrors', error.details);
            throw error;
        }
    },

    async fetchAllocations({ commit, dispatch, state }, params) {
        if (store.getters['session/current_user_permissions'].indexOf("order.view_allocation") == -1) {
            return;
        }

        commit('updateAllocationsLoading', true);
        commit('updateAllocationsLoadingErrors', null);

        try {
            const response = await axios.get('/api/allocations/', { params: state.allocations_filters });
            commit('updateAllocationsLoading', false);
            commit('updateAllocations', response.data.results);
            commit('updateAllocationsCount', response.data.count);
            dispatch('fetchAllocationsStats');
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateAllocationsLoadingErrors', error.details);
            commit('updateAllocationsLoading', false);
            throw error;
        }
    },

    async fetchAllocationsStats({ commit, dispatch, state }, params) {
        if (store.getters['session/current_user_permissions'].indexOf("order.view_allocation") == -1) {
            return;
        }

        commit('updateAllocationsStatsLoading', true);
        commit('updateAllocationsStatsLoadingErrors', null);

        try {
            const response = await axios.get('/api/allocations/stats/', { params: state.allocations_filters });
            commit('updateAllocationsStatsLoading', false);
            commit('updateAllocationsStats', response.data);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateAllocationsStatsLoadingErrors', error.details);
            commit('updateAllocationsStatsLoading', false);
            throw error;
        }
    },

    init({ commit, dispatch, state }, params) {
        commit('updateAllocationsLoaded', false);
        commit('updateAllocations', []);
        commit('updateAllocationsCount', 0);
        commit('updateAllocationsFilters', { ...{ offset: 0, limit: 20 }, ...params?.filters || {} });
    }
};

export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});