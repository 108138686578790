import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    search: '',
    catalogpublishings: [],
    catalogpublishings_filters: { offset: 0, limit: 20, },
    catalogpublishings_loaded: false,
    catalogpublishings_loading: false,
    catalogpublishings_loading_errors: null,
};

const mutations = {
    updateCatalogPublishings(state, catalogpublishings) {
        state.catalogpublishings = catalogpublishings;
        state.catalogpublishings_loaded = true;
    },
    updateCatalogPublishingsFilters(state, catalogpublishings_filters) {
        state.catalogpublishings_filters = catalogpublishings_filters;
    },
    updateCatalogPublishingsLoaded(state, loaded) {
        state.catalogpublishings_loaded = loaded;
    },
    updateCatalogPublishingsLoading(state, loading) {
        state.catalogpublishings_loading = loading;
    },
    updateCatalogPublishingsLoadingErrors(state, errors) {
        state.catalogpublishings_loading_errors = errors;
    },
    updateSearch(state, search) {
        state.search = search;
    },

};

const actions = {
    fetchCatalogPublishings({ commit, state }) {
        commit('updateCatalogPublishingsLoading', true);
        commit('updateCatalogPublishingsLoadingErrors', null);

        const params = { ...state.catalogpublishings_filters };
        if (state.search) {
            params.search = state.search;
        }

        return new Promise((resolve, reject) => {
            axios.get('/api/catalogpublishings/', { params })
                .then((response) => {
                    commit('updateCatalogPublishingsLoading', false);
                    commit('updateCatalogPublishings', response.data.results || []);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateCatalogPublishingsLoadingErrors', error.details);
                    commit('updateCatalogPublishingsLoading', false);
                    reject(error);
                })
        });
    },

    setSearch({ commit, dispatch, state }, search) {
        commit('updateSearch', search);
        dispatch('fetchCatalogPublishings'); // Refetch the catalogpublishings whenever the search term changes
    },

    init({ commit, dispatch, state }, search) {
        commit('updateCatalogPublishingsLoaded', false);
        dispatch('setSearch', '');
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
