var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "10",
      "viewBox": "0 0 16 10"
    }
  }, [_c('path', {
    attrs: {
      "fill": "#579AFF",
      "fill-rule": "evenodd",
      "d": "M15.219 4.194H2.673l2.741-2.816c.306-.314.307-.825.003-1.14-.305-.316-.8-.317-1.105-.003L.23 4.428c-.306.315-.307.828-.001 1.143l4.083 4.194c.306.314.8.313 1.105-.003.304-.315.303-.826-.003-1.14l-2.74-2.816h12.545c.431 0 .781-.36.781-.806 0-.445-.35-.806-.781-.806z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }