import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    stocks: [],
    stocks_filters: { offset: 0, limit: 20, ordering: 'owner_name,name' },
    stocks_count: 0,
    stocks_loaded: false,
    stocks_loading: false,
    stocks_loading_errors: null,
    stock_deleting: false,
};

const mutations = {
    updateStocks(state, stocks) {
        state.stocks = stocks;
        state.stocks_loaded = true;
    },
    updateStocksFilters(state, stocks_filters) {
        state.stocks_filters = stocks_filters;
    },
    updateStocksCount(state, stocks_count) {
        state.stocks_count = stocks_count;
    },
    updateStocksLoading(state, loading) {
        state.stocks_loading = loading;
        if (loading) {
            state.stocks_loaded = false;
        }
    },
    updateStocksLoadingErrors(state, errors) {
        state.stocks_loading_errors = errors;
    },
    updateStockDeleting(state, deleting) {
        state.stock_deleting = deleting;
    },
};

const actions = {

    setStockAsMainStock({ commit, dispatch, state }, params) {
        const url = `/api/stocks/${params.instance.id}/setasmain/`;
        return new Promise((resolve, reject) => {
            axios.post(url)
                .then((response) => {
                    dispatch('fetchStocks');
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                })
        })
    },

    deleteStock({ commit, dispatch, state }, params) {
        commit('updateStockDeleting', true);

        const url = `/api/stocks/${params.instance.id}/`;
        return new Promise((resolve, reject) => {
            axios.delete(url)
                .then((response) => {
                    commit('updateStockDeleting', false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateStockDeleting', false);
                    reject(error);
                })
                .finally(() => {
                    dispatch('session/fetchStats', null, { root: true });
                    dispatch('fetchStocks');
                })
        })
    },

    fetchStocks({ commit, dispatch, state }, params) {
        commit('updateStocksLoading', true);
        commit('updateStocksLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/stocks/', {
                params: state.stocks_filters,
            })
                .then((response) => {
                    commit('updateStocksLoading', false);
                    commit('updateStocks', response.data.results);
                    commit('updateStocksCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateStocksLoadingErrors', error.details);
                    commit('updateStocksLoading', false);
                    reject(error);
                })
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
