<template>
    <div>
        <div>
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        <b>{{ price(excludingVat) }}</b>
                    </div>
                </template>
                <span>{{ $translate("excludingVat") }}</span>
            </v-tooltip>
        </div>
        <div class="grey--text mt-1" v-if="excludingVat">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        {{ price(includingVat) }}
                    </div>
                </template>
                <span>{{ $translate("includingVat") }}</span>
                <template v-if="vatRate">
                    <span> ({{ $translate("vat_rate") }} {{ vatRate * 100 }}%)</span>
                </template>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    props: {
        excludingVat: {
            required: false,
            type: [Number, String],
        },
        includingVat: {
            required: false,
            type: [Number, String],
        },
        vatRate: {
            required: false,
            type: [Number, String],
        },
    },
    i18n: {
        messages: {
            en: {
                excludingVat: "Total excluding VAT",
                includingVat: "Total including VAT",
                vat_rate: "VAT",
            },
            fr: {
                excludingVat: "Total HT",
                includingVat: "Total TTC",
                vat_rate: "TVA",
            },
        },
    },
};
</script>
