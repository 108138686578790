import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const createState = () => ({
    deliverysites: [],
    deliverysites_url: '/api/deliverysites/',
    deliverysites_filters: { offset: 0, limit: 20, ordering: "name" },
    deliverysites_count: 0,
    deliverysites_loaded: false,
    deliverysites_loading: false,
    deliverysites_loading_errors: null,
});

const mutations = {
    updateDeliverySites(state, deliverysites) {
        state.deliverysites = deliverysites;
        state.deliverysites_loaded = true;
        state.deliverysites_loading = false;
        state.deliverysites_loading_errors = null;
    },
    updateDeliverySitesCount(state, deliverysites_count) {
        state.deliverysites_count = deliverysites_count;
    },

    updateDeliverySitesUrl(state, deliverysites_url) {
        state.deliverysites_url = deliverysites_url;
    },
    updateDeliverySitesLoading(state, deliverysites_loading) {
        state.deliverysites_loading = deliverysites_loading;
    },
    updateDeliverySitesLoadingErrors(state, deliverysites_loading_errors) {
        state.deliverysites_loading_errors = deliverysites_loading_errors;
        state.deliverysites_loading = false;
    },
    updateDeliverySitesFilters(state, filters) {
        state.deliverysites_filters = filters;
    },
};

const actions = {

    deleteDeliverySite({ commit, dispatch, state }, params) {
        return new Promise((resolve, reject) => {
            axios.delete(`${state.deliverysites_url}${params.instance.id}/`)
                .then(resolve)
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                }).finally(() => {
                    dispatch('fetchDeliverySites');
                })
        });

    },

    fetchDeliverySites({ commit, dispatch, state }, params) {

        if (
            store.getters["session/current_user_permissions"].indexOf(
                "core.view_deliverysite"
            ) == -1
        ) {
            return;
        }

        commit('updateDeliverySitesLoading', true);
        commit('updateDeliverySitesLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get(state.deliverysites_url, { params: state.deliverysites_filters })
                .then((response) => {
                    commit('updateDeliverySites', response.data.results);
                    commit('updateDeliverySitesCount', response.data.count);
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateDeliverySitesLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateDeliverySitesLoading', false);
                });

        });
    },

    init({ commit, dispatch, state }, params) {
        commit('updateDeliverySitesUrl', params?.url || '/api/deliverysites/');
        commit('updateDeliverySitesFilters', params?.filters || { offset: 0, limit: 20, ordering: "name" });
        commit('updateDeliverySites', []);
    }
};


export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});