import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const createState = () => ({
    catalog: {},
    catalogassignments: [],
    catalogassignments_count: 0,
    catalogassignments_filters: { offset: 0, limit: 20, ordering: 'customer_name' },
    catalogassignments_loaded: false,
    catalogassignments_loading: false,
    catalogassignments_loading_errors: null,
});


const mutations = {

    updateCatalog(state, catalog) {
        state.catalog = catalog;
        state.catalogassignments = [];
        state.catalogassignments_count = 0;
        state.catalogassignments_loaded = false;
        state.catalogassignments_loading = false;
        state.catalogassignments_filters = { offset: 0, limit: 20, catalog: catalog ? catalog.id : null, ordering: 'customer_name' };
    },
    updateCatalogAssignments(state, catalogassignments) {
        state.catalogassignments = catalogassignments;
        state.catalogassignments_loaded = true;
        state.catalogassignments_loading = false;
    },
    updateCatalogAssignmentsCount(state, count) {
        state.catalogassignments_count = count;
    },
    updateCatalogAssignmentsLoading(state, loading) {
        state.catalogassignments_loading = loading;
    },
    updateCatalogAssignmentsLoadingErrors(state, errors) {
        state.catalogassignments_loading_errors = errors;
    },
    updateCatalogAssignmentDeleting(state, deleting) {
        state.catalog_deleting_assignment = deleting;
    },
    updateCatalogAssignmentDeletingErrors(state, errors) {
        state.catalog_deleting_assignments_errors = errors;
    },
    updateCatalogAssignmentsFilters(state, filters) {
        state.catalogassignments_filters = filters;
    },
};


const getters = {

};

const actions = {

    fetchCatalogAssignments({ commit, dispatch, state }, params) {
        commit('updateCatalogAssignmentsLoading', true);
        commit('updateCatalogAssignmentsLoadingErrors', null);

        return new Promise((resolve, reject) => {

            axios.get("/api/catalogassignments/", { params: state.catalogassignments_filters })
                .then((response) => {
                    commit('updateCatalogAssignments', response.data.results);
                    commit('updateCatalogAssignmentsCount', response.data.count);
                    resolve(response.data.payload);

                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateCatalogAssignmentsLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateCatalogAssignmentsLoading', false);
                });
        });
    },

    deleteCatalogAssignment({ commit, dispatch, state }, params) {
        commit('updateCatalogAssignmentDeleting', true);
        commit('updateCatalogAssignmentDeletingErrors', null);

        return new Promise((resolve, reject) => {

            const url = `/api/catalogassignments/${params.instance.id}/`;


            axios.delete(url)
                .then((response) => {
                    commit('updateCatalogAssignmentDeleting', false);
                    resolve(response.data.payload);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateCatalogAssignmentDeleting', false);
                    commit('updateCatalogAssignmentDeletingErrors', error.details);
                    reject(error);
                })
        });
    },

    init({ commit, dispatch, state }, params) {
        commit('updateCatalog', params.catalog);
        commit('updateCatalogAssignmentsFilters', Object.assign({}, state.catalogassignments_filters, params.filters || {}));
    }
};

export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});