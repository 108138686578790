var render = function render(){
  var _vm$errors;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return (_vm$errors = _vm.errors) !== null && _vm$errors !== void 0 && _vm$errors.length ? _c('div', _vm._l(_vm.errors, function (error) {
    return _c('Error', {
      key: error
    }, [_vm._v(" " + _vm._s(error) + " ")]);
  }), 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }