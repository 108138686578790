import axios from '@/plugins/axios';
import qs from 'qs';
import utils from '@/stores/utils';

const state = {
    operators: [],
    operators_count: 0,
    operators_filters: { offset: 0, limit: 20, ordering: 'name' },
    operators_loading: false,
    operators_loading_errors: null,
    operators_cancel_source: null,

    synchronizing_operators: false,
    synchronizing_operators_errors: {}

};

const mutations = {
    updateOperators(state, operators) {
        state.operators = operators;
    },
    updateOperatorsCount(state, count) {
        state.operators_count = count;
    },
    updateOperatorsLoading(state, loading) {
        state.operators_loading = loading;
    },
    updateOperatorsLoadingErrors(state, errors) {
        state.operators_loading_errors = errors;
    },
    updateOperatorsFilters(state, filters) {
        state.operators_filters = filters;
    },
    updateOperatorsCancelSource(state, source) {
        state.operators_cancel_source = source;
    },
    updateSynchronizingOperators(state, synchronizing) {
        state.synchronizing_operators = synchronizing;
    },
    updateSynchronizingOperatorsErrors(state, errors) {
        state.synchronizing_operators_errors = errors;
    },

};

const actions = {

    async fetchOperators({ commit, dispatch, state }, params) {
        commit('updateOperatorsLoading', true);
        commit('updateOperatorsLoadingErrors', null);

        if (state.operators_cancel_source) {
            state.operators_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit('updateOperatorsCancelSource', cancelSource);

        try {
            const response = await axios.get('/api/operators/', {
                params: state.operators_filters,
                cancelToken: state.operators_cancel_source?.token,
            });
            commit('updateOperatorsLoading', false);
            commit('updateOperators', response.data.results);
            commit('updateOperatorsCount', response.data.count);
            commit('updateOperatorsCancelSource', null);
            return response;
        } catch (xhr_error) {
            if (axios.isCancel(xhr_error)) {
                return;
            }
            const error = utils.handleError(xhr_error);
            commit('updateOperatorsLoading', false);
            commit('updateOperatorsLoadingErrors', error.details);
            throw error;
        }
    },


    async init({ commit, dispatch, state }, params) {
        dispatch('fetchOperators');
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
