<template>
    <v-text-field
        :value="inputValue"
        :single-line="singleLine"
        :clearable="clearable"
        :type="inputType"
        :label="label + (required ? ' (*)' : '')"
        :disabled="disabled"
        :format="format"
        :autofocus="focus"
        :step="step"
        :max="max"
        :min="min"
        :error="!!errors"
        :error-messages="errors"
        hide-details="auto"
        autocomplete="off"
        :placeholder="placeholder"
        :persistent-placeholder="persistentPlaceholder"
        @keydown="$emit('keydown', $event)"
        @input="setValue($event)"
        ref="input"
    />
</template>

<script>
export default {
    name: "Field",
    props: {
        value: {
            type: [String, Number, Boolean],
            required: false,
            default: null,
        },
        persistentPlaceholder: {
            type: Boolean,
            default: false,
        },
        singleLine: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "",
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            required: false,
            default: "text",
        },
        min: {
            type: Number,
            required: false,
        },
        max: {
            type: Number,
            required: false,
        },
        errors: {
            type: Array,
            required: false,
            default: null,
        },
        label: {
            type: String,
            required: false,
        },
        required: {
            type: Boolean,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        format: {
            type: String,
            required: false,
        },
        focus: {
            type: Boolean,
            default: false,
        },
        step: {
            type: String,
            required: false,
        },
    },
    computed: {
        inputType() {
            switch (this.type) {
                case "integer":
                case "float":
                    return "number";
                default:
                    return this.type;
            }
        },
    },
    data() {
        return { inputValue: this.value };
    },
    mounted() {
        if (this.focus) {
            this.$refs.input.focus();
        }
        this.inputValue = this.value;
        this.$forceUpdate();
    },
    methods: {
        setValue(value) {
            const intvalue = parseInt(value);

            switch (this.type) {
                case "integer":
                case "number":
                    this.inputValue = Number.isInteger(intvalue) ? intvalue : null;
                    break;
                case "float":
                    this.inputValue = parseFloat(value);
                    break;
                default:
                    this.inputValue = value;
            }
            this.$emit("input", this.inputValue);
        },
    },
};
</script>
