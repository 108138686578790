var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.items ? _c('v-btn', {
    key: _vm.action,
    staticClass: "w-sm-auto w-100",
    attrs: {
      "loading": _vm.loading,
      "light": !_vm.main,
      "disabled": _vm.disabled,
      "text": _vm.$vuetify.breakpoint.name == 'xs' ? false : !_vm.main,
      "color": _vm.main ? 'primary' : _vm.$vuetify.breakpoint.name == 'xs' ? 'primary-light' : 'primary',
      "elevation": "0"
    },
    on: {
      "click": function click($event) {
        return _vm.actionClicked(_vm.action);
      }
    }
  }, [_vm.icon ? _c('v-icon', {
    attrs: {
      "small": "",
      "color": _vm.main ? 'white' : _vm.color || 'primary-light',
      "left": ""
    }
  }, [_vm._v(_vm._s(_vm.iconName(_vm.icon) || _vm.icon))]) : _vm._e(), _c('div', {
    "class": _vm.main ? 'white--text' : 'primary-text'
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")])], 1) : _c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          key: _vm.action,
          staticClass: "w-sm-auto w-100",
          attrs: {
            "light": "",
            "elevation": "0",
            "text": _vm.$vuetify.breakpoint.name == 'xs' ? false : !_vm.main,
            "color": _vm.main ? 'primary' : _vm.$vuetify.breakpoint.name == 'xs' ? 'primary-light' : 'primary'
          }
        }, 'v-btn', attrs, false), on), [_c('div', {
          "class": _vm.main ? 'white--text' : 'primary-text'
        }, [_vm._v(" " + _vm._s(_vm.label) + " ")]), _vm.icon ? _c('v-icon', {
          attrs: {
            "small": "",
            "color": _vm.main ? 'white' : 'primary-light',
            "right": ""
          }
        }, [_vm._v(_vm._s(_vm.iconName("down")))]) : _vm._e()], 1)];
      }
    }])
  }, [_c('v-list', _vm._l(_vm.items, function (item, index) {
    return _c('v-list-item', {
      key: index,
      attrs: {
        "disabled": item.disabled
      },
      on: {
        "click": function click($event) {
          return _vm.actionClicked(item);
        }
      }
    }, [item.icon ? _c('v-icon', {
      attrs: {
        "small": "",
        "left": "",
        "color": item.color
      }
    }, [_vm._v(_vm._s(_vm.iconName(item.icon) || item.icon))]) : _vm._e(), _vm._v(_vm._s(item.label) + " ")], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }