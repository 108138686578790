import axios from "@/plugins/axios";
import utils from "@/stores/utils";
import catalogentries from "./submodules/catalogentries";

const state = {
    selected_items: [],
    importing_products: false,
};

const mutations = {
    selectItem(state, item) {
        if (!state.selected_items.find((selected_item) => selected_item.id == item.id)) {
            state.selected_items.push(item);
        }
    },
    deselectItem(state, item) {
        state.selected_items = state.selected_items.filter((selected_item) => selected_item.id != item.id);
    },
    toggleItem(state, item) {
        if (!state.selected_items.find((selected_item) => selected_item.id == item.id)) {
            state.selected_items.push(item);
        } else {
            state.selected_items = state.selected_items.filter((selected_item) => selected_item.id != item.id);
        }
    },
    updateSelectedItems(state, items) {
        state.selected_items = items;
    },
    updateImportingProducts(state, importing) {
        state.importing_products = importing;
    },
};

const actions = {


    async importProducts({ commit, dispatch, state }, params) {
        commit("updateImportingProducts", true);
        try {
            const response = await axios.post(`/api/importproducts/`, {
                products: state.selected_items.map((item) => item.product),
            });
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        } finally {
            commit("updateImportingProducts", false);
        }
    },

    async init({ commit, dispatch, state }, params) {
        await commit("updateSelectedItems", []);
        await dispatch("providercatalogentries/init", { url: '/api/providercatalogentries/', filters: { offset: 0, limit: 20, ordering: 'name' } });
        await dispatch("providercatalogentries/fetchCatalogEntries", {});
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        providercatalogentries: catalogentries(),
    },
};
