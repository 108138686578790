<template>
    <div class="listitem" @click="$emit('click')">
        <slot name="listitem" :scope="item" />
    </div>
</template>

<script>
export default {
    props: {
        edit: {
            type: Boolean,
            required: true,
            default: true,
        },
        remove: {
            type: Boolean,
            required: true,
            default: true,
        },
        item: {
            type: [Object, Array],
            required: true,
        },
    },
};
</script>

<style lang="scss">
.listitem {
    display: inline-block;
    flex-wrap: wrap;
    border: 1px solid #eee;
    border-radius: 4px;
    margin-bottom: 20px;
    background-color: #fdfdfd;
    transition: 0.3s ease all;
    min-width: 100%;
    & > * {
        padding: 16px 0px;
    }
    .controls {
        opacity: 0;
    }

    &:hover {
        background-color: white;

        .controls {
            opacity: 1;
        }
    }
    .controls {
        svg {
            height: 14px;
            width: 14px;
            transition: 0.3s ease all;
            cursor: pointer;
        }
        svg:hover {
            color: #555 !important;
        }
    }
    @media all and (max-width: 720px) {
        .controls {
            opacity: 1;
            svg,
            svg path {
                height: 30px;
                width: 30px;
            }
        }
    }
}
</style>
