var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "110",
      "height": "103",
      "viewBox": "0 0 110 103",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M97.5405 37.835H8.46875V102.401H97.5405V37.835Z",
      "fill": "#FFC727"
    }
  }), _c('path', {
    attrs: {
      "d": "M39.9388 31.1509H8.46875V37.8349H41.7483L39.9388 31.1509Z",
      "fill": "#FFC727"
    }
  }), _c('g', {
    attrs: {
      "opacity": "0.1"
    }
  }, [_c('path', {
    attrs: {
      "opacity": "0.1",
      "d": "M97.5405 37.835H8.46875V102.401H97.5405V37.835Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.1",
      "d": "M39.9388 31.1509H8.46875V37.8349H41.7483L39.9388 31.1509Z",
      "fill": "black"
    }
  })]), _c('path', {
    attrs: {
      "d": "M80.1218 74.2981C62.7319 69.3229 45.727 64.4016 28.9883 59.5308C33.2008 39.8566 44.9967 20.5817 45.7882 0.608887C63.1636 5.28551 80.1577 9.99091 96.8713 14.7035C92.6264 34.6979 80.8449 54.167 80.1218 74.2981Z",
      "fill": "#EBEBEB"
    }
  }), _c('path', {
    attrs: {
      "d": "M80.1218 74.2982C62.7284 69.3518 45.7199 64.4665 28.9704 59.6244L28.8696 59.5956L28.8876 59.5021C33.0858 39.8207 44.8637 20.5422 45.6191 0.562223C45.6191 0.511859 45.6191 0.461496 45.6191 0.411133L45.7882 0.457901C63.1781 5.15971 80.1758 9.86871 96.9073 14.5957L97.0225 14.6281L96.9973 14.736C92.6984 34.7196 80.8845 54.1779 80.1218 74.2982ZM80.1218 74.2982C80.8054 54.1527 92.5581 34.6764 96.7706 14.6712C96.803 14.7144 96.8318 14.7612 96.8642 14.8079C80.1362 10.1133 63.1493 5.43311 45.781 0.760081L45.9573 0.655754C45.1299 20.6213 33.316 39.8926 29.0927 59.5632L29.0099 59.4409C45.7451 64.3406 62.732 69.2906 80.1218 74.2982Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M77.37 37.7561C74.7835 44.6595 66.9376 48.5088 59.6384 46.3467C52.3393 44.1847 48.4865 36.8927 51.0047 30.0253C53.5228 23.1579 61.0774 19.2331 68.4665 21.3699C75.8555 23.5068 79.9853 30.8275 77.37 37.7561Z",
      "fill": "#455A64"
    }
  }), _c('path', {
    attrs: {
      "d": "M64.1997 33.8632C61.203 37.288 58.1704 40.6983 55.2062 44.1194C53.0663 42.5442 51.4991 40.3135 50.7427 37.7663C49.9863 35.2191 50.0819 32.4947 51.0152 30.0068C53.4974 23.1717 61.1239 19.247 68.477 21.3514C75.8301 23.4559 79.9671 30.8702 77.3662 37.7376C72.9797 36.4426 68.5909 35.1511 64.1997 33.8632Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M64.1997 33.8633C66.815 37.378 69.4016 40.8926 72.0421 44.4181C70.2405 45.6252 68.1958 46.4219 66.0525 46.752C63.9092 47.0821 61.7195 46.9374 59.6382 46.3283C52.3355 44.1879 48.5115 36.8312 51.0045 30.0069C53.4975 23.1826 61.1132 19.2471 68.4663 21.3515C75.8194 23.456 79.9564 30.8703 77.3554 37.7377C72.9762 36.4427 68.591 35.1512 64.1997 33.8633Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M64.1999 33.8632L50.3859 36.1656C50.0778 34.092 50.2955 31.9743 51.019 30.0068C53.5012 23.1717 61.1277 19.247 68.4808 21.3514C75.8339 23.4559 79.9709 30.8702 77.37 37.7376C72.9812 36.4426 68.5912 35.1511 64.1999 33.8632Z",
      "fill": "#FFC727"
    }
  }), _c('path', {
    attrs: {
      "d": "M49.7273 9.27148C56.7854 11.2333 63.7944 13.1974 70.7541 15.164C70.6318 15.6713 70.5059 16.1821 70.3944 16.6929C63.4586 14.7263 56.4797 12.7622 49.4575 10.8004C49.5403 10.2895 49.6338 9.78232 49.7273 9.27148Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M48.1372 48.6128C57.4377 51.354 66.7909 54.112 76.1969 56.8868C76.0566 57.3941 75.9199 57.9049 75.7868 58.4121C66.3449 55.6421 56.9652 52.8841 47.6479 50.1381C47.8062 49.6249 47.9693 49.1164 48.1372 48.6128Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M46.9217 52.4297C56.275 55.1805 65.7014 57.9469 75.2009 60.7289C75.0786 61.2397 74.9635 61.7506 74.8412 62.2578C65.3104 59.4734 55.8517 56.7022 46.4648 53.9442C46.6195 53.437 46.767 52.9333 46.9217 52.4297Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M45.8316 56.25C55.2712 59.0176 64.7887 61.7984 74.3842 64.5924C74.2906 65.1032 74.2007 65.614 74.118 66.1285C64.4649 63.3249 54.9054 60.5381 45.4395 57.7681C45.5654 57.2609 45.6985 56.7536 45.8316 56.25Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M42.6367 48.6556L39.2695 47.6592C39.4386 47.1555 39.6113 46.6555 39.784 46.1519L43.1619 47.1447C42.9773 47.6484 42.8022 48.152 42.6367 48.6556Z",
      "fill": "#FFC727"
    }
  }), _c('path', {
    attrs: {
      "d": "M41.4282 52.4654L38.0215 51.4653C38.1762 50.9617 38.3345 50.458 38.4963 49.958L41.8923 50.9545C41.7376 51.4581 41.5865 51.9618 41.4282 52.4654Z",
      "fill": "#455A64"
    }
  }), _c('path', {
    attrs: {
      "d": "M40.3491 56.2826L36.9136 55.279C37.0503 54.7753 37.1906 54.2717 37.3345 53.7681L40.7556 54.7681C40.6261 55.2718 40.4894 55.779 40.3491 56.2826Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M96.2203 29.2511C96.0428 52.2289 95.827 74.9501 95.5728 97.4147C67.3907 99.0731 37.3955 89.5328 9.78906 94.7383C9.96174 71.7628 10.1776 49.0404 10.4366 26.571C38.6187 24.9162 68.6102 34.4566 96.2203 29.2511Z",
      "fill": "#FFC727"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.8",
      "d": "M96.2203 29.2511C96.0428 52.2289 95.827 74.9501 95.5728 97.4147C67.3907 99.0731 37.3955 89.5328 9.78906 94.7383C9.96174 71.7628 10.1776 49.0404 10.4366 26.571C38.6187 24.9162 68.6102 34.4566 96.2203 29.2511Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M22.2646 87.0729C22.4109 74.6019 22.568 62.1945 22.7359 49.8506C23.4554 49.8506 24.1881 49.865 24.9339 49.8938C24.766 62.2161 24.6042 74.5863 24.4483 87.0046C23.7192 87.0286 22.9913 87.0513 22.2646 87.0729Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M27.7832 86.979C27.9463 74.6087 28.1166 62.2876 28.294 50.0156C29.0315 50.048 29.7654 50.0876 30.5028 50.1307C30.323 62.3811 30.1479 74.6722 29.9776 87.0041C29.2473 86.9897 28.5135 86.979 27.7832 86.979Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M33.334 87.1086C33.5115 74.815 33.6913 62.5611 33.8736 50.3467L36.0932 50.5194C35.9037 62.7194 35.7179 74.9505 35.5356 87.2129C34.8065 87.1745 34.0727 87.1397 33.334 87.1086Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M38.9102 87.4181C39.1836 69.5078 39.4654 51.6515 39.7555 33.8491L41.9823 34.0758C41.6922 51.8757 41.408 69.7116 41.1298 87.5835C40.3959 87.526 39.6584 87.4684 38.9102 87.4181Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M44.522 87.8712C44.805 70.0233 45.094 52.2125 45.3889 34.439L47.6193 34.6836C47.3291 52.4644 47.039 70.2619 46.7488 88.0763C46.0077 88.0043 45.263 87.936 44.522 87.8712Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M50.145 88.4181C50.4328 70.6133 50.7242 52.8254 51.0192 35.0542L53.2532 35.2952C52.9582 53.088 52.6644 70.8736 52.3718 88.6519L50.145 88.4181Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M55.771 89.0189C56.066 71.2381 56.3586 53.4489 56.6488 35.6514L58.8756 35.8744C58.5902 53.6959 58.3 71.4923 58.005 89.2635L55.771 89.0189Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M61.4048 89.634C61.6974 71.8484 61.9852 54.034 62.2682 36.1909C63.0092 36.2557 63.7503 36.3168 64.4914 36.3744C64.2108 54.2535 63.9254 72.0858 63.6352 89.8714C62.8905 89.7958 62.1494 89.7167 61.4048 89.634Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M67.0308 90.2205C67.3186 72.4037 67.5979 54.5366 67.869 36.6191C68.6064 36.6695 69.3475 36.7127 70.0814 36.7522C69.8199 54.7129 69.5453 72.6052 69.2575 90.4291L67.0308 90.2205Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M72.647 90.7314C72.9252 72.8595 73.1926 54.9193 73.4492 36.9106C74.1831 36.9394 74.9169 36.961 75.6508 36.9754C75.411 55.0416 75.1496 73.019 74.8666 90.9077L72.647 90.7314Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M78.2402 91.1305C78.5064 73.1795 78.7583 55.1409 78.9957 37.0147C79.7152 37.0147 80.4443 37.0076 81.1829 36.9932C80.9599 55.1864 80.7201 73.2706 80.4634 91.2456C79.72 91.2144 78.9789 91.1761 78.2402 91.1305Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M83.813 91.3573C84.0528 73.3055 84.2842 55.1434 84.5073 36.871C85.2268 36.835 85.9462 36.7955 86.6657 36.7451C86.4643 55.0919 86.2412 73.3091 85.9966 91.3969C85.2771 91.3993 84.5493 91.3861 83.813 91.3573Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M94.4218 31.4351H11.0483V96.3862H94.4218V31.4351Z",
      "fill": "#EBEBEB"
    }
  }), _c('path', {
    attrs: {
      "d": "M94.4254 31.435L94.5369 96.3933V96.5084H94.4254L11.0483 96.5696H10.8721V96.3933L10.9152 31.4458V31.3091H11.0483L94.4254 31.435ZM94.4254 31.435L11.0483 31.5681L11.185 31.435L11.2102 96.3933L11.034 96.217L94.411 96.2818L94.3103 96.3933L94.4254 31.435Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M28.8663 89.2778L28.8052 63.9089L28.8663 38.54L28.9311 63.9089L28.8663 89.2778Z",
      "fill": "#C7C7C7"
    }
  }), _c('path', {
    attrs: {
      "d": "M30.9383 89.2778L30.8735 63.9089L30.9383 38.54L30.9994 63.9089L30.9383 89.2778Z",
      "fill": "#C7C7C7"
    }
  }), _c('path', {
    attrs: {
      "d": "M33.0066 89.2778L32.9419 63.9089L33.0066 38.54L33.0714 63.9089L33.0066 89.2778Z",
      "fill": "#C7C7C7"
    }
  }), _c('path', {
    attrs: {
      "d": "M35.075 89.2778L35.0103 63.9089L35.075 38.54L35.1398 63.9089L35.075 89.2778Z",
      "fill": "#C7C7C7"
    }
  }), _c('path', {
    attrs: {
      "d": "M37.1439 89.2778L37.0791 63.9089L37.1439 38.54L37.2086 63.9089L37.1439 89.2778Z",
      "fill": "#C7C7C7"
    }
  }), _c('path', {
    attrs: {
      "d": "M39.2125 89.2778L39.1514 63.9089L39.2125 38.54L39.2773 63.9089L39.2125 89.2778Z",
      "fill": "#C7C7C7"
    }
  }), _c('path', {
    attrs: {
      "d": "M41.284 89.2778L41.2192 63.9089L41.284 38.54L41.3451 63.9089L41.284 89.2778Z",
      "fill": "#C7C7C7"
    }
  }), _c('path', {
    attrs: {
      "d": "M43.3528 89.2778L43.2881 63.9089L43.3528 38.54L43.414 63.9089L43.3528 89.2778Z",
      "fill": "#C7C7C7"
    }
  }), _c('path', {
    attrs: {
      "d": "M45.4212 89.2778L45.3564 63.9089L45.4212 38.54L45.4859 63.9089L45.4212 89.2778Z",
      "fill": "#C7C7C7"
    }
  }), _c('path', {
    attrs: {
      "d": "M47.49 89.2778L47.4253 63.9089L47.49 38.54L47.5548 63.9089L47.49 89.2778Z",
      "fill": "#C7C7C7"
    }
  }), _c('path', {
    attrs: {
      "d": "M49.5584 89.2778L49.4937 63.9089L49.5584 38.54L49.6232 63.9089L49.5584 89.2778Z",
      "fill": "#C7C7C7"
    }
  }), _c('path', {
    attrs: {
      "d": "M51.6266 89.2778L51.5654 63.9089L51.6266 38.54L51.6913 63.9089L51.6266 89.2778Z",
      "fill": "#C7C7C7"
    }
  }), _c('path', {
    attrs: {
      "d": "M53.699 89.2778L53.6343 63.9089L53.699 38.54L53.7602 63.9089L53.699 89.2778Z",
      "fill": "#C7C7C7"
    }
  }), _c('path', {
    attrs: {
      "d": "M55.7679 89.2778L55.7031 63.9089L55.7679 38.54L55.8326 63.9089L55.7679 89.2778Z",
      "fill": "#C7C7C7"
    }
  }), _c('path', {
    attrs: {
      "d": "M57.8357 89.2778L57.771 63.9089L57.8357 38.54L57.9005 63.9089L57.8357 89.2778Z",
      "fill": "#C7C7C7"
    }
  }), _c('path', {
    attrs: {
      "d": "M51.562 80.0609V83.9102H60.2461V80.0609H51.562Z",
      "fill": "#FFC727"
    }
  }), _c('path', {
    attrs: {
      "d": "M46.9326 72.9242V76.7734H60.2466V72.9242H46.9326Z",
      "fill": "#FFC727"
    }
  }), _c('path', {
    attrs: {
      "d": "M41.7451 65.7904V69.6396H60.2466V65.7904H41.7451Z",
      "fill": "#FFC727"
    }
  }), _c('path', {
    attrs: {
      "d": "M39.5181 58.6493V62.4985H60.2463V58.6493H39.5181Z",
      "fill": "#FFC727"
    }
  }), _c('path', {
    attrs: {
      "d": "M35.5537 51.5121V55.3613H60.2499V51.5121H35.5537Z",
      "fill": "#FFC727"
    }
  }), _c('path', {
    attrs: {
      "d": "M31.6289 44.3749V48.2241H60.2463V44.3749H31.6289Z",
      "fill": "#FFC727"
    }
  }), _c('path', {
    attrs: {
      "d": "M27.4165 87.9036L35.6222 87.8029L43.8315 87.7345C49.3031 87.6878 54.7748 87.6734 60.2464 87.6626L60.009 87.9036V75.5609L60.0378 63.2218L60.1169 50.8791L60.2572 38.54L60.4011 50.8791L60.4767 63.2218L60.5054 75.5609V87.9036V88.141H60.268C54.7964 88.141 49.3247 88.1159 43.853 88.0691L35.6438 88.0008L27.4165 87.9036Z",
      "fill": "#C7C7C7"
    }
  }), _c('path', {
    attrs: {
      "d": "M61.9839 80.0648V83.9141H62.3328V80.0648H61.9839Z",
      "fill": "#C7C7C7"
    }
  }), _c('path', {
    attrs: {
      "d": "M61.98 72.9276V76.7769H62.3289V72.9276H61.98Z",
      "fill": "#C7C7C7"
    }
  }), _c('path', {
    attrs: {
      "d": "M61.98 65.7904V69.6396H62.3289V65.7904H61.98Z",
      "fill": "#C7C7C7"
    }
  }), _c('path', {
    attrs: {
      "d": "M61.98 58.6493V62.4985H62.3289V58.6493H61.98Z",
      "fill": "#C7C7C7"
    }
  }), _c('path', {
    attrs: {
      "d": "M61.9839 51.5121V55.3613H62.3328V51.5121H61.9839Z",
      "fill": "#C7C7C7"
    }
  }), _c('path', {
    attrs: {
      "d": "M61.9839 44.3749V48.2241H62.3328V44.3749H61.9839Z",
      "fill": "#C7C7C7"
    }
  }), _c('path', {
    attrs: {
      "d": "M52.6593 83.4786C52.6266 81.5984 52.4801 79.7219 52.2204 77.8595C51.986 75.9977 51.6534 74.1497 51.2239 72.3231C50.4009 68.6652 49.1512 65.1167 47.5006 61.7503C45.8791 58.3737 43.7634 55.2577 41.2231 52.505L40.7519 51.9869L40.2518 51.4941L39.2482 50.512C38.5287 49.9076 37.856 49.2565 37.0897 48.7133C35.6107 47.5501 34.0475 46.4979 32.4131 45.5656L32.4311 45.5332C37.5238 48.0996 41.8848 51.9124 45.1083 56.6168C46.19 58.1737 47.1521 59.8105 47.9863 61.5129C49.6384 64.9233 50.8612 68.5254 51.6268 72.2367C52.4275 75.928 52.7742 79.7033 52.6593 83.4786Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M35.1112 44.4722L35.2443 45.0046L32.6182 45.6593L33.4636 48.2314L32.9383 48.4005L31.9131 45.2672L35.1112 44.4722Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M75.2979 90.3642V37.4536H73.1574V90.3642H75.2979Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M80.7012 90.3607V60.5742H78.5607V90.3607H80.7012Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M22.4048 78.8021L22.4048 49.0156H20.2643V78.8021H22.4048Z",
      "fill": "#DBDBDB"
    }
  }), _c('path', {
    attrs: {
      "d": "M109.797 34.5827L109.765 99.5338L26.3877 99.4906L26.4129 51.2674L42.3062 34.5503L109.797 34.5827Z",
      "fill": "#FFC727"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.6",
      "d": "M109.797 34.5827L109.765 99.5338L26.3877 99.4906L26.4129 51.2674L42.3062 34.5503L109.797 34.5827Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M26.4126 51.2674L42.3059 34.5503L42.2987 51.2746L26.4126 51.2674Z",
      "fill": "#FFC727"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.4",
      "d": "M26.4126 51.2674L42.3059 34.5503L42.2987 51.2746L26.4126 51.2674Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M42.5187 93.1625L42.5376 57.0518L40.3971 57.0506L40.3782 93.1614L42.5187 93.1625Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M47.923 93.1625L47.9419 57.0518L45.8014 57.0506L45.7825 93.1614L47.923 93.1625Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M53.3268 93.1659L53.3457 57.0552L51.2052 57.0541L51.1863 93.1648L53.3268 93.1659Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M58.7301 93.1704L58.7578 40.2598L56.6174 40.2586L56.5896 93.1693L58.7301 93.1704Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M64.1344 93.1743L64.1621 40.2637L62.0216 40.2626L61.9939 93.1732L64.1344 93.1743Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M69.5387 93.1772L69.5664 40.2666L67.426 40.2655L67.3983 93.1761L69.5387 93.1772Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M74.943 93.1772L74.9707 40.2666L72.8302 40.2655L72.8025 93.1761L74.943 93.1772Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M80.3434 93.1811L80.3711 40.2705L78.2306 40.2694L78.2029 93.18L80.3434 93.1811Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M85.7506 93.1811L85.7783 40.2705L83.6379 40.2694L83.6102 93.18L85.7506 93.1811Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M91.1549 93.1885L91.1826 40.2778L89.0422 40.2767L89.0145 93.1873L91.1549 93.1885Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M96.5553 93.1919L96.583 40.2812L94.4425 40.2801L94.4148 93.1907L96.5553 93.1919Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M101.96 93.1919L101.987 40.2812L99.8468 40.2801L99.8191 93.1907L101.96 93.1919Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M97.3065 102.401H8.23481L1.36377 43.1914H90.4355L97.3065 102.401Z",
      "fill": "#FFC727"
    }
  }), _c('path', {
    attrs: {
      "d": "M32.1254 37.0615H0.655273L1.36396 43.1915H34.6436L32.1254 37.0615Z",
      "fill": "#FFC727"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }