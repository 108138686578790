import qs from 'qs';
import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    products: [],
    products_count: 0,
    products_filters: { offset: 0, limit: 20, ordering: 'name', available_for_sale: true },
    products_loading: false,
    products_loading_errors: null,
    products_cancel_source: null,
    selected_items: [],
};

const mutations = {
    updateProducts(state, products) {
        state.products = products;
        // Replace selected_items instances with newly fetched products based on id field
        state.selected_items = state.selected_items.map((selected_item) => {
            return products.find((product) => product.id === selected_item.id) || selected_item;
        });
    },
    updateProductsFilters(state, products_filters) {
        state.products_filters = products_filters;
    },
    updateProductsCount(state, products_count) {
        state.products_count = products_count;
    },
    updateProductsLoading(state, loading) {
        state.products_loading = loading;
    },
    updateProductsLoadingErrors(state, errors) {
        state.products_loading_errors = errors;
    },
    updateProductsCancelSource(state, source) {
        state.products_cancel_source = source;
    },
    updateSelectedItems(state, items) {
        state.selected_items = items;
    },
};

const actions = {

    async bulkAction({ commit, dispatch, state }, params) {
        try {
            let data = {
                instances: state.selected_items.map((item) => item.id),
                action: params.action,
                payload: params.payload || {},
            };
            const response = await axios.post('/api/products/bulk/', data);
            dispatch('fetchProducts');
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        }
    },


    exportProductsAsCSV({ state }, params) {
        let filters = {};
        if (params.filtered) {
            filters = Object.assign({}, state.products_filters);
            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 10000;
        filters.available_for_sale = true;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/products/?" + queryparams);
    },

    async fetchProducts({ commit, state, dispatch, rootGetters }) {
        if (rootGetters['session/current_user_permissions'].indexOf("core.view_product") === -1) {
            return;
        }
        commit('updateProductsLoading', true);
        commit('updateProductsLoadingErrors', null);

        if (state.products_cancel_source) {
            state.products_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit('updateProductsCancelSource', cancelSource);

        try {
            const response = await axios.get('/api/products/', {
                params: state.products_filters,
                cancelToken: state.products_cancel_source?.token,
            });
            commit('updateProducts', response.data.results);
            commit('updateProductsCount', response.data.count);
            commit('updateProductsCancelSource', null);
            return response;
        } catch (xhr_error) {
            if (axios.isCancel(xhr_error)) {
                return;
            }
            const error = utils.handleError(xhr_error);
            commit('updateProductsLoadingErrors', error.details);
            commit('updateProductsCancelSource', null);
            throw error;
        } finally {
            commit('updateProductsLoading', false);
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};