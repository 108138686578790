var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "14.999999999999998",
      "height": "14.999999999999998",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:svg": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    staticClass: "layer"
  }, [_c('title', [_vm._v("Layer 1")]), _c('path', {
    staticClass: "ColorScheme-Text",
    attrs: {
      "id": "svg_1",
      "fill": "currentColor",
      "d": "m5.51099,0.15234c-2.96869,0 -5.35865,2.38996 -5.35865,5.35865c0,2.96869 2.38996,5.35865 5.35865,5.35865c1.32287,0 2.52802,-0.47753 3.46079,-1.2664l4.83884,4.83884l0.63146,-0.63146l-4.83884,-4.83884c0.78887,-0.93278 1.2664,-2.13792 1.2664,-3.46079c0,-2.96869 -2.38996,-5.35865 -5.35865,-5.35865zm0,0.89311c2.47391,0 4.46555,1.99163 4.46555,4.46555c0,2.47391 -1.99163,4.46555 -4.46555,4.46555c-2.47391,0 -4.46555,-1.99163 -4.46555,-4.46555c0,-2.47391 1.99163,-4.46555 4.46555,-4.46555z"
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }