<template>
    <div class="listwrapper">
        <Filters
            :filters="{}"
            :filters-conf="filtersConf"
            :search="search"
            :search-label="searchLabel"
            @on-filters-change="onFiltersChange"
        />

        <div class="mainfilter" v-if="mainFilters" :key="activemainfilter">
            <div class="d-flex">
                <div
                    class="mainfilter__item"
                    :key="item.id"
                    v-for="item in mainFilters"
                    @click="selectMainFilter(item)"
                    :class="{
                        active: activemainfilter && item.code == activemainfilter,
                    }"
                >
                    {{ item.label }}
                </div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-12 col-md-auto">
                <span v-show="!loading && items.length && title">
                    <em>{{ title }}</em>
                </span>
                <span v-show="!loading && !items.length">
                    <em v-translate>Nothing found</em>
                </span>
                <span v-show="loading">
                    <em v-translate>Loading</em>
                </span>
            </div>
        </div>

        <div v-if="items.length" class="list mt-2">
            <ul class="pa-0">
                <li v-for="(item, index) in filtered_items" :key="item.id">
                    <ListItem
                        :item="item"
                        :edit="edit"
                        :remove="remove"
                        @click="$emit('clicked-item', item)"
                        @edit-item="$emit('edit-item', item)"
                        @remove-item="$emit('remove-item', item)"
                    >
                        <template slot="listitem" slot-scope="scope">
                            <slot name="item" :item="scope.scope" :index="index" />
                        </template>
                    </ListItem>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import ListItem from "./list-item";

export default {
    components: {
        ListItem,
    },
    props: {
        items: {
            required: true,
        },
        limit: {
            type: Number,
            required: false,
            default: null,
        },
        edit: {
            type: Boolean,
            required: false,
            default: false,
        },
        remove: {
            type: Boolean,
            required: false,
            default: false,
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        filters: {
            required: false,
            default() {
                return [];
            },
        },
        filtersConf: {
            required: false,
            default: null,
        },
        title: {
            type: String,
            required: false,
        },
        search: {
            type: Boolean,
            required: false,
            default: false,
        },
        searchLabel: {
            type: String,
            required: false,
        },
        sortorder: {
            type: String,
            required: false,
            default: null,
        },
        sortby: {
            type: String,
            required: false,
            default: null,
        },
        sort: {
            type: Boolean,
            required: false,
            default: true,
        },
        activeMainFilter: {
            type: String,
            required: false,
        },
        mainFilters: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    computed: {
        perPage: function () {
            return parseInt(localStorage.resultsPerPage || 30);
        },
        filtered_items() {
            if (!this.limit) {
                return this.items;
            }
            return this.items.slice(0, this.limit);
        },
    },
    data() {
        return {
            activemainfilter: this.activeMainFilter,
        };
    },
    watch: {
        mainFilters() {
            if (!this.activemainfilter) {
                this.activemainfilter = this.mainFilters[0].code;
            }
        },
        activemainfilter() {
            this.updateFilters();
        },
    },
    methods: {
        onFiltersChange(filters) {
            this.updateFilters(filters);
        },
        updateFilters(filters) {
            filters = Object.assign({}, filters);
            if (this.activemainfilter) {
                let filter = this.mainFilters.find(
                    (filter) => filter.code == this.activemainfilter
                );
                filters = Object.assign(filters, filter.filters);
            }
            this.$emit("on-filters-change", filters);
        },
        selectMainFilter(item) {
            this.activemainfilter = item.code;
        },
        onPerPageChange(context) {
            localStorage.resultsPerPage = context.currentPerPage;
        },
    },
    mounted() {
        if (this.mainFilters.length && !this.activemainfilter) {
            this.activemainfilter = this.mainFilters[0].code;
        }
    },
};
</script>

<style lang="scss" scoped>
.listwrapper {
    .mainfilter {
        font-family: Avenir-Roman;
        font-size: 11px;
        color: #202020;
        margin-bottom: 10px;
        .mainfilter__item {
            margin-right: 20px;
            cursor: pointer;
            transition: all 0.4s ease;
            border-bottom: 2px solid transparent;
            &.active {
                border-bottom: 2px solid grey;
            }
        }
    }
    ul li {
        list-style: none;
    }
}
</style>
