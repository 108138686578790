
import Vue from 'vue';


const handleError = function (xhr_error) {
    const error = {
        success: false,
        code: xhr_error.request?.status || xhr_error.code
    };

    if (error.code == null) {
        console.error(xhr_error);
    }

    if (!xhr_error.request || error.code == 500) {
        if (Vue.prototype.$gettext) {
            error.details = Vue.prototype.$gettext('An unexpected error happened');
        } else {
            error.details = 'An unexpected error happened';
        }
    } else if (xhr_error.response) {
        error.details = xhr_error.response.data;
    }
    return error;
};


function isColorDark(color) {
    function luma(color) // color can be a hx string or an array of RGB values 0-255
    {
        var rgb = (typeof color === 'string') ? hexToRGBArray(color) : color;
        return (0.2126 * rgb[0]) + (0.7152 * rgb[1]) + (0.0722 * rgb[2]); // SMPTE C, Rec. 709 weightings
    }
    function hexToRGBArray(color) {
        if (color.length === 3)
            color = color.charAt(0) + color.charAt(0) + color.charAt(1) + color.charAt(1) + color.charAt(2) + color.charAt(2);
        else if (color.length !== 6)
            throw ('Invalid hex color: ' + color);
        var rgb = [];
        for (var i = 0; i <= 2; i++)
            rgb[i] = parseInt(color.substr(i * 2, 2), 16);
        return rgb;
    }
    if (color.startsWith('#')) {
        color = color.substr(1, 6);
    }
    return (luma(color) >= 165) ? false : true
}



export default {
    handleError,
    isColorDark
};
