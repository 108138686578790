import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    entity: false,
    entity_saving: false,
    entity_saving_errors: {},
};


const mutations = {

    updateEntity(state, entity) {
        state.entity = entity;
    },

    updateEntitySaving(state, saving) {
        state.entity_saving = saving;
        state.entity_saving_errors = {};
    },

    updateEntitySavingErrors(state, saving_errors) {
        state.entity_saving_errors = saving_errors || {};
        state.entity_saving = false;
    }
};

const actions = {

    async saveEntity({ commit, dispatch, state }, params) {
        commit('updateEntitySaving', true);

        let entity = { ...state.entity };
        try {
            const response = await axios.patch(`/api/entities/${state.entity.id}/`, entity);
            commit('updateEntity', { ...state.entity, id: response.data.id });
            commit('updateEntitySaving', false);
            dispatch('session/fetchLoginInfo', null, { root: true });
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateEntitySavingErrors', error.details);
            throw error;
        }
    },

    init({ commit, dispatch, state }, params) {
        let entity = {
            ...params.entity
        };
        if (!entity.billing_address) {
            entity.billing_address = {};
        }
        commit('updateEntity', entity);
    }

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
