var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "560",
      "height": "560",
      "viewBox": "0 0 560 560",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M280 478.867C399.932 478.867 497.157 473.191 497.157 466.189C497.157 459.187 399.932 453.51 280 453.51C160.068 453.51 62.8432 459.187 62.8432 466.189C62.8432 473.191 160.068 478.867 280 478.867Z",
      "fill": "#EAF2F9"
    }
  }), _c('path', {
    attrs: {
      "d": "M400.803 123.144H369.634C366.832 123.144 364.56 125.416 364.56 128.218V156.486C364.56 159.289 366.832 161.56 369.634 161.56H400.803C403.605 161.56 405.877 159.289 405.877 156.486V128.218C405.877 125.416 403.605 123.144 400.803 123.144Z",
      "fill": "#009B5A"
    }
  }), _c('path', {
    attrs: {
      "d": "M381.181 156.677C381.181 156.677 383.79 169.299 401.576 176.299C401.576 176.299 390.701 169.344 391.373 156.677H381.181Z",
      "fill": "#009B5A"
    }
  }), _c('path', {
    attrs: {
      "d": "M376.275 141.266L382.178 149.464L394.162 135.251",
      "stroke": "white",
      "stroke-width": "3.36",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M439.634 167.35C445.11 171.282 450.061 178.002 446.354 196.818C442.646 215.634 441.706 220.114 441.706 220.114L405.866 216.082C405.866 216.082 404.186 199.427 406.986 183.758C410.771 163.52 423.595 155.826 439.634 167.35Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M403.76 271.32C403.76 271.32 391.619 336 388.382 362.578C384.149 397.23 366.845 447.922 366.845 447.922L373.766 449.714C373.766 449.714 398.16 406.627 402.886 367.875C422.262 328.261 423.83 279.474 423.83 279.474L403.76 271.32Z",
      "fill": "#EEC1BB"
    }
  }), _c('path', {
    attrs: {
      "d": "M353.92 463.601C355.038 463.087 356.242 462.783 357.47 462.706C357.47 462.706 361.726 460.197 365.938 450.475H370.686C371.487 450.486 372.265 450.742 372.916 451.209C373.567 451.675 374.059 452.33 374.326 453.085C374.404 453.297 374.45 453.52 374.461 453.746C374.408 454.872 374.284 455.994 374.091 457.106C373.397 460.891 372.473 464.632 371.325 468.306L370.518 468.093L371.974 461.026C372.467 458.618 369.936 460.936 368.771 463.366C367.606 465.797 365.848 468.16 360.64 467.04C354.502 465.584 352.05 464.699 353.92 463.601Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M374.506 453.175C375.402 450.879 376.544 446.768 376.544 446.768L366.386 445.357C366.386 445.357 366.386 450.352 364.504 453.623C369.813 453.6 374.506 453.175 374.506 453.175Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M416.36 270.29C416.36 270.29 417.872 336.09 420.19 362.746C423.226 397.533 416.774 450.711 416.774 450.711L423.92 451.024C423.92 451.024 438.872 403.827 435.512 364.919C446.275 322.157 437.752 274.109 437.752 274.109L416.36 270.29Z",
      "fill": "#EEC1BB"
    }
  }), _c('path', {
    attrs: {
      "d": "M405.664 465.774C406.722 465.138 407.887 464.701 409.102 464.486C409.102 464.486 413.056 461.541 416.203 451.416L420.907 450.878C421.71 450.803 422.517 450.977 423.217 451.378C423.917 451.78 424.475 452.387 424.816 453.118C424.916 453.319 424.985 453.535 425.018 453.757C425.09 454.902 425.09 456.05 425.018 457.195C424.729 461.016 424.21 464.816 423.461 468.574L422.643 468.462L423.326 461.272C423.55 458.83 421.299 461.406 420.392 463.949C419.485 466.491 417.962 469.101 412.63 468.429C406.426 467.678 403.883 467.04 405.664 465.774Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M424.962 453.174C425.6 450.8 426.294 446.589 426.294 446.589L416.046 446.286C416.046 446.286 416.584 451.248 415.072 454.709C420.336 454.093 424.962 453.174 424.962 453.174Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M401.173 260.523L387.856 352.61C387.856 352.61 431.222 359.33 448.582 355.398C448.582 355.398 452.85 302.232 434.728 263.558L401.173 260.523Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M361.088 227.965L349.496 216.518C349.496 216.518 349.104 213.45 347.973 212.598C346.842 211.747 338.912 208.858 337.893 209.496C336.874 210.134 335.563 216.563 336.93 217.739C338.296 218.915 345.52 220.416 346.259 220.293L358.837 235.2L361.088 227.965Z",
      "fill": "#EEC1BB"
    }
  }), _c('path', {
    attrs: {
      "d": "M402.573 207.099C397.578 209.754 379.837 234.55 377.227 235.771C374.618 236.992 354.648 220.808 354.648 220.808L351.288 226.554C351.288 226.554 367.662 249.76 376.925 248.64C386.187 247.52 403.917 227.92 403.917 227.92L402.573 207.099Z",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.1",
      "d": "M402.573 207.099C397.578 209.754 379.837 234.55 377.227 235.771C374.618 236.992 354.648 220.808 354.648 220.808L351.288 226.554C351.288 226.554 367.662 249.76 376.925 248.64C386.187 247.52 403.917 227.92 403.917 227.92L402.573 207.099Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M355.746 221.424L354.357 220.114L350.717 226.274L352.027 228.794L355.746 221.424Z",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M396.794 235.099C400.938 231.146 403.917 227.864 403.917 227.864L403.603 222.936L402.08 218.154C399.931 220.365 398.333 223.052 397.415 225.996C396.497 228.939 396.284 232.058 396.794 235.099Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M400.893 259.582C399.493 234.942 400.12 221.368 400.758 214.592C401.464 207.368 402.573 207.099 402.573 207.099C402.573 207.099 410.413 206.338 417.211 206.394C422.471 206.572 427.711 207.126 432.891 208.051C437.822 208.869 442.688 210.041 447.451 211.557C446.656 213.797 445.939 216.037 445.267 218.008C444.382 220.685 443.587 223.16 442.859 225.478C441.515 229.802 440.462 233.542 439.634 236.891C437.493 245.324 436.242 253.958 435.904 262.651C435.904 262.651 444.517 285.163 446.197 301.683L417.637 304.763L413.717 288.243L409.797 305.323L393.837 299.723L400.893 259.582Z",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.1",
      "d": "M400.893 259.582C399.493 234.942 400.12 221.368 400.758 214.592C401.464 207.368 402.573 207.099 402.573 207.099C402.573 207.099 410.413 206.338 417.211 206.394C422.471 206.572 427.711 207.126 432.891 208.051C437.822 208.869 442.688 210.041 447.451 211.557C446.656 213.797 445.939 216.037 445.267 218.008C444.382 220.685 443.587 223.16 442.859 225.478C441.515 229.802 440.462 233.542 439.634 236.891C437.493 245.324 436.242 253.958 435.904 262.651C435.904 262.651 444.517 285.163 446.197 301.683L417.637 304.763L413.717 288.243L409.797 305.323L393.837 299.723L400.893 259.582Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M438.245 242.928C438.626 241.046 439.074 239.042 439.622 236.891C439.97 235.525 440.35 234.091 440.742 232.568C439.622 228.749 438.502 231.739 438.502 231.739C437.808 235.43 437.721 239.209 438.245 242.928Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M417.603 207.894C417.948 207.72 418.303 207.566 418.667 207.435C419.474 206.797 420.459 205.262 420.414 201.533C420.378 200.054 420.239 198.58 420 197.12L430.382 195.182C430.382 195.182 426.843 202.171 429.789 207.368C430.808 207.816 431.413 208.488 431.301 209.306C430.528 215.298 417.626 221.099 417.626 221.099C417.626 221.099 411.342 211.333 417.603 207.894Z",
      "fill": "#EEC1BB"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M420 197.12C420.234 198.569 420.369 200.032 420.403 201.499C427.414 201.499 430.382 195.182 430.382 195.182L420 197.12Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M425.746 168.123C416.718 167.63 413.549 172.301 412.384 181.563C410.917 193.144 413.045 201.544 424.469 199.685C439.981 197.12 440.742 168.941 425.746 168.123Z",
      "fill": "#EEC1BB"
    }
  }), _c('path', {
    attrs: {
      "d": "M418.242 181.44C417.445 182.748 416.494 183.955 415.408 185.035C415.655 185.418 416.004 185.726 416.415 185.923C416.826 186.121 417.284 186.201 417.738 186.155L418.242 181.44Z",
      "fill": "#D4827D"
    }
  }), _c('path', {
    attrs: {
      "d": "M423.808 180.947C423.808 181.686 424.099 182.325 424.592 182.358C425.085 182.392 425.533 181.832 425.6 181.093C425.667 180.354 425.298 179.715 424.805 179.682C424.312 179.648 423.864 180.208 423.808 180.947Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M425.006 179.726L423.45 178.976C423.45 178.976 424.144 180.387 425.006 179.726Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M414.613 180.074C414.557 180.813 414.904 181.44 415.397 181.485C415.89 181.53 416.349 180.958 416.405 180.208C416.461 179.458 416.102 178.842 415.61 178.797C415.117 178.752 414.669 179.334 414.613 180.074Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M415.811 178.853L414.254 178.091C414.254 178.091 414.949 179.514 415.811 178.853Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M424.245 174.72C424.962 174.672 425.68 174.789 426.344 175.063C427.008 175.337 427.601 175.76 428.075 176.299",
      "stroke": "#263238",
      "stroke-width": "0.768587",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M417.76 173.981C417.152 173.789 416.509 173.738 415.878 173.831C415.248 173.924 414.646 174.159 414.12 174.518",
      "stroke": "#263238",
      "stroke-width": "0.768587",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M434.784 183.881C434.784 183.881 430.965 176.232 431.917 170.542C431.917 170.542 414.434 162.254 412.552 180.353C412.552 180.353 411.69 164.136 427.224 164.673C442.758 165.211 441.448 187.869 433.07 194.197C433.07 194.152 437.136 188.664 434.784 183.881Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M433.205 185.707C433.355 184.668 433.91 183.731 434.75 183.101C435.59 182.471 436.645 182.2 437.685 182.347C440.462 182.806 441.482 188.63 434.448 189.627C433.44 189.739 432.914 188.731 433.205 185.707Z",
      "fill": "#EEC1BB"
    }
  }), _c('path', {
    attrs: {
      "d": "M424.782 188.239C424.099 188.913 423.289 189.445 422.399 189.804C421.509 190.164 420.557 190.344 419.597 190.333",
      "stroke": "#263238",
      "stroke-width": "0.768587",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M431.917 170.543C431.917 170.543 412.406 165.469 410.771 183.759",
      "stroke": "#263238",
      "stroke-width": "0.768587",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M414.512 212.341C414.512 212.341 413.022 227.17 415.464 240.666C415.464 240.666 421.221 219.811 429.307 212.722L420.011 214.054L414.512 212.341Z",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.5",
      "d": "M414.512 212.341C414.512 212.341 413.022 227.17 415.464 240.666C415.464 240.666 421.221 219.811 429.307 212.722L420.011 214.054L414.512 212.341Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M429.442 205.744L420 212.722V205.307C420 205.307 412.373 208.074 412.91 216.507L419.171 213.629V216.507L420.638 213.73L425.589 219.386C425.589 219.386 431.648 212.318 432.309 210.19C432.97 208.062 429.442 205.744 429.442 205.744Z",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.7",
      "d": "M429.442 205.744L420 212.722V205.307C420 205.307 412.373 208.074 412.91 216.507L419.171 213.629V216.507L420.638 213.73L425.589 219.386C425.589 219.386 431.648 212.318 432.309 210.19C432.97 208.062 429.442 205.744 429.442 205.744Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M439.298 271.175L430.629 229.813C430.539 229.38 430.303 228.991 429.96 228.712C429.618 228.433 429.189 228.28 428.747 228.279H402.08C401.884 228.279 401.69 228.322 401.512 228.405C401.334 228.488 401.177 228.609 401.051 228.76C400.925 228.911 400.834 229.087 400.783 229.276C400.732 229.466 400.724 229.665 400.758 229.858L407.982 270.357C408.057 270.794 408.281 271.191 408.616 271.481C408.951 271.771 409.376 271.936 409.819 271.947L438.01 272.787C438.206 272.783 438.4 272.736 438.576 272.648C438.753 272.561 438.908 272.436 439.031 272.282C439.154 272.129 439.242 271.95 439.288 271.758C439.334 271.567 439.337 271.367 439.298 271.175Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.1",
      "d": "M439.298 271.175L430.629 229.813C430.539 229.38 430.303 228.991 429.96 228.712C429.618 228.433 429.189 228.28 428.747 228.279H402.08C401.884 228.279 401.69 228.322 401.512 228.405C401.334 228.488 401.177 228.609 401.051 228.76C400.925 228.911 400.834 229.087 400.783 229.276C400.732 229.466 400.724 229.665 400.758 229.858L407.982 270.357C408.057 270.794 408.281 271.191 408.616 271.481C408.951 271.771 409.376 271.936 409.819 271.947L438.01 272.787C438.206 272.783 438.4 272.736 438.576 272.648C438.753 272.561 438.908 272.436 439.031 272.282C439.154 272.129 439.242 271.95 439.288 271.758C439.334 271.567 439.337 271.367 439.298 271.175Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M450.822 257.779L437.035 249.088C437.035 249.088 435.982 246.187 434.706 245.594C433.429 245 425.051 243.891 424.234 244.731C423.416 245.571 423.483 252.123 425.074 252.986C426.664 253.848 434.034 253.747 434.728 253.467L450.173 265.294L450.822 257.779Z",
      "fill": "#EEC1BB"
    }
  }), _c('path', {
    attrs: {
      "d": "M447.451 211.557C447.451 211.557 438.491 224.638 439.757 235.838L451.707 255.618L443.677 251.518L440.037 258.373C440.037 258.373 449.277 272.608 461.037 269.248C472.797 265.888 447.451 211.557 447.451 211.557Z",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.1",
      "d": "M447.451 211.557C447.451 211.557 438.491 224.638 439.757 235.838L451.707 255.618L443.677 251.518L440.037 258.373C440.037 258.373 449.277 272.608 461.037 269.248C472.797 265.888 447.451 211.557 447.451 211.557Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M444.517 251.138L439.947 259.638L438.917 257.208L442.557 250.298L444.517 251.138Z",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "d": "M427.84 228.279C426.787 228.279 426.787 255.83 426.787 255.83L402.707 255.136C402.707 255.136 399.997 231.84 402.707 228.256L427.84 228.279Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.1",
      "d": "M427.84 228.279C426.787 228.279 426.787 255.83 426.787 255.83L402.707 255.136C402.707 255.136 399.997 231.84 402.707 228.256L427.84 228.279Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M328.384 206.45L351.154 216.026C351.277 216.079 351.388 216.156 351.481 216.253C351.574 216.35 351.647 216.464 351.696 216.588C351.745 216.713 351.768 216.847 351.766 216.981C351.763 217.115 351.734 217.247 351.68 217.37C351.574 217.612 351.377 217.802 351.131 217.898C350.886 217.995 350.612 217.99 350.37 217.885L327.454 208.174L327.242 208.062L324.576 206.08L328.082 206.382C328.185 206.389 328.287 206.411 328.384 206.45Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M252.84 253.646L230.72 255.618L221.85 250.611C221.85 250.611 193.402 234.763 186.01 229.757C183.77 228.222 181.675 224.885 179.872 220.965C178.359 217.603 177.057 214.151 175.974 210.627C175.47 209.048 175.022 207.536 174.642 206.147C173.79 203.157 173.264 200.917 173.118 200.312C173.125 200.264 173.125 200.215 173.118 200.166H173.174L180.186 197.926L186.043 196.067C186.043 196.067 186.312 197.456 186.771 199.573L187.174 201.443C187.298 201.981 187.421 202.563 187.555 203.146C189.034 209.664 191.307 218.557 192.954 220.013C195.608 222.354 228.984 245.101 230.171 245.694C231.358 246.288 241.987 245.694 241.987 245.694L252.84 253.646Z",
      "fill": "#E4897B"
    }
  }), _c('path', {
    attrs: {
      "d": "M172.939 168.762C183.131 168.605 189.616 200.189 189.616 200.189L176.176 206.909C172.358 200.299 170.367 192.792 170.408 185.159C170.722 173.04 172.939 168.762 172.939 168.762Z",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.5",
      "d": "M172.939 168.762C183.131 168.605 189.616 200.189 189.616 200.189L176.176 206.909C172.358 200.299 170.367 192.792 170.408 185.159C170.722 173.04 172.939 168.762 172.939 168.762Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M191.106 200.805L187.477 203.157L182.739 206.226L175.93 210.627L174.709 211.422L173.23 206.931L174.597 206.192L175.493 205.699L181.72 202.306L186.693 199.584L189.179 198.229L189.616 197.994L191.106 200.805Z",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M183.344 211.422C183.365 214.924 182.115 218.314 179.827 220.965C178.314 217.604 177.012 214.151 175.93 210.627L174.709 211.422L173.23 206.942L174.597 206.203C173.746 203.213 173.219 200.973 173.074 200.368V200.189L174.686 187.869C176.803 191.049 178.61 194.425 180.085 197.949C180.667 199.326 181.205 200.794 181.698 202.317C181.869 202.84 182.026 203.377 182.168 203.93C182.381 204.691 182.56 205.464 182.717 206.237C183.081 207.943 183.291 209.679 183.344 211.422Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M171.416 458.483L180.79 459.48L182.549 439.264L173.174 438.267L171.416 458.483Z",
      "fill": "#E4897B"
    }
  }), _c('path', {
    attrs: {
      "d": "M124.656 457.8H133.728L134.478 436.766H125.395L124.656 457.8Z",
      "fill": "#E4897B"
    }
  }), _c('path', {
    attrs: {
      "d": "M134.994 456.747H123.962C123.781 456.75 123.607 456.814 123.467 456.927C123.327 457.041 123.229 457.199 123.189 457.375L121.397 465.461C121.359 465.633 121.356 465.811 121.387 465.984C121.419 466.158 121.484 466.323 121.58 466.471C121.676 466.619 121.8 466.746 121.946 466.846C122.091 466.946 122.255 467.015 122.427 467.051C122.524 467.068 122.622 467.068 122.718 467.051C126.291 467.051 128.89 466.783 133.37 466.783C136.136 466.783 144.469 467.063 148.277 467.063C152.085 467.063 152.589 463.299 151.021 462.952C144.021 461.429 138.701 459.312 136.461 457.352C136.062 456.978 135.54 456.763 134.994 456.747Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M135.621 458.013C135.577 458.002 135.537 457.978 135.507 457.944C135.477 457.91 135.458 457.867 135.453 457.822C135.448 457.783 135.454 457.742 135.472 457.706C135.49 457.67 135.519 457.641 135.554 457.621C135.878 457.408 138.813 455.605 139.821 456.131C139.935 456.181 140.032 456.265 140.098 456.371C140.164 456.477 140.196 456.6 140.19 456.725C140.213 456.934 140.184 457.145 140.106 457.341C140.028 457.536 139.903 457.709 139.742 457.845C139.231 458.198 138.614 458.364 137.995 458.315C137.195 458.305 136.398 458.203 135.621 458.013ZM136.237 457.71C137.298 458.071 138.456 458.01 139.474 457.542C139.58 457.446 139.662 457.325 139.712 457.191C139.763 457.057 139.781 456.912 139.765 456.77C139.765 456.602 139.686 456.523 139.619 456.523C139.538 456.477 139.444 456.457 139.35 456.467C138.242 456.677 137.185 457.099 136.237 457.71Z",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "d": "M135.52 458.08C135.488 458.057 135.462 458.027 135.444 457.992C135.426 457.957 135.418 457.917 135.419 457.878C135.419 457.755 135.654 455.145 136.707 454.294C136.829 454.184 136.973 454.102 137.13 454.053C137.286 454.005 137.452 453.991 137.614 454.014C137.777 454.013 137.935 454.068 138.061 454.17C138.188 454.272 138.275 454.415 138.309 454.574C138.488 455.537 136.674 457.531 135.744 458.046H135.643C135.606 458.069 135.563 458.081 135.52 458.08ZM136.976 454.541C136.325 455.354 135.934 456.346 135.856 457.385C136.797 456.68 137.95 455.145 137.838 454.574C137.838 454.518 137.838 454.361 137.525 454.361H137.424C137.274 454.373 137.13 454.427 137.01 454.518L136.976 454.541Z",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "d": "M181.776 456.467H170.744C170.565 456.468 170.391 456.529 170.25 456.641C170.11 456.752 170.012 456.908 169.971 457.083L168.179 465.17C168.106 465.517 168.173 465.879 168.366 466.177C168.559 466.475 168.863 466.685 169.21 466.76H169.512C173.074 466.693 175.672 466.48 180.163 466.48C182.918 466.48 191.251 466.771 195.059 466.771C198.867 466.771 199.371 462.997 197.814 462.65C190.814 461.126 185.494 459.021 183.254 457.05C182.849 456.681 182.323 456.474 181.776 456.467Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M182.403 457.722C182.359 457.711 182.32 457.687 182.29 457.653C182.26 457.619 182.241 457.576 182.235 457.531C182.229 457.49 182.237 457.448 182.257 457.412C182.277 457.375 182.309 457.347 182.347 457.33C182.672 457.117 185.606 455.314 186.603 455.84C186.716 455.893 186.811 455.977 186.876 456.082C186.942 456.187 186.976 456.31 186.973 456.434C186.996 456.643 186.968 456.855 186.889 457.05C186.811 457.246 186.686 457.419 186.525 457.554C186.025 457.917 185.418 458.102 184.8 458.08C183.99 458.052 183.186 457.932 182.403 457.722ZM183.019 457.419C184.08 457.773 185.234 457.717 186.256 457.263C186.363 457.167 186.445 457.046 186.496 456.912C186.547 456.777 186.564 456.633 186.547 456.49C186.547 456.311 186.469 456.232 186.413 456.232C186.327 456.186 186.23 456.166 186.133 456.176C185.026 456.391 183.97 456.813 183.019 457.419Z",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "d": "M182.347 457.767C182.314 457.747 182.287 457.719 182.267 457.685C182.248 457.652 182.237 457.615 182.235 457.576C182.235 457.442 182.47 454.832 183.523 453.981C183.645 453.871 183.789 453.789 183.946 453.74C184.102 453.692 184.268 453.679 184.43 453.701C184.595 453.701 184.754 453.758 184.88 453.862C185.007 453.966 185.094 454.111 185.125 454.272C185.315 455.224 183.501 457.229 182.56 457.733H182.459C182.426 457.755 182.387 457.766 182.347 457.767ZM183.803 454.228C183.147 455.038 182.756 456.032 182.683 457.072C183.613 456.367 184.766 454.832 184.654 454.272C184.654 454.205 184.654 454.048 184.341 454.048H184.24C184.079 454.06 183.926 454.122 183.803 454.228Z",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M173.174 438.267L182.549 439.264L181.63 449.691L172.256 448.695L173.174 438.267Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M125.395 436.778H134.478L134.086 447.63H125.003L125.395 436.778Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M129.136 252.247H177.296C181.877 277.279 196.224 365.837 185.931 444.741L169.613 442.714C169.613 442.714 171.125 337.96 156.722 299.242C155.064 319.962 153.093 342.015 151.39 353.752C147.235 382.626 138.264 445.76 138.264 445.76H121.654C121.654 445.76 125.014 385.347 126.616 356.955C128.33 326.133 129.08 258.575 129.136 252.247Z",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M156.8 299.242C159.174 306.031 160.976 313.007 162.187 320.096C163.531 301.65 158.144 282.094 158.144 282.094L156.8 299.242Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M187.846 448.538L167.653 445.536L168.269 439.947L189.269 442.702L187.846 448.538Z",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.6",
      "d": "M187.846 448.538L167.653 445.536L168.269 439.947L189.269 442.702L187.846 448.538Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M140.258 445.883H120.669V440.026L140.997 439.645L140.258 445.883Z",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.6",
      "d": "M140.258 445.883H120.669V440.026L140.997 439.645L140.258 445.883Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M129.046 260.624L129.002 253.647L178.125 250.611L178.472 257.376L129.046 260.624Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M141.478 251.877H139.261V262.102H141.478V251.877Z",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "d": "M175.426 248.481L173.214 248.642L173.958 258.84L176.169 258.679L175.426 248.481Z",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "d": "M178.08 250.611L129.002 254.24C129.002 249.939 128.89 245.146 128.565 240.083C127.96 230.731 126.806 220.494 125.518 210.896C122.786 190.501 119.437 172.995 119.437 172.995C142.285 167.227 172.939 168.728 172.939 168.728C180.902 198.565 178.08 250.611 178.08 250.611Z",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.6",
      "d": "M178.08 250.611L129.002 254.24C129.002 249.939 128.89 245.146 128.565 240.083C127.96 230.731 126.806 220.494 125.518 210.896C122.786 190.501 119.437 172.995 119.437 172.995C142.285 167.227 172.939 168.728 172.939 168.728C180.902 198.565 178.08 250.611 178.08 250.611Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M136.875 169.937C141.355 173.958 152.141 172.48 152.141 172.48C152.141 172.48 158.861 170.621 157.483 168.504C153.496 167.854 151.57 165.995 150.763 163.609C150.321 162.137 150.183 160.59 150.36 159.062C150.422 158.209 150.534 157.36 150.696 156.52L137.256 146.597C139.059 153.149 141.21 165.189 136.875 169.937Z",
      "fill": "#E4897B"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M140.403 152.902C140.93 160.944 147.538 163.162 150.774 163.61C150.332 162.137 150.195 160.59 150.371 159.062L140.403 152.902Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M163.52 127.49C166.779 130.581 165.424 140 158.782 144.816C152.141 149.632 152.141 126.336 152.141 126.336C152.141 126.336 161.448 125.518 163.52 127.49Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M139.496 154.762C140.889 156.823 142.92 158.37 145.277 159.166C147.634 159.961 150.187 159.96 152.544 159.163C155.609 158.192 158.188 156.089 159.757 153.283C159.978 152.894 160.18 152.494 160.362 152.085C163.632 144.48 164.64 131.04 156.587 126.045C153.822 124.312 150.483 123.747 147.302 124.473C144.121 125.2 141.358 127.159 139.619 129.92C138.591 131.559 137.958 133.415 137.771 135.341C136.786 144.256 135.778 149.475 139.496 154.762Z",
      "fill": "#E4897B"
    }
  }), _c('path', {
    attrs: {
      "d": "M150.808 140.101C150.64 140.807 150.965 141.467 151.446 141.546C151.928 141.624 152.398 141.098 152.566 140.426C152.734 139.754 152.398 139.059 151.939 138.981C151.48 138.903 150.942 139.373 150.808 140.101Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M158.973 141.49C158.805 142.195 159.141 142.867 159.6 142.946C160.059 143.024 160.563 142.498 160.72 141.826C160.877 141.154 160.552 140.448 160.082 140.37C159.611 140.291 159.096 140.807 158.973 141.49Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M156.318 141.4C156.804 143.74 157.61 146.001 158.715 148.12C158.158 148.392 157.549 148.537 156.929 148.547C156.31 148.557 155.696 148.43 155.131 148.176L156.318 141.4Z",
      "fill": "#DE5753"
    }
  }), _c('path', {
    attrs: {
      "d": "M148.725 137.021C148.801 137.043 148.882 137.047 148.96 137.031C149.038 137.016 149.111 136.981 149.173 136.931C149.555 136.55 150.022 136.264 150.536 136.096C151.05 135.929 151.596 135.885 152.13 135.968C152.184 135.981 152.241 135.984 152.296 135.975C152.351 135.967 152.404 135.947 152.452 135.918C152.5 135.889 152.542 135.851 152.575 135.806C152.608 135.76 152.632 135.709 152.645 135.655C152.669 135.545 152.65 135.43 152.591 135.334C152.533 135.238 152.44 135.168 152.331 135.139C151.662 135.016 150.973 135.053 150.32 135.248C149.668 135.442 149.071 135.789 148.579 136.259C148.501 136.341 148.458 136.449 148.458 136.562C148.458 136.674 148.501 136.783 148.579 136.864C148.617 136.925 148.666 136.979 148.725 137.021Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M153.317 151.76C152.257 151.743 151.218 151.469 150.287 150.962C149.357 150.455 148.563 149.729 147.974 148.848C147.958 148.822 147.948 148.794 147.943 148.764C147.938 148.734 147.939 148.704 147.947 148.674C147.954 148.645 147.967 148.618 147.986 148.594C148.004 148.57 148.027 148.549 148.053 148.534C148.102 148.504 148.161 148.495 148.218 148.507C148.274 148.52 148.323 148.554 148.355 148.602C148.919 149.404 149.662 150.063 150.525 150.528C151.388 150.992 152.348 151.249 153.328 151.278C153.386 151.278 153.441 151.3 153.483 151.34C153.524 151.38 153.549 151.434 153.552 151.491C153.549 151.547 153.526 151.599 153.487 151.639C153.447 151.678 153.395 151.701 153.339 151.704L153.317 151.76Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M162.96 139.272C163.035 139.316 163.12 139.339 163.206 139.339C163.314 139.305 163.404 139.233 163.46 139.135C163.516 139.037 163.534 138.922 163.509 138.813C163.335 138.152 163.004 137.543 162.544 137.038C162.083 136.532 161.508 136.146 160.866 135.912C160.759 135.88 160.644 135.892 160.545 135.944C160.447 135.997 160.373 136.086 160.339 136.192C160.307 136.3 160.319 136.417 160.371 136.517C160.423 136.617 160.512 136.694 160.619 136.73C161.121 136.93 161.568 137.245 161.926 137.65C162.284 138.054 162.542 138.537 162.68 139.059C162.709 139.113 162.749 139.159 162.797 139.196C162.845 139.233 162.901 139.258 162.96 139.272Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M146.171 125.373V129.36C145.467 129.597 144.878 130.09 144.521 130.742C144.164 131.394 144.065 132.156 144.245 132.877C145.107 136.349 142.61 141.254 139.821 142.957C139.821 142.957 139.91 141.512 138.701 141.702C137.491 141.893 136.875 149.408 138.802 153.25C138.802 153.25 135.621 149.598 134.568 141.512C133.582 134.03 134.098 127.96 135.923 125.339L146.171 125.373Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M132.664 141.926C133.304 144.1 134.778 145.932 136.763 147.022C139.406 148.445 141.389 146.227 141.243 143.394C141.064 140.862 139.496 137.01 136.562 136.786C133.627 136.562 131.902 139.25 132.664 141.926Z",
      "fill": "#E4897B"
    }
  }), _c('path', {
    attrs: {
      "d": "M134.12 132.16C134.12 132.16 131.354 125.518 138.6 120.646C145.846 115.774 163.038 121.453 164.248 125.406C165.458 129.36 152.454 136.539 134.12 132.16Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "d": "M138.566 165.805L149.531 171.808L151.032 163.61L158.715 168.605V179.077L150.584 174.227L144.872 182.784L133.594 170.397L138.566 165.805Z",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "d": "M163.471 250.896L154.838 251.374C154.121 251.414 153.573 252.027 153.612 252.743L153.935 258.581C153.975 259.297 154.588 259.846 155.305 259.806L163.938 259.328C164.654 259.288 165.203 258.675 165.163 257.959L164.84 252.121C164.8 251.405 164.187 250.856 163.471 250.896Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.5",
      "d": "M138.566 165.805L149.531 171.808L151.032 163.61L158.715 168.605V179.077L150.584 174.227L144.872 182.784L133.594 170.397L138.566 165.805Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M146.171 237.955C142.912 242.861 132.485 242.827 128.565 240.117C127.96 230.765 126.806 220.528 125.518 210.93L146.171 237.955Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M129.002 200.189C129.002 200.189 131.32 215.286 133.594 218.232C135.867 221.178 182.874 246.882 182.874 246.882L199.125 245.706L206.595 254.274C206.595 254.274 184.946 257.634 182.582 256.984C181.14 256.585 179.741 256.045 178.405 255.371C178.405 255.371 129.125 235.962 124.981 231.526C120.837 227.091 116.122 204.322 116.122 204.322L129.002 200.189Z",
      "fill": "#E4897B"
    }
  }), _c('path', {
    attrs: {
      "d": "M119.437 173.029C128.038 171.237 132.485 206.931 132.485 206.931L114.89 211.411C114.89 211.411 108.853 175.247 119.437 173.029Z",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.5",
      "d": "M119.437 173.029C128.038 171.237 132.485 206.931 132.485 206.931L114.89 211.411C114.89 211.411 108.853 175.247 119.437 173.029Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M113.859 210.56L133.594 205.834V209.227L114.744 214.693L113.859 210.56Z",
      "fill": "#0980FF"
    }
  }), _c('path', {
    attrs: {
      "d": "M181.44 433.44V279.91C181.441 273.266 179.405 266.781 175.605 261.33L170.24 253.646H265.552L270.917 261.33C274.717 266.781 276.753 273.266 276.752 279.91V431.984",
      "stroke": "#263238",
      "stroke-width": "6.72",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M383.992 467.096C389.442 467.096 393.859 462.678 393.859 457.229C393.859 451.779 389.442 447.362 383.992 447.362C378.543 447.362 374.125 451.779 374.125 457.229C374.125 462.678 378.543 467.096 383.992 467.096Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.4",
      "d": "M388.931 457.229C388.933 456.252 388.646 455.296 388.104 454.482C387.563 453.668 386.792 453.033 385.89 452.658C384.988 452.282 383.994 452.183 383.035 452.372C382.076 452.561 381.195 453.031 380.503 453.721C379.811 454.412 379.34 455.292 379.148 456.25C378.957 457.208 379.054 458.202 379.428 459.105C379.801 460.008 380.434 460.781 381.247 461.324C382.059 461.867 383.015 462.157 383.992 462.157C384.64 462.158 385.282 462.032 385.881 461.785C386.48 461.538 387.025 461.175 387.484 460.718C387.943 460.26 388.307 459.716 388.555 459.117C388.803 458.519 388.931 457.877 388.931 457.229Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M382.805 459.424C382.035 459.423 381.285 459.187 380.654 458.746C380.023 458.306 379.542 457.682 379.277 456.96L373.464 441.034H386.557V455.672C386.557 456.667 386.161 457.622 385.458 458.325C384.754 459.029 383.8 459.424 382.805 459.424Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M382.805 459.424C382.035 459.423 381.285 459.187 380.654 458.746C380.023 458.306 379.542 457.682 379.277 456.96L373.464 441.034H386.557V455.672C386.557 456.667 386.161 457.622 385.458 458.325C384.754 459.029 383.8 459.424 382.805 459.424Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M274.232 467.096C279.681 467.096 284.099 462.678 284.099 457.229C284.099 451.779 279.681 447.362 274.232 447.362C268.782 447.362 264.365 451.779 264.365 457.229C264.365 462.678 268.782 467.096 274.232 467.096Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.4",
      "d": "M279.171 457.229C279.173 456.252 278.886 455.296 278.344 454.482C277.803 453.668 277.032 453.033 276.13 452.658C275.228 452.282 274.234 452.183 273.275 452.372C272.316 452.561 271.435 453.031 270.743 453.721C270.052 454.412 269.58 455.292 269.389 456.25C269.197 457.208 269.294 458.202 269.668 459.105C270.041 460.008 270.674 460.781 271.487 461.324C272.299 461.867 273.255 462.157 274.232 462.157C274.88 462.158 275.522 462.032 276.121 461.785C276.72 461.538 277.265 461.175 277.724 460.718C278.183 460.26 278.547 459.716 278.795 459.117C279.043 458.519 279.171 457.877 279.171 457.229Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M273.045 459.424C272.275 459.423 271.525 459.187 270.894 458.746C270.263 458.306 269.782 457.682 269.517 456.96L263.704 441.034H276.797V455.672C276.797 456.667 276.401 457.622 275.698 458.325C274.994 459.029 274.04 459.424 273.045 459.424Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M273.045 459.424C272.275 459.423 271.525 459.187 270.894 458.746C270.263 458.306 269.782 457.682 269.517 456.96L263.704 441.034H276.797V455.672C276.797 456.667 276.401 457.622 275.698 458.325C274.994 459.029 274.04 459.424 273.045 459.424Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M400.96 429.554V438.872C400.96 439.707 400.628 440.507 400.038 441.098C399.448 441.688 398.648 442.019 397.813 442.019H177.251C176.417 442.019 175.616 441.688 175.026 441.098C174.436 440.507 174.104 439.707 174.104 438.872V429.554H400.96Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.4",
      "d": "M400.96 429.554V438.872C400.96 439.707 400.628 440.507 400.038 441.098C399.448 441.688 398.648 442.019 397.813 442.019H177.251C176.417 442.019 175.616 441.688 175.026 441.098C174.436 440.507 174.104 439.707 174.104 438.872V429.554H400.96Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M322.56 429.554V438.872C322.56 439.707 322.228 440.507 321.638 441.098C321.048 441.688 320.247 442.019 319.413 442.019H177.251C176.417 442.019 175.616 441.688 175.026 441.098C174.436 440.507 174.104 439.707 174.104 438.872V429.554H322.56Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M304.472 467.096C309.922 467.096 314.339 462.678 314.339 457.229C314.339 451.779 309.922 447.362 304.472 447.362C299.023 447.362 294.605 451.779 294.605 457.229C294.605 462.678 299.023 467.096 304.472 467.096Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.6",
      "d": "M309.411 457.229C309.413 456.252 309.126 455.296 308.584 454.482C308.043 453.668 307.272 453.033 306.37 452.658C305.468 452.282 304.474 452.183 303.515 452.372C302.556 452.561 301.675 453.031 300.983 453.721C300.292 454.412 299.82 455.292 299.629 456.25C299.437 457.208 299.534 458.202 299.908 459.105C300.281 460.008 300.914 460.781 301.727 461.324C302.539 461.867 303.495 462.157 304.472 462.157C305.12 462.158 305.762 462.032 306.361 461.785C306.961 461.538 307.505 461.175 307.964 460.718C308.423 460.26 308.787 459.716 309.035 459.117C309.283 458.519 309.411 457.877 309.411 457.229Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M303.285 459.424C302.515 459.423 301.765 459.187 301.134 458.746C300.503 458.306 300.022 457.682 299.757 456.96L293.944 441.034H307.037V455.672C307.037 456.667 306.641 457.622 305.938 458.325C305.234 459.029 304.28 459.424 303.285 459.424Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.4",
      "d": "M303.285 459.424C302.515 459.423 301.765 459.187 301.134 458.746C300.503 458.306 300.022 457.682 299.757 456.96L293.944 441.034H307.037V455.672C307.037 456.667 306.641 457.622 305.938 458.325C305.234 459.029 304.28 459.424 303.285 459.424Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M194.712 467.096C200.162 467.096 204.579 462.678 204.579 457.229C204.579 451.779 200.162 447.362 194.712 447.362C189.262 447.362 184.845 451.779 184.845 457.229C184.845 462.678 189.262 467.096 194.712 467.096Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.6",
      "d": "M199.651 457.229C199.653 456.252 199.366 455.296 198.824 454.482C198.283 453.668 197.512 453.033 196.61 452.658C195.708 452.282 194.714 452.183 193.755 452.372C192.796 452.561 191.915 453.031 191.223 453.721C190.532 454.412 190.06 455.292 189.868 456.25C189.677 457.208 189.774 458.202 190.148 459.105C190.521 460.008 191.154 460.781 191.967 461.324C192.779 461.867 193.735 462.157 194.712 462.157C195.36 462.158 196.002 462.032 196.601 461.785C197.2 461.538 197.745 461.175 198.204 460.718C198.663 460.26 199.027 459.716 199.275 459.117C199.523 458.519 199.651 457.877 199.651 457.229Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M193.525 459.424C192.755 459.423 192.005 459.187 191.374 458.746C190.743 458.306 190.262 457.682 189.997 456.96L184.184 441.034H197.277V455.672C197.277 456.667 196.882 457.622 196.178 458.325C195.474 459.029 194.52 459.424 193.525 459.424Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.4",
      "d": "M193.525 459.424C192.755 459.423 192.005 459.187 191.374 458.746C190.743 458.306 190.262 457.682 189.997 456.96L184.184 441.034H197.277V455.672C197.277 456.667 196.882 457.622 196.178 458.325C195.474 459.029 194.52 459.424 193.525 459.424Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M305.861 357.258H201.421V429.554H305.861V357.258Z",
      "fill": "#E19400"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.4",
      "d": "M305.861 357.258H201.421V429.554H305.861V357.258Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M249.883 357.258H201.421V429.554H249.883V357.258Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M245.302 414.266H239.243C238.674 414.266 238.213 414.727 238.213 415.296V423.573C238.213 424.142 238.674 424.603 239.243 424.603H245.302C245.871 424.603 246.333 424.142 246.333 423.573V415.296C246.333 414.727 245.871 414.266 245.302 414.266Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M234.371 414.266H228.312C227.743 414.266 227.282 414.727 227.282 415.296V423.573C227.282 424.142 227.743 424.603 228.312 424.603H234.371C234.94 424.603 235.402 424.142 235.402 423.573V415.296C235.402 414.727 234.94 414.266 234.371 414.266Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.4",
      "d": "M283.405 357.258H277.502V364.795H283.405V357.258Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M299.544 388.125H258.888V397.578H299.544V388.125Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M218.478 361.021H204.579V374.92H218.478V361.021Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M316.523 284.961H212.083V357.257H316.523V284.961Z",
      "fill": "#E19400"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.4",
      "d": "M316.523 284.961H212.083V357.257H316.523V284.961Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M260.546 284.961H212.083V357.257H260.546V284.961Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M255.976 341.969H249.917C249.348 341.969 248.886 342.431 248.886 343V351.277C248.886 351.846 249.348 352.307 249.917 352.307H255.976C256.545 352.307 257.006 351.846 257.006 351.277V343C257.006 342.431 256.545 341.969 255.976 341.969Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M245.045 341.969H238.986C238.417 341.969 237.955 342.431 237.955 343V351.277C237.955 351.846 238.417 352.307 238.986 352.307H245.045C245.614 352.307 246.075 351.846 246.075 351.277V343C246.075 342.431 245.614 341.969 245.045 341.969Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.4",
      "d": "M294.067 284.961H288.165V292.499H294.067V284.961Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M310.206 315.829H269.55V325.282H310.206V315.829Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M229.152 288.725H215.253V302.624H229.152V288.725Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M380.453 284.961H276.013V357.257H380.453V284.961Z",
      "fill": "#E19400"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.4",
      "d": "M380.453 284.961H276.013V357.257H380.453V284.961Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M324.475 284.961H276.013V357.257H324.475V284.961Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M319.906 341.969H313.846C313.277 341.969 312.816 342.431 312.816 343V351.277C312.816 351.846 313.277 352.307 313.846 352.307H319.906C320.475 352.307 320.936 351.846 320.936 351.277V343C320.936 342.431 320.475 341.969 319.906 341.969Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M308.974 341.969H302.915C302.346 341.969 301.885 342.431 301.885 343V351.277C301.885 351.846 302.346 352.307 302.915 352.307H308.974C309.544 352.307 310.005 351.846 310.005 351.277V343C310.005 342.431 309.544 341.969 308.974 341.969Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.4",
      "d": "M357.997 284.961H352.094V292.499H357.997V284.961Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M374.136 315.829H333.48V325.282H374.136V315.829Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M293.082 288.725H279.182V302.624H293.082V288.725Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M344.333 212.666H239.893V284.962H344.333V212.666Z",
      "fill": "#E19400"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M288.355 212.666H239.893V284.962H288.355V212.666Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M283.774 269.674H277.715C277.146 269.674 276.685 270.135 276.685 270.704V278.981C276.685 279.55 277.146 280.011 277.715 280.011H283.774C284.343 280.011 284.805 279.55 284.805 278.981V270.704C284.805 270.135 284.343 269.674 283.774 269.674Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M272.854 269.674H266.795C266.226 269.674 265.765 270.135 265.765 270.704V278.981C265.765 279.55 266.226 280.011 266.795 280.011H272.854C273.423 280.011 273.885 279.55 273.885 278.981V270.704C273.885 270.135 273.423 269.674 272.854 269.674Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.4",
      "d": "M321.877 212.666H315.974V220.203H321.877V212.666Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M338.016 243.533H297.36V252.986H338.016V243.533Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M256.95 216.429H243.051V230.328H256.95V216.429Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M372.322 357.258H267.882V429.554H372.322V357.258Z",
      "fill": "#263238"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M372.322 357.258H267.882V429.554H372.322V357.258Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M316.344 357.258H267.882V429.554H316.344V357.258Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M311.763 414.266H305.704C305.135 414.266 304.674 414.727 304.674 415.296V423.573C304.674 424.142 305.135 424.603 305.704 424.603H311.763C312.332 424.603 312.794 424.142 312.794 423.573V415.296C312.794 414.727 312.332 414.266 311.763 414.266Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M300.832 414.266H294.773C294.204 414.266 293.742 414.727 293.742 415.296V423.573C293.742 424.142 294.204 424.603 294.773 424.603H300.832C301.401 424.603 301.862 424.142 301.862 423.573V415.296C301.862 414.727 301.401 414.266 300.832 414.266Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.4",
      "d": "M349.866 357.258H343.963V364.795H349.866V357.258Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M366.005 388.125H325.349V397.578H366.005V388.125Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M284.939 361.021H271.04V374.92H284.939V361.021Z",
      "fill": "black"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }