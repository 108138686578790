<template>
    <div>
        <LineChartGenerator
            :options="chartOptions"
            :data="preparedData"
            :height="height"
            :id="chartId"
        />
    </div>
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs";
import Base from "./base";
import utils from "@/utils.js";

const BACKGROUND_COLORS = [
    "#36a2eb",
    "#f67019",
    "#f53794",
    "#537bc4",
    "#acc236",
    "#4dc9f6",
    "#166a8f",
    "#00a950",
    "#58595b",
    "#8549ba",
];

export default {
    extends: Base,
    components: { LineChartGenerator },
    props: {
        fill: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        preparedDisplayLegend() {
            return !!this.datasets && this.datasets.length > 1;
        },
        preparedData() {
            let chartData = JSON.parse(JSON.stringify(this.chartData));
            chartData.datasets.map((dataset, idx) => {
                dataset.pointStyle =
                    ((dataset.data && dataset.data) || []).length > 50
                        ? "line"
                        : null;
                dataset.label = dataset.label || "";
                dataset.borderColor = BACKGROUND_COLORS[idx];
                dataset.backgroundColor = BACKGROUND_COLORS[idx] + "10";
                dataset.fill = this.fill && chartData.length == 1;
                dataset.cubicInterpolationMode = "monotone";
                dataset.tension = 1;
            });
            return chartData;
        },
    },
    methods: {
        getLabel(tooltipItems) {
            return `${tooltipItems.dataset.label}: ${utils.formatNumberToString(
                tooltipItems.raw,
                this.unit
            )}`;
        },
    },
    data() {
        return {
            charttype: "line",
        };
    },
};
</script>
