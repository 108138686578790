var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "listitem",
    on: {
      "click": function click($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm._t("listitem", null, {
    "scope": _vm.item
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }