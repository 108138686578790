import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    orderitem: {},
    orderitem_saving: false,
    orderitem_saving_errors: {},
};

const mutations = {
    updateOrderItemSaving(state, saving) {
        state.orderitem_saving = saving;
    },
    updateOrderItemSavingErrors(state, errors) {
        state.orderitem_saving_errors = errors;
    },

    updateOrderItem(state, orderitem) {
        state.orderitem = orderitem;
        state.orderitem_saving_errors = [];
    },

};
const actions = {

    async saveOrderItem({ commit, rootGetters, state }, params) {
        if (rootGetters['session/current_user_permissions'].indexOf("order.add_orderitem") === -1 && !state.orderitem.id) {
            return;
        }
        if (rootGetters['session/current_user_permissions'].indexOf("order.change_orderitem") === -1 && state.orderitem.id) {
            return;
        }

        commit('updateOrderItemSaving', true);
        commit('updateOrderItemSavingErrors', {});

        let url = '/api/customerorderitems/';
        let method = axios.post;

        if (state.orderitem.id) {
            url = `/api/customerorderitems/${state.orderitem.id}/`;
            method = axios.put;
        }
        try {
            const response = await axios.put(`/api/customerorderitems/${state.orderitem.id}/count/`, { count: state.orderitem.count });
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateOrderItemSavingErrors', error.details);
            throw error;
        } finally {
            commit('updateOrderItemSaving', false);
        }
    },
};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
