<template>
    <div>
        <Doughnut
            :data="preparedData"
            :options="chartOptions"
            :height="height"
            :width="height"
            :id="chartId"
        />
    </div>
</template>


<script>
import { Doughnut } from "vue-chartjs";
import Base from "./base";
import utils from "@/utils.js";

export default {
    extends: Base,
    components: { Doughnut },
    props: {
        displayXAxes: {
            type: Boolean,
            default: false,
        },
        displayYAxes: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        preparedData() {
            if (!this.chartData || !this.chartData.datasets) {
                return {};
            }
            let chartData = JSON.parse(JSON.stringify(this.chartData));
            chartData.datasets.map((dataset) => {
                dataset.label = dataset.label || "";
                dataset.backgroundColor = [
                    "#4dc9f6",
                    "#f67019",
                    "#f53794",
                    "#537bc4",
                    "#acc236",
                    "#166a8f",
                    "#00a950",
                    "#58595b",
                    "#8549ba",
                    "#4dc9f6",
                    "#f67019",
                    "#f53794",
                    "#537bc4",
                    "#acc236",
                    "#166a8f",
                    "#00a950",
                    "#58595b",
                    "#8549ba",
                ];
                dataset.fill = true;
                dataset.cubicInterpolationMode = "monotone";
                dataset.tension = 0.4;
            });
            return chartData;
        },
    },
    methods: {
        getLabel(tooltipItems, data) {
            // return `${tooltipItems.label}: ${tooltipItems.raw} ${
            //     this.unit || ""
            // }`;

            return ` ${
                tooltipItems.label || tooltipItems.dataset.label
            }: ${utils.formatNumberToString(tooltipItems.raw, this.unit)}`;
        },
    },
    data() {
        return {
            charttype: "doughnut",
        };
    },
};
</script>
