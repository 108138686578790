import qs from 'qs';
import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const createState = () => ({
    billingaccounts: [],
    billingaccounts_count: 0,
    billingaccounts_loading: false,
    billingaccounts_loading_errors: null,
    billingaccounts_filters: { offset: 0, limit: 20, },
});


const mutations = {

    updateBillingAccounts(state, billingaccounts) {
        state.billingaccounts = billingaccounts;
    },
    updateBillingAccountsCount(state, count) {
        state.billingaccounts_count = count;
    },
    updateBillingAccountsLoading(state, loading) {
        state.billingaccounts_loading = loading;
    },
    updateBillingAccountsLoadingErrors(state, errors) {
        state.billingaccounts_loading_errors = errors;
    },
    updateBillingAccountsFilters(state, filters) {
        state.billingaccounts_filters = filters;
    },
};


const actions = {

    async setBillingAccountAsDefault({ commit, dispatch, state, rootGetters }) {
        if (rootGetters['session/current_user_permissions'].indexOf("billing.change_billingaccount") === -1) {
            return;
        }

        const url = `/api/billingaccounts/${state.billingaccount.id}/default/`;
        try {
            const response = await axios.post(url);
            dispatch('fetchBillingAccount');
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        }
    },

    async fetchBillingAccounts({ commit, state, rootGetters }) {
        if (rootGetters['session/current_user_permissions'].indexOf("billing.view_billingaccount") === -1) {
            return;
        }

        commit('updateBillingAccountsLoading', true);
        commit('updateBillingAccountsLoadingErrors', null);

        try {
            const response = await axios.get('/api/billingaccounts/', { params: state.billingaccounts_filters });
            commit('updateBillingAccountsLoading', false);
            commit('updateBillingAccounts', response.data.results);
            commit('updateBillingAccountsCount', response.data.count);
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateBillingAccountsLoadingErrors', error.details);
            commit('updateBillingAccountsLoading', false);
            throw error;
        }
    },

    init({ commit, state, rootGetters }, params) {
        commit('updateBillingAccountsFilters', Object.assign({}, state.billingaccounts_filters, params.filters));
    },

};

export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});