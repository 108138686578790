import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import qs from 'qs';

const state = {
    stocks: [],
};

const mutations = {
    updateStocks(state, stocks) {
        state.stocks = stocks;
    },
};

const actions = {


    fetchStocks({ commit, dispatch, state }, params) {
        return new Promise((resolve, reject) => {
            axios.get('/api/stocks/'
            )
                .then((response) => {
                    commit('updateStocks', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                })
        });
    },

    printLabels({ commit, dispatch, state }, params) {
        const queryparams = qs.stringify(params.params, { arrayFormat: 'repeat' });
        window.open(`/forms/labels/${params.product}/?${queryparams}`);
    },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
