<template>
    <BarChartGenerator
        :options="chartOptions"
        :data="preparedData"
        :height="height"
        :id="chartId"
    />
</template>

<script>
import { Bar as BarChartGenerator } from "vue-chartjs";
import Base from "./base";
import utils from "@/utils.js";

const BACKGROUND_COLORS = [
    "#36a2eb",
    "#f67019",
    "#f53794",
    "#537bc4",
    "#acc236",
    "#4dc9f6",
    "#166a8f",
    "#00a950",
    "#58595b",
    "#8549ba",
];

export default {
    extends: Base,
    components: { BarChartGenerator },
    computed: {
        preparedDisplayLegend() {
            return !!this.datasets && this.datasets.length > 1;
        },
        preparedData() {
            let chartData = JSON.parse(JSON.stringify(this.chartData));
            chartData.datasets.map((dataset, idx) => {
                dataset.label = dataset.label || "";
                dataset.backgroundColor = BACKGROUND_COLORS[idx];
            });
            return chartData;
        },
    },
    methods: {
        getLabel(tooltipItems) {
            return `${tooltipItems.dataset.label}: ${utils.formatNumberToString(
                tooltipItems.raw,
                this.unit
            )}`;
        },
    },
    data() {
        return {
            charttype: "bar",
        };
    },
};
</script>
