import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import invitations from "./submodules/invitations";
import customers from "./submodules/customers";
import providers from "./submodules/providers";
import catalogs from "./submodules/catalogs";
import billingaccounts from './submodules/billingaccounts';

const state = {
    invitation: null,
    invitation_loading: false,
    invitation_saving: false,

    invitation_accepting: false,
    invitation_declining: false,

    existing_provider: null,
    existing_customer: null,
    selected_catalogs: [],
    billingaccount: null,
};

const mutations = {

    updateInvitation(state, invitation) {

        if (invitation && state.invitation && invitation.id == state.invitation.id) {
            state.invitation = invitation;
            return;
        }
        state.invitation = invitation;

        if (!invitation) {
            return;
        }
    },

    updateInvitationSaving(state, saving) {
        state.invitation_saving = saving;
    },
    updateInvitationLoading(state, loading) {
        state.invitation_loading = loading;
    },
    updateInvitationLoadingErrors(state, errors) {
        state.invitation_loading_errors = errors;
    },
    updateInvitationAccepting(state, accepting) {
        state.invitation_accepting = accepting;
    },
    updateInvitationDeclining(state, declining) {
        state.invitation_declining = declining;
    },
    updateExistingProvider(state, provider) {
        state.existing_provider = provider;
    },
    updateExistingCustomer(state, customer) {
        state.existing_customer = customer;
    },
    updateSelectedCatalogs(state, selected_catalogs) {
        state.selected_catalogs = selected_catalogs;
    },
    updateBillingAccount(state, billingaccount) {
        state.billingaccount = billingaccount;
    },
};

const actions = {

    async fetchInvitation({ commit, dispatch, state }, params) {

        let url = `/api/invitations/${state.invitation.id}/`;

        commit('updateInvitationLoading', true);

        try {
            const response = await axios.get(url, { params: { profile: "invitation" } });
            commit('updateInvitationLoading', false);
            commit('updateInvitation', response.data);
            dispatch('fetchBillingAccount');
            return response.data;
        } catch (xhr_error) {
            commit('updateInvitationLoading', false);
            throw utils.handleError(xhr_error);
        }
    },

    async deleteInvitation({ commit, dispatch, state }, params) {
        const url = `/api/invitations/${params.instance.id}/`;

        try {
            const response = await axios.delete(url);
            dispatch('fetchInvitation');
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        }
    },

    async acceptInvitation({ commit, dispatch, state }, params) {
        const url = `/api/invitations/${state.invitation.uuid}/accept/`;
        commit('updateInvitationAccepting', true);
        try {
            let data = {};
            if (state.invitation.provider && state.existing_customer) {
                data = { existing_customer: state.existing_customer };
            } else if (state.invitation.provider && !state.existing_customer) {
                data = { catalogs: state.selected_catalogs, billingaccount: state.billingaccount };
            } else if (state.invitation.customer && state.invitation.initiator_data.profile != 'operator' && state.existing_provider) {
                data = { existing_provider: state.existing_provider };
            }

            const response = await axios.post(url, data);
            return response.data;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        } finally {
            commit('updateInvitationAccepting', false);
        }
    },



    async declineInvitation({ commit, dispatch, state }, params) {
        const url = `/api/invitations/${state.invitation.uuid}/decline/`;
        commit('updateInvitationDeclining', true);
        try {
            const response = await axios.post(url);
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        } finally {
            commit('updateInvitationDeclining', false);
        }
    },


    init({ commit, dispatch, state }, params) {
        commit('updateInvitation', params.invitation);
        dispatch("invitations/init", { filters: { invitation: params.invitation.id } });

        if (!state.invitation.entity) {
            dispatch("invitations/fetchInvitations");
        }
        dispatch('customers/init', { filters: { limit: 1000, connected: false, ordering: 'name' } });
        dispatch('providers/init', { filters: { limit: 1000, connected: false, ordering: 'name' } });
        dispatch('catalogs/init', { filters: { limit: 1000, ordering: "name" } });
        dispatch('billingaccounts/init', { filters: { limit: 1000, ordering: "name" } });
        if (params.invitation.provider) {
            dispatch('customers/fetchCustomers');
            dispatch('catalogs/fetchCatalogs').then((response) => {
                commit('updateSelectedCatalogs', response.results.filter((catalog) => catalog.default).map((catalog) => catalog.id));
            });
            dispatch('billingaccounts/fetchBillingAccounts').then((response) => {
                const default_billingaccount = response.results.find((billingaccount) => billingaccount.default);
                commit('updateBillingAccount', default_billingaccount ? default_billingaccount.id : null);
            });
        } else {
            dispatch('providers/fetchProviders');
        }



    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        invitations: invitations(),
        customers: customers(),
        providers: providers(),
        catalogs: catalogs(),
        billingaccounts: billingaccounts(),
    }
};
