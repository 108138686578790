import axios from '@/plugins/axios';
import qs from 'qs';
import utils from '@/stores/utils';
import store from '@/stores/store';

const state = {
    section: null,
    providerorders: [],
    providerorders_filters: { limit: 20, offset: 0, ordering: '-order_id' },
    providerorders_count: 0,
    providerorders_loading: false,
    providerorders_loading_errors: null,
    providerorders_cancel_source: null,
    orders_stats: {},
    orders_stats_loaded: false,
    orders_stats_loading: false,
    orders_stats_loading_errors: null,
};

let getModeFilters = function (section, customer) {
    const filters = {
        exclude_readonly: false,
        customer: customer,
    };
    switch (section) {
        case 'drafts':
            filters.ready = false;
            filters.readonly = false;
            filters.exclude_readonly = false;
            break;
        case 'processing':
            filters.ready = true;
            filters.readonly = false;
            filters.exclude_readonly = true;
            break;
    }
    if (store.getters['session/current_entity_profile'] == 'customer') {
        filters.suborder = false;
    }
    return filters;

};

const mutations = {
    updateOrdersStats(state, orders_stats) {
        state.orders_stats = orders_stats;
        state.orders_stats_loaded = true;
    },
    updateOrdersStatsLoading(state, loading) {
        state.orders_stats_loading = loading;
    },
    updateOrdersStatsLoadingErrors(state, errors) {
        state.orders_stats_loading_errors = errors;
    },
    updateSection(state, mode) {
        state.providerorders = [];
        state.providerorders_count = 0;
        state.section = mode;

        state.providerorders_filters = {
            offset: 0,
            limit: state.providerorders_filters.limit || 20,
            search: state.providerorders_filters.search,
            ordering: state.providerorders_filters.ordering,
            customer: state.providerorders_filters.customer,
        };

        state.providerorders_filters = Object.assign({}, state.providerorders_filters, getModeFilters(state.section));
    },


    updateProviderOrders(state, providerorders) {
        state.providerorders = providerorders;
    },
    updateProviderOrdersCount(state, count) {
        state.providerorders_count = count;
    },
    updateProviderOrdersLoading(state, loading) {
        state.providerorders_loading = loading;
    },
    updateProviderOrdersLoadingErrors(state, errors) {
        state.providerorders_loading_errors = errors;
    },
    updateProviderOrdersFilters(state, providerorders_filters) {
        state.providerorders_filters = Object.assign(
            {
                limit: state.providerorders_filters.limit || 20,
                ordering: state.providerorders_filters.ordering,
            },
            getModeFilters(state.section, state.provider),
            providerorders_filters);
    },

    updateProviderOrdersCancelSource(state, source) {
        state.providerorders_cancel_source = source;
    },

};

const actions = {
    changeSection({ commit, dispatch, state }, mode) {
        if (mode == state.section) {
            return;
        }
        commit('updateSection', mode);
        dispatch('fetchProviderOrders');
        dispatch('session/fetchStats', null, { root: true });
    },

    exportProviderOrdersAsCSV({ commit, dispatch, state }, params) {
        let filters = {};
        if (params.filtered) {
            filters = Object.assign({}, state.providerorders_filters);
            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 10000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/providerorders/?" + queryparams);
    },

    exportProviderOrderItemsAsCSV({ commit, dispatch, state }, params) {
        let filters = {};
        if (params.filtered) {
            for (let key in state.providerorders_filters) {
                filters['order_' + key] = state.providerorders_filters[key];
            }

            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 10000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/providerorderitems/?" + queryparams);
    },

    async fetchProviderOrders({ commit, dispatch, state }, params) {
        commit('updateProviderOrdersLoading', true);
        commit('updateProviderOrdersLoadingErrors', null);

        if (state.providerorders_cancel_source) {
            state.providerorders_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit('updateProviderOrdersCancelSource', cancelSource);

        try {
            const response = await axios.get('/api/providerorders/', {
                params: state.providerorders_filters,
                cancelToken: state.providerorders_cancel_source?.token,
            });
            commit('updateProviderOrdersLoading', false);
            commit('updateProviderOrders', response.data.results);
            commit('updateProviderOrdersCount', response.data.count);
            commit('updateProviderOrdersCancelSource', null);
            dispatch('fetchOrdersStats');
            return response;
        } catch (xhr_error) {
            if (axios.isCancel(xhr_error)) {
                return;
            }
            const error = utils.handleError(xhr_error);
            commit('updateProviderOrdersLoadingErrors', error.details);
            commit('updateProviderOrdersLoading', false);
            commit('updateProviderOrdersCancelSource', null);
            return Promise.reject(error);
        }
    },


    async assignProviderOrder({ commit, dispatch, state }, params) {
        const url = `/api/providerorders/${params.order.id}/assign/`;

        try {
            const response = await axios.put(url, { user: params.user });
            dispatch('fetchProviderOrders');
            dispatch('session/fetchStats', null, { root: true });
            return response;
        } catch (xhr_error) {
            return Promise.reject(utils.handleError(xhr_error));
        }
    },


    async fetchOrdersStats({ commit, dispatch, state }, params) {
        if (store.getters['session/current_user_permissions'].indexOf("order.view_order") == -1) {
            return;
        }

        commit('updateOrdersStatsLoading', true);
        commit('updateOrdersStatsLoadingErrors', null);

        try {
            const response = await axios.get('/api/providerorders/stats/', { params: state.providerorders_filters });
            commit('updateOrdersStatsLoading', false);
            commit('updateOrdersStats', response.data);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateOrdersStatsLoadingErrors', error.details);
            commit('updateOrdersStatsLoading', false);
            throw error;
        }
    },

    async init({ commit, dispatch, state }, params) {
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
