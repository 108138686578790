import synchronize from './submodules/synchronize';


const state = {
};

const mutations = {
};

const actions = {
    init({ commit, dispatch, state }, params) {
        dispatch("synchronize/init");
    },
};

export default {
    namespaced: true,
    state,

    actions,
    mutations,
    modules: {
        synchronize: synchronize(),
    }
};
