var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fileselect"
  }, [_c('input', {
    key: _vm.trynumber,
    ref: "fileinput",
    attrs: {
      "type": "file",
      "accept": _vm.accept
    },
    on: {
      "input": _vm.handleFileChange,
      "change": _vm.handleFileChange
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }