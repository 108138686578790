import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const createState = () => ({
    providers: [],
    providers_url: '/api/providers/',
    providers_filters: { offset: 0, limit: 20, },
    providers_count: 0,
    providers_loaded: false,
    providers_loading: false,
    providers_loading_errors: null,
});

const mutations = {
    updateProviders(state, providers) {
        state.providers = providers;
        state.providers_loaded = true;
        state.providers_loading = false;
        state.providers_loading_errors = null;
    },
    updateProvidersUrl(state, providers_url) {
        state.providers_url = providers_url;
    },
    updateProvidersFilters(state, providers_filters) {
        state.providers_filters = providers_filters;
    },
    updateProvidersLoading(state, providers_loading) {
        state.providers_loading = providers_loading;
    },
    updateProvidersLoadingErrors(state, providers_loading_errors) {
        state.providers_loading_errors = providers_loading_errors;
        state.providers_loading = false;
    },
    updateProvidersCount(state, providers_count) {
        state.providers_count = providers_count;
    },
};

const actions = {

    fetchProviders({ commit, dispatch, state }, params) {
        if (store.getters['session/current_user_permissions'].indexOf("core.view_provider") == -1) {
            return;
        }

        commit('updateProvidersLoading', true);
        commit('updateProvidersLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get(state.providers_url, { params: state.providers_filters })
                .then((response) => {
                    commit('updateProviders', response.data.results);
                    commit('updateProvidersCount', response.data.count);
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProvidersLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateProvidersLoading', false);
                });

        });
    },

    init({ commit, dispatch, state }, params) {
        commit('updateProviders', []);
        commit('updateProvidersFilters', params.filters || {});
        if (params.url) {
            commit('updateProvidersUrl', params.url);
        }
    },
};


export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});