const createState = () => ({
    message: null,
    receive_copy: false,
    recipients: [],
    save_message: false,
    subject: null,
    sending_invoice: false,
    sending_invoice_errors: {},
});

const getters = {
    ready: (state) => {
        return state.message && state.subject && state.recipients?.length;
    }
};

const mutations = {

    updateSendingInvoice(state, sending) {
        state.sending_invoice = sending;
        state.sending_invoice_errors = {};
    },
    updateSendingInvoiceErrors(state, sending_invoice_errors) {
        state.sending_invoice_errors = sending_invoice_errors;
        state.sending_invoice = false;
    },

    updateMessage(state, message) {
        state.message = message;
    },

    updateSubject(state, subject) {
        state.subject = subject;
    },

    updateRecipients(state, recipients) {
        state.recipients = recipients;
    },

    updateReceiveCopy(state, receive_copy) {
        state.receive_copy = receive_copy;
    },

    updateSaveMessage(state, save_message) {
        state.save_message = save_message;
    },
};


const actions = {

    init({ commit, dispatch, state }, params) {
        commit('updateMessage', params?.message);
        commit('updateSubject', params?.subject);
        commit('updateRecipients', params?.recipients || []);
        commit('updateReceiveCopy', !!params?.receive_copy);
        commit('updateSaveMessage', !!params?.save_message);
        commit('updateSendingInvoice', false);
    },
};


export default () => ({
    namespaced: true,
    state: createState(),
    getters,
    mutations,
    actions
});