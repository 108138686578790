var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-alert', {
    staticClass: "text-body-2 mb-4",
    attrs: {
      "border": "left",
      "colored-border": "",
      "color": _vm.defaultColor,
      "elevation": _vm.elevation,
      "icon": _vm.defaultIcon
    }
  }, [_c('div', {
    staticClass: "d-sm-flex d-block pb-1"
  }, [_c('div', {
    staticClass: "flex-grow-1 mb-2 pr-0 pr-sm-2"
  }, [_vm._t("default")], 2), _vm._t("button")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }