import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    producttypes: [],
    producttypes_count: 0,
    producttypes_loading: false,
    producttypes_loaded: false,
    producttypes_loading_errors: null,
    producttype_deleting: false,
    producttype_deleting_errors: null,
};

const mutations = {
    updateProductTypes(state, producttypes) {
        state.producttypes = producttypes;
    },
    updateProductTypesCount(state, count) {
        state.producttypes_count = count;
    },
    updateProductTypesLoading(state, loading) {
        state.producttypes_loading = loading;
    },
    updateProductTypesLoaded(state, loaded) {
        state.producttypes_loaded = loaded;
    },
    updateProductTypesLoadingErrors(state, errors) {
        state.producttypes_loading_errors = errors;
    },
    updateProductTypeDeleting(state, deleting) {
        state.producttype_deleting = deleting;
        state.producttype_deleting_errors = null;
    },
    updateProductTypeDeletingErrors(state, errors) {
        state.producttype_deleting_errors = errors;
        state.producttype_deleting = false;
    },
};


const actions = {

    fetchProductTypes({ commit, dispatch, state }, params) {
        commit('updateProductTypesLoading', true);
        commit('updateProductTypesLoadingErrors', null);

        return new Promise((resolve, reject) => {

            axios.get('/api/producttypes/', { params: { ordering: 'label' } })
                .then((response) => {
                    commit('updateProductTypes', response.data.results);
                    commit('updateProductTypesCount', response.data.count);
                    commit('updateProductTypesLoading', false);
                    commit('updateProductTypesLoaded', true);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProductTypesLoadingErrors', error.details);
                    commit('updateProductTypesLoading', false);
                    reject(error);
                });
        });
    },

    deleteProductType({ commit, dispatch, state }, params) {
        commit('updateProductTypeDeleting', true);
        commit('updateProductTypeDeletingErrors', null);

        const url = `/api/producttypes/${params.instance.id}/`;
        return new Promise((resolve, reject) => {
            axios.delete(url)
                .then((response) => {
                    commit('updateProductTypeDeleting', false);
                    dispatch('fetchProductTypes')
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProductTypeDeleting', false);
                    commit('updateProductTypeDeletingErrors', error.details);
                    reject(error);
                }).finally(() => {
                    dispatch('session/fetchStats', null, { root: true });
                });
        });
    },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
