import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const state = {
    deliverysites: [],
    deliverysites_count: 0,
    deliverysites_filters: { offset: 0, limit: 20, ordering: 'name' },
    deliverysites_loading: false,
    deliverysites_loading_errors: null,
};

const mutations = {
    updateDeliverySites(state, deliverysites) {
        state.deliverysites = deliverysites;
    },
    updateDeliverySitesCount(state, count) {
        state.deliverysites_count = count;
    },
    updateDeliverySitesFilters(state, filters) {
        state.deliverysites_filters = filters;
    },
    updateDeliverySitesLoading(state, loading) {
        state.deliverysites_loading = loading;
    },
    updateDeliverySitesLoadingErrors(state, errors) {
        state.deliverysites_loading_errors = errors;
    },
};

const actions = {


    fetchDeliverySites({ commit, dispatch, state }, params) {
        if (store.getters['session/current_user_permissions'].indexOf("core.view_deliverysite") == -1) {
            return;
        }

        commit('updateDeliverySitesLoading', true);
        commit('updateDeliverySitesLoadingErrors', null);

        return new Promise((resolve, reject) => {

            this.request = axios.get("/api/deliverysites/", { params: { ...state.deliverysites_filters } })
                .then((response) => {
                    commit('updateDeliverySites', response.data.results);
                    commit('updateDeliverySitesCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateDeliverySitesLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateDeliverySitesLoading', false);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
