var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    style: {
      width: _vm.width,
      height: _vm.height
    }
  }, [_vm.use == 'magnifier' ? _c('MagnifierSvg') : _vm.use == 'filter' ? _c('FilterSvg') : _vm.use == 'hide' ? _c('HideSvg') : _vm.use == 'menu' ? _c('MenuSvg') : _vm.use == 'arrow' ? _c('ArrowSvg') : _vm.use == 'cross' ? _c('CrossSvg') : _vm.use == 'smartphone' ? _c('SmartPhone') : _vm.use == 'telephone' ? _c('Telephone') : _vm.use == 'company' ? _c('Company') : _vm.use == 'city' ? _c('City') : _vm.use == 'address' ? _c('Address') : _vm.use === 'back-arrow' ? _c('BackArrow') : _vm.use == 'connect' ? _c('Connect') : _vm.use == 'signup' ? _c('Signup') : _vm.use == 'logo' ? _c('Logo') : _vm.use == 'logoxs' ? _c('LogoXs') : _vm.use == 'folder' ? _c('Folder') : _vm.use == 'noresult' ? _c('NoResult') : _vm.use == 'welcome' ? _c('Welcome') : _vm.use == 'nodata' ? _c('NoData') : _vm.use == 'start' ? _c('Start') : _c('div', [_vm._v("Did not find " + _vm._s(_vm.use))])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }