var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "version": "1.1",
      "viewBox": "0 -41 922.50011 922"
    }
  }, [_c('g', {
    attrs: {
      "id": "surface1"
    }
  }, [_c('path', {
    staticStyle: {
      "stroke": "none",
      "fill-rule": "nonzero",
      "fill": "rgb(0%, 0%, 0%)",
      "fill-opacity": "1"
    },
    attrs: {
      "d": "M 921.859375 786.230469 L 854.433594 381.621094 C 850.742188 359.070312 831.210938 342.535156 808.359375 342.613281 L 632.417969 342.613281 L 632.417969 373.734375 L 808.359375 373.734375 C 815.96875 373.734375 822.460938 379.238281 823.710938 386.742188 L 891.140625 791.351562 C 891.882812 795.859375 890.613281 800.472656 887.652344 803.960938 C 884.703125 807.453125 880.367188 809.460938 875.789062 809.46875 L 46.6875 809.46875 C 42.109375 809.460938 37.773438 807.453125 34.8125 803.960938 C 31.859375 800.472656 30.589844 795.859375 31.339844 791.351562 L 98.773438 386.742188 C 100.019531 379.238281 106.507812 373.734375 114.113281 373.734375 L 290.058594 373.734375 L 290.058594 342.613281 L 114.113281 342.613281 C 91.269531 342.554688 71.761719 359.074219 68.066406 381.621094 L 0.632812 786.230469 C -1.621094 799.765625 2.195312 813.609375 11.0625 824.082031 C 19.9375 834.546875 32.960938 840.59375 46.6875 840.59375 L 875.789062 840.59375 C 889.515625 840.597656 902.550781 834.5625 911.433594 824.089844 C 920.308594 813.621094 924.125 799.769531 921.859375 786.230469 Z M 921.859375 786.230469 "
    }
  }), _c('path', {
    staticStyle: {
      "stroke": "none",
      "fill-rule": "nonzero",
      "fill": "rgb(0%, 0%, 0%)",
      "fill-opacity": "1"
    },
    attrs: {
      "d": "M 461.242188 529.351562 C 465.65625 529.351562 469.863281 527.46875 472.8125 524.183594 C 480.609375 515.496094 663.542969 310.410156 663.542969 202.550781 C 663.542969 90.828125 572.972656 0.25 461.242188 0.25 C 349.507812 0.25 258.9375 90.828125 258.9375 202.550781 C 258.9375 310.410156 441.867188 515.496094 449.660156 524.183594 C 452.605469 527.46875 456.816406 529.351562 461.242188 529.351562 Z M 461.242188 31.375 C 555.722656 31.476562 632.308594 108.058594 632.417969 202.550781 C 632.417969 276.738281 518.023438 424.476562 461.242188 490.226562 C 404.457031 424.492188 290.058594 276.785156 290.058594 202.550781 C 290.167969 108.058594 366.746094 31.476562 461.242188 31.375 Z M 461.242188 31.375 "
    }
  }), _c('path', {
    staticStyle: {
      "stroke": "none",
      "fill-rule": "nonzero",
      "fill": "rgb(0%, 0%, 0%)",
      "fill-opacity": "1"
    },
    attrs: {
      "d": "M 570.171875 202.550781 C 570.171875 142.386719 521.394531 93.621094 461.242188 93.621094 C 401.078125 93.621094 352.304688 142.386719 352.304688 202.550781 C 352.304688 262.710938 401.078125 311.488281 461.242188 311.488281 C 521.363281 311.417969 570.101562 262.6875 570.171875 202.550781 Z M 383.429688 202.550781 C 383.429688 159.574219 418.261719 124.742188 461.242188 124.742188 C 504.203125 124.742188 539.046875 159.574219 539.046875 202.550781 C 539.046875 245.523438 504.203125 280.367188 461.242188 280.367188 C 418.285156 280.3125 383.472656 245.5 383.429688 202.550781 Z M 383.429688 202.550781 "
    }
  }), _c('path', {
    staticStyle: {
      "stroke": "none",
      "fill-rule": "nonzero",
      "fill": "rgb(0%, 0%, 0%)",
      "fill-opacity": "1"
    },
    attrs: {
      "d": "M 430.113281 560.476562 L 492.367188 560.476562 L 492.367188 591.601562 L 430.113281 591.601562 Z M 430.113281 560.476562 "
    }
  }), _c('path', {
    staticStyle: {
      "stroke": "none",
      "fill-rule": "nonzero",
      "fill": "rgb(0%, 0%, 0%)",
      "fill-opacity": "1"
    },
    attrs: {
      "d": "M 383.429688 560.476562 L 414.554688 560.476562 L 414.554688 591.601562 L 383.429688 591.601562 Z M 383.429688 560.476562 "
    }
  }), _c('path', {
    staticStyle: {
      "stroke": "none",
      "fill-rule": "nonzero",
      "fill": "rgb(0%, 0%, 0%)",
      "fill-opacity": "1"
    },
    attrs: {
      "d": "M 507.921875 560.476562 L 539.046875 560.476562 L 539.046875 591.601562 L 507.921875 591.601562 Z M 507.921875 560.476562 "
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }