var render = function render(){
  var _vm$items;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "infocard mb-4",
    attrs: {
      "elevation": _vm.elevation
    }
  }, [_vm.title ? _c('v-card-title', {
    staticClass: "pb-2 d-flex"
  }, [_c('div', {
    staticClass: "flex-grow-1"
  }, [_vm._v(_vm._s(_vm.title))]), _vm.extratitle ? _c('div', {
    staticClass: "font-italic font-weight-regular"
  }, [_vm._v(" " + _vm._s(_vm.extratitle) + " ")]) : _vm._e()]) : _vm._e(), _c('v-card-text', {
    staticClass: "pt-2"
  }, [_vm.error ? _c('div', [_vm._v(" " + _vm._s(_vm.$gettext("Error fetching information")) + " ")]) : !((_vm$items = _vm.items) !== null && _vm$items !== void 0 && _vm$items.length) ? _c('div', {
    staticClass: "d-flex flex-grow-1 align-center"
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-alert-octagon-outline")]), _vm._v(_vm._s(_vm.warning || _vm.$gettext("No data")) + " ")], 1) : _c('v-row', _vm._l(_vm.items, function (item, key) {
    return _c('v-col', {
      key: key,
      staticClass: "d-flex",
      attrs: {
        "cols": 12,
        "sm": _vm.sm,
        "md": _vm.md,
        "lg": _vm.lg
      }
    }, [_c('div', {
      staticClass: "d-flex align-center",
      "class": {
        borders: _vm.borders
      }
    }, [item.icon ? _c('div', {
      staticClass: "iconwrapper"
    }, [_c('v-icon', {
      attrs: {
        "size": "12",
        "color": "primary",
        "circle": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.iconName(item.icon) || item.icon) + " ")])], 1) : _vm._e(), _c('div', [_c('div', {
      staticClass: "infocard__title"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "infocard__text",
      "class": [item.color ? "".concat(item.color, "--text") : 'grey--text text--darken-2']
    }, [item.link ? [_c(item.bold ? 'b' : 'span', {
      tag: "component"
    }, [_vm.isDict(item.link) ? _c('router-link', {
      attrs: {
        "to": item.link
      }
    }, [_vm._v(" " + _vm._s(item.value || "-") + " ")]) : _c('a', {
      attrs: {
        "target": "_blank",
        "href": item.link
      }
    }, [_vm._v(" " + _vm._s(item.value || _vm.$gettext("Link")) + " ")])], 1)] : item.lookup ? [_c('Lookup', {
      attrs: {
        "object": item.lookup.object,
        "params": item.lookup.params
      }
    }, [_vm._v(_vm._s(item.value))])] : [item.value ? _vm._l(_vm.lines(item), function (line, count) {
      return _c(item.pre ? 'pre' : item.bold ? 'b' : 'span', {
        key: count,
        tag: "component",
        staticClass: "whitespace-pre-wrap"
      }, [count ? _c('br') : _vm._e(), line ? [_vm.isObject(line) ? [_c('span', [_vm._v(_vm._s(line.title))]), line.link ? [_vm._v(" - "), _c('span', {
        staticClass: "link",
        on: {
          "click": function click($event) {
            return _vm.$emit(line.link.event, line.link.event_data);
          }
        }
      }, [_vm._v(_vm._s(line.link.title))])] : _vm._e()] : [_c('span', {}, [_vm._v(_vm._s(line))])]] : _vm._e()], 2);
    }) : !item.action ? [_vm._v(" - ")] : _vm._e()], item.action ? [_vm.lines(item).length > 1 ? _c('br') : item.value ? [_vm._v(" -  ")] : _vm._e(), item.action ? _c('a', {
      staticClass: "text-primary",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.$emit(item.action);
        }
      }
    }, [_vm._v(_vm._s(item.action_text))]) : _vm._e()] : _vm._e()], 2)])])]);
  }), 1), _vm._t("default")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }