var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "viewBox": "-12 0 156.059 95.274",
      "width": "156.059",
      "height": "95.274",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:bx": "https://boxy-svg.com"
    }
  }, [_c('defs', [_c('clipPath', {
    attrs: {
      "clipPathUnits": "userSpaceOnUse",
      "id": "clipPath408"
    }
  }, [_c('path', {
    attrs: {
      "d": "M 0,500 H 500 V 0 H 0 Z",
      "id": "path406"
    }
  })]), _c('clipPath', {
    attrs: {
      "clipPathUnits": "userSpaceOnUse",
      "id": "clipPath424"
    }
  }, [_c('path', {
    attrs: {
      "d": "m 104.388,348.582 h 242.34 V 184.839 h -242.34 z",
      "id": "path422"
    }
  })])]), _c('g', {
    attrs: {
      "id": "g404",
      "clip-path": "url(#clipPath408)",
      "transform": "matrix(0.641793, 0, 0, -0.581851, -78.468567, 202.822739)",
      "bx:origin": "0 1"
    }
  }, [_c('g', {
    attrs: {
      "id": "g410",
      "transform": "translate(337.7109,184.8389)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#007cff",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "m 0,0 h -214.158 c -4.843,0 -9.092,3.963 -9.441,8.807 l -10.522,146.13 c -0.349,4.843 3.329,8.806 8.172,8.806 h 214.158 c 4.844,0 9.092,-3.963 9.441,-8.806 L 8.173,8.807 C 8.522,3.963 4.844,0 0,0",
      "id": "path412"
    }
  })]), _c('g', {
    attrs: {
      "id": "g414",
      "transform": "translate(338.532,184.8389)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#007cff",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "m 0,0 h -214.158 c -4.843,0 -9.092,3.963 -9.441,8.807 l -10.522,146.13 c -0.349,4.843 3.329,8.806 8.172,8.806 h 214.158 c 4.844,0 9.092,-3.963 9.441,-8.806 L 8.173,8.807 C 8.522,3.963 4.844,0 0,0",
      "id": "path416"
    }
  })]), _c('g', {
    attrs: {
      "id": "g418"
    }
  }, [_c('g', {
    attrs: {
      "id": "g420"
    }
  }), _c('g', {
    attrs: {
      "id": "g432"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clipPath424)",
      "opacity": "0.5",
      "id": "g430"
    }
  }, [_c('g', {
    attrs: {
      "transform": "translate(338.532,184.8389)",
      "id": "g428"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#ffffff",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "m 0,0 h -214.158 c -4.843,0 -9.092,3.963 -9.441,8.807 l -10.522,146.13 c -0.349,4.843 3.329,8.806 8.172,8.806 h 214.158 c 4.844,0 9.092,-3.963 9.441,-8.806 L 8.173,8.807 C 8.522,3.963 4.844,0 0,0",
      "id": "path426"
    }
  })])])])]), _c('g', {
    attrs: {
      "id": "g434",
      "transform": "translate(327.058,344.179)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#007cff",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "m 0,0 h -214.158 c -0.242,0 -0.481,-0.01 -0.717,-0.029 -5.475,-0.451 -4.356,-8.778 1.169,-8.778 H 0.817 c 5.525,0 5.444,8.327 -0.095,8.778 C 0.483,-0.01 0.242,0 0,0",
      "id": "path436"
    }
  })]), _c('g', {
    attrs: {
      "id": "g438",
      "transform": "translate(118.4779,339.7754)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#fafafa",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "m 0,0 c 0.08,-1.115 -0.759,-2.019 -1.874,-2.019 -1.115,0 -2.084,0.904 -2.164,2.019 -0.08,1.115 0.759,2.019 1.874,2.019 C -1.049,2.019 -0.08,1.115 0,0",
      "id": "path440"
    }
  })]), _c('g', {
    attrs: {
      "id": "g442",
      "transform": "translate(125.3458,339.7754)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#fafafa",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "m 0,0 c 0.08,-1.115 -0.759,-2.019 -1.874,-2.019 -1.115,0 -2.084,0.904 -2.164,2.019 -0.08,1.115 0.758,2.019 1.874,2.019 C -1.049,2.019 -0.08,1.115 0,0",
      "id": "path444"
    }
  })]), _c('g', {
    attrs: {
      "id": "g446",
      "transform": "translate(132.2137,339.7754)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#fafafa",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "m 0,0 c 0.08,-1.115 -0.759,-2.019 -1.874,-2.019 -1.115,0 -2.084,0.904 -2.164,2.019 -0.08,1.115 0.758,2.019 1.874,2.019 C -1.049,2.019 -0.08,1.115 0,0",
      "id": "path448"
    }
  })]), _c('g', {
    attrs: {
      "id": "g450",
      "transform": "translate(332.8542,199.4236)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#ffffff",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "m 0,0 h -204.903 c -1.763,0 -3.296,1.429 -3.423,3.193 l -8.653,120.172 c -0.127,1.764 1.2,3.193 2.963,3.193 H -9.113 c 1.763,0 3.295,-1.429 3.422,-3.193 L 2.963,3.193 C 3.09,1.429 1.763,0 0,0",
      "id": "path452"
    }
  })]), _c('g', {
    attrs: {
      "id": "g454",
      "transform": "translate(246.5298,245.2019)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#ffffff",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "m 0,0 -3.335,46.319 -10.107,6.084 H -41.247 L -37.473,0 Z",
      "id": "path456"
    }
  })]), _c('g', {
    attrs: {
      "id": "g458",
      "transform": "translate(209.5076,245.6865)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#007cff",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "m 0,0 h 36.501 l -3.28,45.549 -9.776,5.884 H -3.704 Z M 37.022,-0.969 H -0.451 c -0.254,0 -0.465,0.196 -0.483,0.449 l -3.774,52.403 c -0.009,0.134 0.037,0.267 0.129,0.365 0.092,0.099 0.22,0.155 0.355,0.155 H 23.58 c 0.088,0 0.174,-0.025 0.25,-0.07 L 33.937,46.25 c 0.135,-0.082 0.222,-0.224 0.233,-0.381 L 37.506,-0.45 c 0.009,-0.134 -0.037,-0.266 -0.129,-0.365 -0.092,-0.098 -0.22,-0.154 -0.355,-0.154",
      "id": "path460"
    }
  })]), _c('g', {
    attrs: {
      "id": "g462",
      "transform": "translate(243.1945,291.521)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#ececec",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "m 0,0 -10.107,6.083 3.711,-8.059 z",
      "id": "path464"
    }
  })]), _c('g', {
    attrs: {
      "id": "g466",
      "transform": "translate(234.1855,296.3779)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#007cff",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "m 0,0 2.875,-6.244 4.956,1.53 z m 2.613,-7.317 c -0.185,0 -0.36,0.106 -0.441,0.282 l -3.71,8.059 c -0.089,0.193 -0.042,0.422 0.117,0.564 0.158,0.141 0.391,0.163 0.573,0.054 L 9.259,-4.442 C 9.422,-4.54 9.513,-4.726 9.49,-4.916 9.467,-5.105 9.334,-5.263 9.152,-5.32 L 2.756,-7.296 C 2.708,-7.31 2.66,-7.317 2.613,-7.317",
      "id": "path468"
    }
  })]), _c('g', {
    attrs: {
      "id": "g470",
      "transform": "translate(220.9691,273.9944)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#007cff",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "m 0,0 c 0.084,-1.169 -0.795,-2.117 -1.965,-2.117 -1.169,0 -2.185,0.948 -2.269,2.117 -0.084,1.169 0.795,2.117 1.965,2.117 C -1.1,2.117 -0.084,1.169 0,0",
      "id": "path472"
    }
  })]), _c('g', {
    attrs: {
      "id": "g474",
      "transform": "translate(234.7048,273.9944)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#007cff",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "m 0,0 c 0.084,-1.169 -0.795,-2.117 -1.965,-2.117 -1.169,0 -2.185,0.948 -2.269,2.117 -0.084,1.169 0.795,2.117 1.965,2.117 C -1.1,2.117 -0.084,1.169 0,0",
      "id": "path476"
    }
  })]), _c('g', {
    attrs: {
      "id": "g478",
      "transform": "translate(238.5796,260.2649)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#007cff",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "m 0,0 c -0.252,0 -0.464,0.195 -0.483,0.45 -0.245,3.404 -5.583,6.173 -11.9,6.173 -4.185,0 -7.942,-1.245 -9.806,-3.248 -0.829,-0.89 -1.212,-1.851 -1.14,-2.855 0.019,-0.268 -0.181,-0.499 -0.448,-0.519 -0.268,-0.016 -0.499,0.182 -0.519,0.449 -0.091,1.265 0.392,2.505 1.398,3.585 2.041,2.194 6.07,3.557 10.515,3.557 C -5.454,7.592 0.198,4.485 0.484,0.52 0.503,0.252 0.302,0.021 0.035,0.001 0.023,0 0.012,0 0,0",
      "id": "path480"
    }
  })]), _c('g', {
    attrs: {
      "id": "g482",
      "transform": "translate(213.1672,278.2834)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#007cff",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "M 0,0 C -0.118,0 -0.236,0.043 -0.33,0.129 -0.526,0.312 -0.537,0.618 -0.355,0.814 L 1.768,3.102 C 1.951,3.299 2.258,3.309 2.453,3.128 2.649,2.946 2.661,2.639 2.479,2.443 L 0.355,0.155 C 0.26,0.052 0.13,0 0,0",
      "id": "path484"
    }
  })]), _c('g', {
    attrs: {
      "id": "g486",
      "transform": "translate(237.5842,278.2834)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#007cff",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "m 0,0 c -0.118,0 -0.237,0.043 -0.331,0.13 l -2.453,2.288 c -0.195,0.183 -0.206,0.489 -0.023,0.685 0.182,0.196 0.488,0.207 0.684,0.024 L 0.331,0.839 C 0.526,0.656 0.537,0.35 0.354,0.154 0.259,0.052 0.13,0 0,0",
      "id": "path488"
    }
  })]), _c('g', {
    attrs: {
      "id": "g490",
      "transform": "translate(202.257,234.8474)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#007cff",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "M 0,0 H 2.26 L 5.521,-4.334 5.209,0 H 7.491 L 8.055,-7.833 H 5.773 L 2.53,-3.532 2.84,-7.833 H 0.564 Z",
      "id": "path492"
    }
  })]), _c('g', {
    attrs: {
      "id": "g494",
      "transform": "translate(213.755,230.9148)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#007cff",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "M 0,0 C 0.057,-0.791 0.245,-1.359 0.564,-1.704 0.882,-2.05 1.295,-2.223 1.8,-2.223 c 0.52,0 0.911,0.17 1.171,0.508 0.261,0.339 0.36,0.946 0.297,1.822 C 3.214,0.844 3.027,1.383 2.705,1.723 2.383,2.063 1.967,2.233 1.458,2.233 0.97,2.233 0.591,2.061 0.32,1.715 0.049,1.37 -0.057,0.798 0,0 m -2.421,0.011 c -0.092,1.279 0.192,2.274 0.853,2.987 0.662,0.712 1.628,1.068 2.899,1.068 1.304,0 2.334,-0.35 3.089,-1.05 C 5.176,2.316 5.599,1.336 5.69,0.075 5.756,-0.841 5.656,-1.591 5.39,-2.177 5.124,-2.763 4.712,-3.219 4.153,-3.545 3.594,-3.871 2.88,-4.034 2.011,-4.034 c -0.883,0 -1.625,0.141 -2.224,0.422 -0.599,0.282 -1.1,0.727 -1.504,1.336 -0.403,0.609 -0.638,1.371 -0.704,2.287",
      "id": "path496"
    }
  })]), _c('g', {
    attrs: {
      "id": "g498",
      "transform": "translate(226.7004,233.0735)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#007cff",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "m 0,0 0.308,-4.28 h 0.593 c 0.506,0 0.862,0.056 1.067,0.169 0.206,0.112 0.359,0.308 0.46,0.587 0.101,0.28 0.129,0.733 0.084,1.36 -0.06,0.83 -0.236,1.398 -0.529,1.705 C 1.69,-0.153 1.231,0 0.604,0 Z M -2.548,1.774 H 1.048 C 1.757,1.774 2.336,1.678 2.786,1.485 3.236,1.293 3.617,1.017 3.928,0.657 4.239,0.297 4.475,-0.121 4.638,-0.598 4.801,-1.076 4.901,-1.582 4.94,-2.116 5,-2.953 4.951,-3.602 4.794,-4.063 4.637,-4.525 4.4,-4.911 4.084,-5.223 3.768,-5.534 3.42,-5.742 3.039,-5.845 2.518,-5.988 2.043,-6.059 1.612,-6.059 h -3.596 z",
      "id": "path500"
    }
  })]), _c('g', {
    attrs: {
      "id": "g502",
      "transform": "translate(236.8239,230.0012)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#007cff",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "M 0,0 -1.067,2.816 -1.72,0 Z m 0.624,-1.694 h -2.748 l -0.289,-1.293 h -2.471 l 2.38,7.833 h 2.64 L 3.643,-2.987 H 1.109 Z",
      "id": "path504"
    }
  })]), _c('g', {
    attrs: {
      "id": "g506",
      "transform": "translate(239.369,234.8474)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#007cff",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "M 0,0 H 7.357 L 7.496,-1.934 H 5.028 L 5.453,-7.833 H 3.032 L 2.608,-1.934 H 0.139 Z",
      "id": "path508"
    }
  })]), _c('g', {
    attrs: {
      "id": "g510",
      "transform": "translate(251.7413,230.0012)"
    }
  }, [_c('path', {
    staticStyle: {
      "fill": "#007cff",
      "fill-opacity": "1",
      "fill-rule": "nonzero",
      "stroke": "none"
    },
    attrs: {
      "d": "M 0,0 -1.067,2.816 -1.72,0 Z m 0.624,-1.694 h -2.748 l -0.289,-1.293 h -2.471 l 2.38,7.833 h 2.64 L 3.643,-2.987 H 1.109 Z",
      "id": "path512"
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }