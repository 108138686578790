var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', {
    ref: "input",
    attrs: {
      "value": _vm.inputValue,
      "single-line": _vm.singleLine,
      "clearable": _vm.clearable,
      "type": _vm.inputType,
      "label": _vm.label + (_vm.required ? ' (*)' : ''),
      "disabled": _vm.disabled,
      "format": _vm.format,
      "autofocus": _vm.focus,
      "step": _vm.step,
      "max": _vm.max,
      "min": _vm.min,
      "error": !!_vm.errors,
      "error-messages": _vm.errors,
      "hide-details": "auto",
      "autocomplete": "off",
      "placeholder": _vm.placeholder,
      "persistent-placeholder": _vm.persistentPlaceholder
    },
    on: {
      "keydown": function keydown($event) {
        return _vm.$emit('keydown', $event);
      },
      "input": function input($event) {
        return _vm.setValue($event);
      }
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }