import store from '@/stores/store';
import allocations from './submodules/allocations';

const state = {

};

const mutations = {

};

const actions = {

    async init({ commit, dispatch, state }, params) {
        dispatch('allocations/init', {
            filters: {},
        });
        if (store.getters['session/current_user_permissions'].indexOf("order.view_allocation") != -1) {
            dispatch('allocations/fetchAllocations');
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        allocations: allocations(),
    }
};
