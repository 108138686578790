<template>
    <div class="back-home bold pointer" @click="clicked">
        <MasterSvg use="back-arrow" />
        {{ text }}
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    props: {
        to: {
            type: Object,
            default: null,
        },
        text: {
            type: String,
            default: null,
        },
    },
    methods: {
        clicked() {
            if (this.to) {
                this.routerPush(this.to);
            } else {
                this.$emit("click");
            }
        },
    },
};
</script>

<style lang="scss">
.back-home {
    cursor: pointer;
    svg {
        margin-right: 10px;
    }
}
</style>
