import qs from 'qs';
import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const createState = () => ({
    url: '/api/catalogs/',
    catalogs: [],
    catalogs_count: 0,
    catalogs_loading: false,
    catalogs_loaded: false,
    catalogs_loading_errors: null,
    catalogs_filters: { offset: 0, limit: 20, },
});


const mutations = {

    updateCatalogs(state, catalogs) {
        state.catalogs = catalogs;
        state.catalogs_loaded = true;
    },
    updateCatalogsCount(state, count) {
        state.catalogs_count = count;
    },
    updateCatalogsLoading(state, loading) {
        state.catalogs_loading = loading;
        if (loading) {
            state.catalogs_loaded = false;
        }
    },
    updateCatalogsLoadingErrors(state, errors) {
        state.catalogs_loading_errors = errors;
    },
    updateCatalogsFilters(state, filters) {
        state.catalogs_filters = filters;
    },
    updateUrl(state, url) {
        state.url = url;
    },
};

const actions = {

    async setCatalogAsDefault({ commit, dispatch, state, rootGetters }) {
        if (rootGetters['session/current_user_permissions'].indexOf("catalog.change_catalog") === -1) {
            return;
        }

        const url = `${state.url}${state.catalog.id}/default/`;
        try {
            const response = await axios.post(url);
            dispatch('fetchCatalog');
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },

    async fetchCatalogs({ commit, state, rootGetters }) {
        if (rootGetters['session/current_user_permissions'].indexOf("catalog.view_catalog") === -1) {
            return;
        }

        commit('updateCatalogsLoading', true);
        commit('updateCatalogsLoadingErrors', null);
        try {
            const response = await axios.get(state.url, { params: state.catalogs_filters });
            commit('updateCatalogs', response.data.results);
            commit('updateCatalogsCount', response.data.count);
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateCatalogsLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateCatalogsLoading', false);
        }
    },

    init({ commit, state }, params) {
        commit('updateUrl', params.url || '/api/catalogs/');
        commit('updateCatalogs', []);
        commit('updateCatalogsFilters', { ...state.catalogs_filters, ...params.filters });
    },

};


export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});