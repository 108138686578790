<template>
    <v-card class="infocard mb-4" :elevation="elevation">
        <v-card-title class="pb-2 d-flex" v-if="title">
            <div class="flex-grow-1">{{ title }}</div>
            <div class="font-italic font-weight-regular" v-if="extratitle">
                {{ extratitle }}
            </div>
        </v-card-title>
        <v-card-text class="pt-2">
            <div v-if="error">
                {{ $gettext("Error fetching information") }}
            </div>
            <div class="d-flex flex-grow-1 align-center" v-else-if="!items?.length">
                <v-icon class="mr-2">mdi-alert-octagon-outline</v-icon
                >{{ warning || $gettext("No data") }}
            </div>
            <v-row v-else>
                <v-col
                    :cols="12"
                    :sm="sm"
                    :md="md"
                    :lg="lg"
                    v-for="(item, key) in items"
                    :key="key"
                    class="d-flex"
                >
                    <div class="d-flex align-center" :class="{ borders }">
                        <div class="iconwrapper" v-if="item.icon">
                            <v-icon size="12" color="primary" circle>
                                {{ iconName(item.icon) || item.icon }}
                            </v-icon>
                        </div>
                        <div>
                            <div class="infocard__title">
                                {{ item.title }}
                            </div>
                            <div
                                class="infocard__text"
                                :class="[
                                    item.color
                                        ? `${item.color}--text`
                                        : 'grey--text text--darken-2',
                                ]"
                            >
                                <template v-if="item.link">
                                    <component :is="item.bold ? 'b' : 'span'">
                                        <router-link
                                            v-if="isDict(item.link)"
                                            :to="item.link"
                                        >
                                            {{ item.value || "-" }}
                                        </router-link>
                                        <a target="_blank" v-else :href="item.link">
                                            {{ item.value || $gettext("Link") }}
                                        </a>
                                    </component>
                                </template>
                                <template v-else-if="item.lookup">
                                    <Lookup
                                        :object="item.lookup.object"
                                        :params="item.lookup.params"
                                        >{{ item.value }}</Lookup
                                    >
                                </template>

                                <template v-else>
                                    <template v-if="item.value">
                                        <component
                                            class="whitespace-pre-wrap"
                                            :is="
                                                item.pre
                                                    ? 'pre'
                                                    : item.bold
                                                    ? 'b'
                                                    : 'span'
                                            "
                                            :key="count"
                                            v-for="(line, count) in lines(item)"
                                        >
                                            <br v-if="count" />
                                            <template v-if="line">
                                                <template v-if="isObject(line)">
                                                    <span>{{ line.title }}</span>
                                                    <template v-if="line.link">
                                                        -
                                                        <span
                                                            class="link"
                                                            @click="
                                                                $emit(
                                                                    line.link.event,
                                                                    line.link.event_data
                                                                )
                                                            "
                                                            >{{ line.link.title }}</span
                                                        >
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <span class="">{{ line }}</span>
                                                </template>
                                            </template>
                                        </component>
                                    </template>
                                    <template v-else-if="!item.action"> - </template>
                                </template>
                                <template v-if="item.action">
                                    <br v-if="lines(item).length > 1" />
                                    <template v-else-if="item.value"
                                        >&nbsp;-&nbsp;
                                    </template>

                                    <a
                                        href="#"
                                        v-if="item.action"
                                        class="text-primary"
                                        @click.prevent="$emit(item.action)"
                                        >{{ item.action_text }}</a
                                    >
                                </template>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <slot />
        </v-card-text>
    </v-card>
</template>

<script>
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    props: {
        title: {
            type: String,
            required: false,
        },
        extratitle: {
            type: String,
            required: false,
        },
        warning: {
            type: String,
            required: false,
        },
        elevation: {
            type: [String, Number],
            required: false,
        },
        items: {
            type: Array,
            required: false,
        },
        cols: {
            type: Number,
            required: false,
            default: 12,
        },
        sm: {
            type: Number,
            required: false,
        },
        md: {
            type: Number,
            required: false,
        },
        lg: {
            type: Number,
            required: false,
        },
        xl: {
            type: Number,
            required: false,
        },
        borders: {
            type: Boolean,
            required: false,
            default: false,
        },
        error: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        isObject(obj) {
            return typeof obj === "object";
        },
        lines(item) {
            if (!item.value) {
                return [];
            }
            if (typeof item.value == "object") {
                return item.value;
            }
            return [item.value];
        },
        isDict(obj) {
            return typeof obj === "object";
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";
.col-6 > .infocard,
.col-12 > .infocard,
.col > .infocard {
    height: 100%;
}
.infocard {
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .borders {
        border: 1px solid red;
    }
    .iconwrapper {
        align-self: center;
    }
    .iconwrapper,
    .iconwrapper > .v-icon {
        margin-right: 12px;
        height: 22px;
        width: 22px;
    }
    .iconwrapper > .v-icon {
        border-radius: 11px;
        background-color: rgba($color-primary, 0.1);
    }

    .infocard__title {
        color: $color-gs-extrabold;
        line-height: 18px;
        font-size: 12px !important;

        font-weight: 600;
    }
    .v-card__text {
        .infocard__text {
            line-height: 18px;
            color: $color-gs-medium;
            font-size: 12px !important;
        }
    }
    .whitespace-pre-wrap {
        white-space: pre-wrap;
    }
}
</style>
