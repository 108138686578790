// Import necessary styles
import 'sweetalert2/dist/sweetalert2.min.css';
import 'sl-vue-tree/dist/sl-vue-tree-minimal.css';
import "viewerjs/dist/viewer.css";

// Import libraries and plugins
import Vue from 'vue';
import VueMq from 'vue-mq';
import VueSweetalert2 from 'vue-sweetalert2';
import VuePapaParse from 'vue-papa-parse';
import SlVueTree from 'sl-vue-tree';
import VueCookies from "vue-cookies";
import VueViewer from "v-viewer";
import 'chartjs-adapter-moment';
import {
    Chart as ChartJS,
    Title as ChartTitle,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    CategoryScale,
    LinearScale,
    TimeScale,
} from "chart.js";

// Import custom plugins
import Components from './components';
import posthogPlugin from "./posthog";
import Utils from './utils';
import utils from '@/utils.js';

// Add custom method to Array prototype
Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
};

// Register global Vue filter
Vue.filter('price', function (value) {
    return utils.price(value);
});

// Use Vue plugins
Vue.use(VueMq, {
    breakpoints: {
        sm: 576,
        md: 768,
        lg: 992,
        xl: Infinity,
    },
});
Vue.use(VueViewer);
Vue.use(VueCookies);
Vue.use(VuePapaParse);
Vue.use(VueSweetalert2);
Vue.use(Components);
Vue.use(Utils);
Vue.use(posthogPlugin);

// Register Vue component
Vue.component('sl-vue-tree', SlVueTree);

// Register Chart.js components
ChartJS.register(
    ChartTitle,
    Tooltip,
    Legend,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    TimeScale,
    LinearScale,
    CategoryScale
);


