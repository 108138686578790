import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const createState = () => ({
    catalog: {},
    catalogagentassignments: [],
    catalogagentassignments_count: 0,
    catalogagentassignments_filters: { offset: 0, limit: 20, ordering: 'agent_name' },
    catalogagentassignments_loaded: false,
    catalogagentassignments_loading: false,
    catalogagentassignments_loading_errors: null,
});


const mutations = {

    updateCatalog(state, catalog) {
        state.catalog = catalog;
        state.catalogagentassignments = [];
        state.catalogagentassignments_count = 0;
        state.catalogagentassignments_loaded = false;
        state.catalogagentassignments_loading = false;
        state.catalogagentassignments_filters = { offset: 0, limit: 20, catalog: catalog ? catalog.id : null, ordering: 'agent_name' };
    },
    updateCatalogAgentAssignments(state, catalogagentassignments) {
        state.catalogagentassignments = catalogagentassignments;
        state.catalogagentassignments_loaded = true;
        state.catalogagentassignments_loading = false;
    },
    updateCatalogAgentAssignmentsCount(state, count) {
        state.catalogagentassignments_count = count;
    },
    updateCatalogAgentAssignmentsLoading(state, loading) {
        state.catalogagentassignments_loading = loading;
    },
    updateCatalogAgentAssignmentsLoadingErrors(state, errors) {
        state.catalogagentassignments_loading_errors = errors;
    },
    updateCatalogAgentAssignmentDeleting(state, deleting) {
        state.catalog_deleting_assignment = deleting;
    },
    updateCatalogAgentAssignmentDeletingErrors(state, errors) {
        state.catalog_deleting_assignments_errors = errors;
    },
    updateCatalogAgentAssignmentsFilters(state, filters) {
        state.catalogagentassignments_filters = filters;
    },
};


const getters = {

};

const actions = {

    fetchCatalogAgentAssignments({ commit, dispatch, state }, params) {
        commit('updateCatalogAgentAssignmentsLoading', true);
        commit('updateCatalogAgentAssignmentsLoadingErrors', null);

        return new Promise((resolve, reject) => {

            axios.get("/api/catalogagentassignments/", { params: state.catalogagentassignments_filters })
                .then((response) => {
                    commit('updateCatalogAgentAssignments', response.data.results);
                    commit('updateCatalogAgentAssignmentsCount', response.data.count);
                    resolve(response.data.payload);

                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateCatalogAgentAssignmentsLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateCatalogAgentAssignmentsLoading', false);
                });
        });
    },

    deleteCatalogAgentAssignment({ commit, dispatch, state }, params) {
        commit('updateCatalogAgentAssignmentDeleting', true);
        commit('updateCatalogAgentAssignmentDeletingErrors', null);

        return new Promise((resolve, reject) => {

            const url = `/api/catalogagentassignments/${params.instance.id}/`;


            axios.delete(url)
                .then((response) => {
                    commit('updateCatalogAgentAssignmentDeleting', false);
                    resolve(response.data.payload);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateCatalogAgentAssignmentDeleting', false);
                    commit('updateCatalogAgentAssignmentDeletingErrors', error.details);
                    reject(error);
                })
        });
    },

    init({ commit, dispatch, state }, params) {
        commit('updateCatalog', params.catalog);
        commit('updateCatalogAgentAssignmentsFilters', Object.assign({}, state.catalogagentassignments_filters, params.filters || {}));
    }
};

export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});