import store from '@/stores/store';
import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    events: [],
    evwnts_filters: { offset: 0, limit: 20, },
    events_count: 0,
    events_errors: null,
    events_loading: false,
};

const mutations = {
    updateEvents(state, events) {
        state.events = events;
    },
    updateEventsLoading(state, loading) {
        state.events_loading = loading;
    },
    updateEventsCount(state, count) {
        state.events_count = count;
    },
    updateEventsErrors(state, errors) {
        state.events_errors = errors;
    },
    updateEventsFilters(state, filters) {
        state.events_filters = filters;
    },
};

const actions = {

    fetchEvents({ commit, dispatch, state }, params) {
        if (store.getters['session/current_user_permissions'].indexOf("core.view_event") == -1) {
            return;
        }
        commit('updateEventsLoading', true);

        return new Promise((resolve, reject) => {

            axios.get('/api/events/', { params: state.events_filters })
                .then((response) => {
                    commit('updateEvents', response.data.results);
                    commit('updateEventsCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateEventsErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateEventsLoading', false);
                });
        });
    },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
