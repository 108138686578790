var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-autocomplete', {
    ref: "autocomplete",
    attrs: {
      "value": _vm.option,
      "flat": "",
      "hide-details": "",
      "outlined": _vm.outlined,
      "dense": _vm.dense,
      "items": _vm.options,
      "item-title": _vm.itemTitle,
      "item-value": _vm.itemValue,
      "clearable": _vm.clearable && !_vm.required,
      "loading": _vm.loading,
      "label": _vm.required ? _vm.label + ' (*)' : _vm.label,
      "required": _vm.required,
      "hint": _vm.hint,
      "disabled": _vm.disabled,
      "menu-props": {
        closeOnClick: true
      },
      "error-messages": _vm.errorMessages,
      "autofocus": _vm.autofocus
    },
    on: {
      "input": _vm.selectOption
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn(scope) {
        return [_vm._t("label", null, {
          "props": scope
        })];
      }
    }, {
      key: "item",
      fn: function fn(scope) {
        return [_vm._t("item", function () {
          return [_vm._v(" " + _vm._s(scope.item[_vm.itemTitle || "text"]) + " ")];
        }, {
          "props": scope
        })];
      }
    }], null, true)
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }