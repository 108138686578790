import axios from "@/plugins/axios";
import utils from "@/stores/utils";
import products from "./submodules/products";

const state = {
    catalog: null,
    selected_items: [],
    adding_products: false,
};

const mutations = {
    updateCatalog(state, catalog) {
        state.catalog = catalog;
    },
    selectItem(state, item) {
        if (!state.selected_items.find((selected_item) => selected_item.id == item.id)) {
            state.selected_items.push(item);
        }
    },
    deselectItem(state, item) {
        state.selected_items = state.selected_items.filter((selected_item) => selected_item.id != item.id);
    },
    toggleItem(state, item) {
        if (!state.selected_items.find((selected_item) => selected_item.id == item.id)) {
            state.selected_items.push(item);
        } else {
            state.selected_items = state.selected_items.filter((selected_item) => selected_item.id != item.id);
        }
    },
    updateSelectedItems(state, items) {
        state.selected_items = items;
    },
    updateAddingProducts(state, adding) {
        state.adding_products = adding;
    },
};

const actions = {

    async importProducts({ commit, dispatch, state }, params) {
        commit("updateAddingProducts", true);
        try {
            const response = await axios.post(`/api/products/bulk/`, {
                action: "add_to_catalog", products: state.selected_items.map((item) => item.id),
                payload: { catalog: state.catalog.id }
            });
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        } finally {
            commit("updateAddingProducts", false);
        }
    },

    async init({ commit, dispatch, state }, params) {
        commit("updateCatalog", params.catalog);
        commit("updateSelectedItems", []);

        dispatch("products/init", { filters: { limit: 20, for_catalog: params.catalog.id } });
        dispatch("products/fetchProducts");
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        products: products(),
    },
};
