<template>
    <v-btn
        :color="ghost ? '' : color"
        :class="buttonClass"
        :style="{ justifyContent: align }"
        :small="small"
        :dense="dense"
        :loading="loading"
        :elevation="elevation"
        :disabled="disabled || loading"
        :size="size"
        @click="$emit('click', $event)"
    >
        <v-icon v-if="icon" class="mr-2" :color="iconColor">{{ iconName(icon) }}</v-icon>
        <span v-if="text" class="text-wrap">{{ text }}</span>
    </v-btn>
</template>

<script>
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    name: "Btn",
    props: {
        color: {
            type: String,
            default: "primary",
        },
        align: {
            type: String,
            default: "center",
        },
        elevation: {
            type: [String, Number],
            default: 2,
        },
        buttonClass: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        text: {
            type: String,
            required: false,
        },
        size: {
            type: String,
            required: false,
            default: "normal",
        },
        icon: {
            type: String,
            required: false,
        },
        ghost: {
            type: Boolean,
            required: false,
            default: false,
        },
        small: {
            type: Boolean,
            required: false,
            default: false,
        },
        dense: {
            type: Boolean,
            required: false,
            default: false,
        },
        iconColor: {
            type: String,
            required: false,
        },
    },
    methods: {
        focus() {
            this.$refs.button.focus();
        },
    },
};
</script>
