<template>
    <div :style="{ width, height }">
        <MagnifierSvg v-if="use == 'magnifier'" />
        <FilterSvg v-else-if="use == 'filter'" />
        <HideSvg v-else-if="use == 'hide'" />
        <MenuSvg v-else-if="use == 'menu'" />
        <ArrowSvg v-else-if="use == 'arrow'" />
        <CrossSvg v-else-if="use == 'cross'" />
        <SmartPhone v-else-if="use == 'smartphone'" />
        <Telephone v-else-if="use == 'telephone'" />
        <Company v-else-if="use == 'company'" />
        <City v-else-if="use == 'city'" />
        <Address v-else-if="use == 'address'" />
        <BackArrow v-else-if="use === 'back-arrow'" />
        <Connect v-else-if="use == 'connect'" />
        <Signup v-else-if="use == 'signup'" />
        <Logo v-else-if="use == 'logo'" />
        <LogoXs v-else-if="use == 'logoxs'" />
        <Folder v-else-if="use == 'folder'" />
        <NoResult v-else-if="use == 'noresult'" />
        <Welcome v-else-if="use == 'welcome'" />
        <NoData v-else-if="use == 'nodata'" />
        <Start v-else-if="use == 'start'" />
        <div v-else>Did not find {{ use }}</div>
    </div>
</template>

<script>
import MagnifierSvg from "@/assets/svg/common/magnifier.vue";
import FilterSvg from "@/assets/svg/common/filter.vue";
import HideSvg from "@/assets/svg/common/hide.vue";
import MenuSvg from "@/assets/svg/common/menu.vue";
import ArrowSvg from "@/assets/svg/common/arrow.vue";
import CrossSvg from "@/assets/svg/common/cross.vue";
import SmartPhone from "@/assets/svg/common/smartphone.vue";
import Telephone from "@/assets/svg/common/telephone.vue";
import Company from "@/assets/svg/common/company.vue";
import City from "@/assets/svg/common/city.vue";
import Address from "@/assets/svg/common/address.vue";
import BackArrow from "@/assets/svg/common/back-arrow.vue";
import Logo from "@/assets/svg/common/logo.vue";
import LogoXs from "@/assets/svg/common/logo-xs.vue";
import NoResult from "@/assets/svg/common/noresult.vue";
import Folder from "@/assets/svg/common/folder.vue";
import Signup from "@/assets/svg/illustration/signup.vue";
import Welcome from "@/assets/svg/illustration/welcome.vue";
import Connect from "@/assets/svg/illustration/connect.vue";
import NoData from "@/assets/svg/common/nodata.vue";
import Start from "@/assets/svg/common/start.vue";

export default {
    components: {
        Logo,
        LogoXs,
        MagnifierSvg,
        CrossSvg,
        FilterSvg,
        HideSvg,
        MenuSvg,
        ArrowSvg,
        SmartPhone,
        Telephone,
        Company,
        City,
        Address,
        BackArrow,
        NoResult,
        Folder,
        Signup,
        Connect,
        Welcome,
        NoData,
        Start,
    },
    props: {
        use: {
            type: String,
            required: true,
        },
        width: {
            type: Number,
            required: false,
        },
        height: {
            type: Number,
            required: false,
        },
    },
};
</script>
