import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import synchronize from './submodules/synchronize';
import store from '@/stores/store';


const state = {
    catalog: null,
};


const mutations = {

    updateCatalog(state, catalog) {
        state.catalog = catalog;
    },
};

const actions = {
    async init({ commit, dispatch, state }, params) {
        await dispatch("synchronize/init");
        commit('updateCatalog', params.catalog);
        commit('synchronize/updateExtraParams', { catalog: params.catalog.id });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        synchronize: synchronize(),
    }
};
