import axios from '@/plugins/axios';
import qs from 'qs';
import utils from '@/stores/utils';

const state = {
    customers: [],
    customers_count: 0,
    customers_filters: { offset: 0, limit: 20, ordering: 'name' },
    customers_loading: false,
    customers_loading_errors: null,
    customers_cancel_source: null,

    synchronizing_customers: false,
    synchronizing_customers_errors: {}

};

const mutations = {
    updateCustomers(state, customers) {
        state.customers = customers;
    },
    updateCustomersCount(state, count) {
        state.customers_count = count;
    },
    updateCustomersLoading(state, loading) {
        state.customers_loading = loading;
    },
    updateCustomersLoadingErrors(state, errors) {
        state.customers_loading_errors = errors;
    },
    updateCustomersFilters(state, filters) {
        state.customers_filters = filters;
    },
    updateCustomersCancelSource(state, source) {
        state.customers_cancel_source = source;
    },
    updateSynchronizingCustomers(state, synchronizing) {
        state.synchronizing_customers = synchronizing;
    },
    updateSynchronizingCustomersErrors(state, errors) {
        state.synchronizing_customers_errors = errors;
    },

};

const actions = {

    exportCustomersAsCSV({ commit, dispatch, state }, params) {
        let filters = {};
        if (params.filtered) {
            filters = Object.assign({}, state.customers_filters);
            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 10000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/customers/?" + queryparams);
    },

    synchronizeCustomers({ commit, dispatch, state }, params) {
        commit('updateSynchronizingCustomers', true);
        commit('updateSynchronizingCustomersErrors', null);

        let url = '/api/sync/customers/';

        return new Promise((resolve, reject) => {

            axios.post(url, { payload: params.payload })
                .then((response) => {
                    commit('updateSynchronizingCustomers', false);
                    resolve(response.data.payload);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    error.details = error.details.payload;
                    commit('updateSynchronizingCustomers', false);
                    commit('updateSynchronizingCustomersErrors', error.details);
                    reject(error);
                });
        });
    },

    async fetchCustomers({ commit, dispatch, state }, params) {
        commit('updateCustomersLoading', true);
        commit('updateCustomersLoadingErrors', null);

        if (state.customers_cancel_source) {
            state.customers_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit('updateCustomersCancelSource', cancelSource);


        try {
            const response = await axios.get('/api/customers/', {
                params: state.customers_filters,
                cancelToken: state.customers_cancel_source?.token,
            });
            commit('updateCustomersLoading', false);
            commit('updateCustomers', response.data.results);
            commit('updateCustomersCount', response.data.count);
            commit('updateCustomersCancelSource', null);
            return response;
        } catch (xhr_error) {
            if (axios.isCancel(xhr_error)) {
                return;
            }
            const error = utils.handleError(xhr_error);
            commit('updateCustomersLoading', false);
            commit('updateCustomersLoadingErrors', error.details);
            throw error;
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
