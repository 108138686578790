import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import qs from 'qs';

const state = {
    purchaseprices: [],
    purchaseprices_filters: { offset: 0, limit: 20, },
    purchaseprices_count: 0,
    purchaseprices_loading: false,
    purchaseprices_loading_errors: null,

};

const mutations = {
    updatePurchasePrices(state, purchaseprices) {
        state.purchaseprices = purchaseprices;
    },
    updatePurchasePricesFilters(state, purchaseprices_filters) {
        state.purchaseprices_filters = purchaseprices_filters;
    },
    updatePurchasePricesCount(state, purchaseprices_count) {
        state.purchaseprices_count = purchaseprices_count;
    },
    updatePurchasePricesLoading(state, loading) {
        state.purchaseprices_loading = loading;
    },
    updatePurchasePricesLoadingErrors(state, errors) {
        state.purchaseprices_loading_errors = errors;
    },
};

const actions = {

    fetchPurchasePrices({ commit, dispatch, state }, params) {
        commit('updatePurchasePricesLoading', true);
        commit('updatePurchasePricesLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/purchaseprices/', {
                params: state.purchaseprices_filters,
            })
                .then((response) => {
                    commit('updatePurchasePrices', response.data.results);
                    commit('updatePurchasePricesCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updatePurchasePricesLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updatePurchasePricesLoading', false);
                })
        });
    },



};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
