var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-alert', {
    staticClass: "text-body-2",
    attrs: {
      "border": "left",
      "colored-border": "",
      "color": "red accent-4",
      "elevation": _vm.elevation,
      "type": "error"
    }
  }, [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }