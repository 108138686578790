<template>
    <strike v-if="strike"> <slot /> </strike>
    <span v-else>
        <slot />
    </span>
</template>

<script>
export default {
    props: {
        strike: {
            type: Boolean,
            required: false,
        },
    },
};
</script>
