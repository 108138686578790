var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Placeholder', {
    attrs: {
      "title": _vm.title || _vm.$translate('error.fetchingData'),
      "description": _vm.description || _vm.$translate('error.fetchingDataDescription'),
      "actions": [{
        main: false,
        text: _vm.$translate('actions.retry'),
        action: 'retry'
      }]
    },
    on: {
      "retry": function retry($event) {
        return _vm.$emit('retry');
      }
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }