var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  }, [_c('path', {
    attrs: {
      "fill": "#797979",
      "fill-rule": "nonzero",
      "d": "M5.058 5a2.606 2.606 0 0 1 2.544 1.991h7.432c.331 0 .6.265.6.592a.596.596 0 0 1-.6.592H7.602a2.606 2.606 0 0 1-2.544 1.98 2.606 2.606 0 0 1-2.543-1.98H.601A.596.596 0 0 1 0 7.583c0-.327.269-.592.6-.592h1.915A2.606 2.606 0 0 1 5.058 5zm0 3.982c.78 0 1.413-.623 1.413-1.392 0-.77-.632-1.393-1.413-1.393-.78 0-1.413.624-1.413 1.393s.633 1.392 1.413 1.392zM10.598 10a2.606 2.606 0 0 1 2.521 1.991h1.915c.331 0 .6.265.6.592a.596.596 0 0 1-.6.592h-1.915a2.606 2.606 0 0 1-2.543 1.98 2.606 2.606 0 0 1-2.543-1.98H.6A.596.596 0 0 1 0 12.583c0-.327.269-.592.6-.592h7.454A2.606 2.606 0 0 1 10.597 10zm0 3.968c.78 0 1.412-.623 1.412-1.392 0-.77-.633-1.392-1.413-1.392s-1.413.623-1.413 1.392c0 .769.633 1.392 1.413 1.392zm0-13.968a2.606 2.606 0 0 1 2.521 1.991h1.915c.331 0 .6.265.6.592a.596.596 0 0 1-.6.592h-1.915a2.606 2.606 0 0 1-2.543 1.98 2.606 2.606 0 0 1-2.543-1.98H.6A.596.596 0 0 1 0 2.583c0-.327.269-.592.6-.592h7.454A2.606 2.606 0 0 1 10.597 0zm0 3.968c.78 0 1.412-.623 1.412-1.392 0-.77-.633-1.392-1.413-1.392s-1.413.623-1.413 1.392c0 .769.633 1.392 1.413 1.392z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }