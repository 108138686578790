import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    billingaccount: null,
    billingaccount_saving: false,
    billingaccount_saving_errors: {},
};


const mutations = {

    updateBillingAccount(state, billingaccount) {
        state.billingaccount = billingaccount;
    },

    updateBillingAccountSaving(state, saving) {
        state.billingaccount_saving = saving;
        state.billingaccount_saving_errors = {};
    },

    updateBillingAccountSavingErrors(state, saving_errors) {
        state.billingaccount_saving_errors = saving_errors || {};
        state.billingaccount_saving = false;
    }
};

const actions = {

    async saveBillingAccount({ commit, dispatch, state }, params) {
        commit('updateBillingAccountSaving', true);

        let billingaccount = {
            address: state.billingaccount.address,
            siren: state.billingaccount.siren,
        };
        try {
            const response = await axios.patch(`/api/billingaccounts/${state.billingaccount.id}/`, billingaccount);
            commit('updateBillingAccount', { ...state.billingaccount, id: response.data.id });
            commit('updateBillingAccountSaving', false);
            dispatch('session/fetchLoginInfo', null, { root: true });
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateBillingAccountSavingErrors', error.details);
            throw error;
        }
    },

    init({ commit, dispatch, state }, params) {
        let billingaccount = {
            ...params.billingaccount
        };
        if (!billingaccount.address) {
            billingaccount.address = {};
        }
        commit('updateBillingAccount', JSON.parse(JSON.stringify(billingaccount)));
    }

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
