<template>
    <div class="datetime mb-5">
        <v-menu
            v-model="display"
            ref="menu"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :close-on-content-click="false"
            v-if="!fixed"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :background-color="backgroundColor"
                    :clearable="clearable"
                    :dense="dense"
                    :error-messages="errorMessages"
                    :flat="flat"
                    :label="required ? label + ' (*)' : label"
                    :outlined="outlined"
                    :prepend-inner-icon="prependInnerIcon"
                    :value="pretty_date"
                    :hide-details="!errorMessages"
                    v-bind="attrs"
                    v-on="on"
                    @change="setNewDate($event)"
                    :placeholder="placeholder"
                    :disabled="disabled"
                ></v-text-field>
            </template>

            <v-date-picker
                v-model="inputVal"
                view-mode="year"
                no-title
                scrollable
                :first-day-of-week="1"
                :min="minDate"
                :max="maxDate"
                @input="display = false"
                :width="width"
                :disabled="disabled"
            >
            </v-date-picker>
        </v-menu>
        <template v-else>
            <v-date-picker
                :value="inputVal || $moment().format('YYYY-MM-DD')"
                @input="inputVal = $event"
                view-mode="year"
                no-title
                scrollable
                :first-day-of-week="1"
                :min="minDate"
                :max="maxDate"
                class="px-0"
                :width="width"
                :disabled="disabled"
            >
            </v-date-picker>
        </template>
    </div>
</template>

<script>
import moment from "moment";

export default {
    props: {
        minDate: {
            default: () => moment().format("YYYY-MM-DD"),
        },
        maxDate: {
            default: null,
        },
        dateFormat: {
            type: String,
            default: "ll",
        },
        value: {
            required: false,
        },
        errorMessages: {
            required: false,
        },
        label: {
            type: String,
            required: true,
        },
        fixed: {
            type: Boolean,
            required: false,
            default: false,
        },
        required: {
            type: Boolean,
            required: false,
        },
        width: {
            type: Number,
            required: false,
        },
        prependInnerIcon: {
            type: String,
            required: false,
        },
        outlined: {
            type: Boolean,
            required: false,
        },
        flat: {
            type: Boolean,
            required: false,
            default: false,
        },
        dense: {
            type: Boolean,
            required: false,
            default: false,
        },
        backgroundColor: {
            type: String,
            required: false,
        },
        clearable: {
            type: Boolean,
            required: false,
            default: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        placeholder: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            inputVal: this.value,
            display: false,
        };
    },
    computed: {
        pretty_date() {
            if (!this.inputVal) {
                return null;
            }
            return this.$moment(this.inputVal).format(this.dateFormat);
        },
    },
    methods: {
        setNewDate(date) {
            // parse date using $moment and dateFormat prop
            let newDate = this.$moment(date, this.dateFormat);
            // if date is valid, emit it
            if (newDate.isValid()) {
                // Emit an input event with date with format YYY-MM-DD
                this.$emit("input", newDate.format("YYYY-MM-DD"));
            } else {
                this.$emit("input", null);
            }
        },
    },
    watch: {
        inputVal(val) {
            this.$emit("input", val);
        },
    },
};
</script>
