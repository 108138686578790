var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('LineChartGenerator', {
    attrs: {
      "options": _vm.chartOptions,
      "data": _vm.preparedData,
      "height": _vm.height,
      "id": _vm.chartId
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }