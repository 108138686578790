import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    contractline: null,
    contractline_loading: false,
    contractline_loading_errors: null,

    contractline_saving: false,
    contractline_saving_errors: {},
};

const mutations = {
    updateContractLine(state, contractline) {
        state.contractline = contractline;
        state.contractline_saving_errors = {};
    },
    updateContractLineLoading(state, loading) {
        state.contractline_loading = loading;
    },
    updateContractLineLoadingErrors(state, errors) {
        state.contractline_loading_errors = errors;
    },
    updateContractLineSaving(state, contractline_saving) {
        state.contractline_saving = contractline_saving;
    },
    updateContractLineSavingErrors(state, contractline_saving_errors) {
        state.contractline_saving = false;
        state.contractline_saving_errors = contractline_saving_errors;
    },

};

const actions = {

    saveContractLine({ commit, dispatch, state }, params) {
        commit('updateContractLineSaving', true);

        let url = '/api/contractlines/';
        let method = axios.post;

        if (params.instance.id) {
            url = `/api/contractlines/${params.instance.id}/`;
            method = axios.put;
        }

        return new Promise((resolve, reject) => {
            method(url, params.instance)
                .then((response) => {
                    commit('updateContractLineSaving', false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateContractLineSavingErrors', error.details);
                    reject(error);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
