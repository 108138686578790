var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({}, on), [_c('b', [_vm._v(_vm._s(_vm.price(_vm.excludingVat)))])])];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.$translate("excludingVat")))])])], 1), _vm.excludingVat ? _c('div', {
    staticClass: "grey--text mt-1"
  }, [_c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('div', _vm._g({}, on), [_vm._v(" " + _vm._s(_vm.price(_vm.includingVat)) + " ")])];
      }
    }], null, false, 3997422942)
  }, [_c('span', [_vm._v(_vm._s(_vm.$translate("includingVat")))]), _vm.vatRate ? [_c('span', [_vm._v(" (" + _vm._s(_vm.$translate("vat_rate")) + " " + _vm._s(_vm.vatRate * 100) + "%)")])] : _vm._e()], 2)], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }