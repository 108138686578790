import Vue from "vue";
import axios from "axios";
import VueI18n from 'vue-i18n';
import translations from '@/locales/translations.json';
import GetTextPlugin from 'vue-gettext';
import store from "@/stores/store";
import moment from 'moment';

require('moment/locale/fr')

// Constants for available languages and their codes
const LANGUAGES = {
  'fr': 'fr_FR',
  'en': 'en_US',
};

/**
 * Function to determine the best language for the user
 * @returns {string} The best language code (e.g., 'fr' or 'en')
 */
function determineBestLanguage() {
  // 1. Check URL parameter 'l'
  const urlParams = new URLSearchParams(window.location.search);
  const langParam = urlParams.get('l');
  if (langParam && LANGUAGES[langParam]) {
    return langParam;
  }

  // 2. Check the global user_language variable
  const userLanguage = store.getters["session/current_user_language"];
  if (typeof userLanguage !== 'undefined' && LANGUAGES[userLanguage]) {
    return userLanguage;
  }

  // 3. Check browser languages
  const browserLanguages = navigator.languages || [navigator.language || navigator.userLanguage];
  for (let i = 0; i < browserLanguages.length; i++) {
    const browserLang = browserLanguages[i].split('-')[0]; // Extract language code (e.g., 'en' from 'en-US')
    if (LANGUAGES[browserLang]) {
      return browserLang;
    }
  }

  // 4. Default to English if no valid language found
  return 'en';
}

const locale = determineBestLanguage();

store.commit("session/updateActiveLocale", LANGUAGES[locale].replace('_', '-'));

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: LANGUAGES[locale],
  fallbackLocale: 'fr',
});

Vue.prototype.$translate = function (key, ...values) {
  const i18n = this.$i18n;
  return i18n._t(key, locale, i18n._getMessages(), this, ...values);
};

const availableLanguages = {
  en: 'English',
  fr: 'Français',
};

Vue.use(GetTextPlugin, {
  availableLanguages,
  defaultLanguage: locale,
  languageVmMixin: {
    computed: {
      currentKebabCase() {
        return this.current.replace('_', '-');
      },
    },
  },
  translations: translations,
  silent: true,
});

Vue.prototype.$availableLanguages = availableLanguages;

Vue.use(require('vue-moment'), { moment });
Vue.moment().locale(LANGUAGES[locale]);
axios.defaults.headers.common[
  "Accept-Language"
] = locale;

export default i18n;
