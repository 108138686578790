<template>
    <Placeholder
        :title="title || $translate('error.fetchingData')"
        :description="description || $translate('error.fetchingDataDescription')"
        :actions="[
            {
                main: false,
                text: $translate('actions.retry'),
                action: 'retry',
            },
        ]"
        @retry="$emit('retry')"
    />
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
        },
        description: {
            type: String,
            required: false,
        },
    },
    i18n: {
        messages: {
            en: {
                "error.fetchingData": "Error fetching data",
                "error.fetchingDataDescription":
                    "An error occured while fetching data. Please try again or contact us if you need assistance.",
                "actions.retry": "Retry",
            },
            fr: {
                "error.fetchingData": "Erreur lors de la récupération des données",
                "error.fetchingDataDescription":
                    "Une erreur s'est produite lors de la récupération des données. Veuillez réessayer ou nous contacter si vous avez besoin d'aide.",
                "actions.retry": "Réessayer",
            },
        },
    },
};
</script>
